import yup from 'client/validation'

import dict from './dictionary'

export const ChildDateOfBirthValidationSchema = {
  dateOfBirth: yup
    .string()
    .required(dict.dateOfBirth.errMsg)
    .validateIfChildIsNotTooYoung()
    .validateIfChildIsNotTooOld()
    .noFutureDateValidation(dict.dateOfBirth.errMsg),
}

export const ChildBirthPlaceValidationSchema = {
  birthPlace: yup.string().required(dict.birthPlace.validation.required),
}
