import React from 'react'
import { TrackingWrapper, EventBusBridge } from '@piggybank/core'
import { get } from 'lodash'

import { T_Store, T_TaggingSpec } from 'types/index'

import { taggingSpecs } from './specs'
import connect from './connect'
import { TealiumEnv } from 'enums/index'
import { trackEvent, trackView } from 'client/helpers/tagging/taggingFunctions';

type T_Props = {
  enabled: boolean
  store: T_Store
  children: any
}

const Analytics = ({ enabled, store, children }: T_Props) => {
  const trackEvents = events => {
    events.forEach((event: T_TaggingSpec) => {
      const { content } = event
      const isViewEvent = Boolean(get(event, 'rule.isViewEvent'))

      if (get(store, 'environment.TEALIUM_ENV') === TealiumEnv.Dev) {
        // Add 'TEALIUM TAG ↓' to console filter and uncomment below when working on tags.
        // console.clear()
        console.log(
          '%cTEALIUM TAG ↓',
          `
            color: #292929;
            font-size: 24px;
            background-color: #27999c;
            padding: 8px;
            border-radius: 3px;
            margin: 8px 0;
          `,
          content
        )
      }

      isViewEvent ? trackView(content) : trackEvent(content);
    })
  }

  return enabled ? (
    <TrackingWrapper specs={taggingSpecs} onTrackingEvent={trackEvents}>
      <EventBusBridge>{children}</EventBusBridge>
    </TrackingWrapper>
  ) : (
    children
  )
}

export default connect(Analytics)
