import { T_FormInput } from 'types/index'
import { T_TaxDetails, T_TaxResidency } from 'types/data/local/submissionPayload'
import { PageKey, YesNoBoolean } from 'enums/index'

const getTaxResidence = (countryOrJurisdiction): T_TaxResidency => {
  const result: T_TaxResidency = {
    country: countryOrJurisdiction.countryOrJurisdictionValue,
  }
  if (countryOrJurisdiction.childHasTaxIdOrReferenceNumber === YesNoBoolean.Yes) {
    result.identificationNumber = countryOrJurisdiction.taxIdentificationOrReferenceNumber.trim()
  } else {
    result.noTinReason = countryOrJurisdiction.reasonForMissingChildTaxIdOrReferenceNumber
  }
  return result
}

export default (formInput: T_FormInput): T_TaxDetails => {
  const {
    countryOrJurisdictionValue,
    childHasTaxIdOrReferenceNumber,
    reasonForMissingChildTaxIdOrReferenceNumber,
    taxIdentificationOrReferenceNumber,
    countryOrJurisdictionAdditionalValues = [],
  } = formInput[PageKey.ChildContactAndFinancialDetailsPage]

  const result: T_TaxDetails = {
    taxResidence: getTaxResidence({
      countryOrJurisdictionValue,
      childHasTaxIdOrReferenceNumber,
      reasonForMissingChildTaxIdOrReferenceNumber,
      taxIdentificationOrReferenceNumber,
    }),
  }
  if (countryOrJurisdictionAdditionalValues.length > 0) {
    result.additionalTaxResidence1 = getTaxResidence(countryOrJurisdictionAdditionalValues[0])
  }
  if (countryOrJurisdictionAdditionalValues.length > 1) {
    result.additionalTaxResidence2 = getTaxResidence(countryOrJurisdictionAdditionalValues[1])
  }
  return result
}
