import { PageKey } from 'enums/index'

import AccountInformationPage from 'client/pages/AccountInformationPage'
import ApplicationDeclinedIneligibilityPage from 'client/pages/ApplicationDeclinedPage/ApplicationDeclinedIneligibilityPage'
import ApplicationDeclinedInternalPolicyPage from 'client/pages/ApplicationDeclinedPage/ApplicationDeclinedInternalPolicyPage'
import ApplicationReceivedPage from 'client/pages/ApplicationReceivedPage'
import ApplicationReferredPage from 'client/pages/ApplicationReferredPage'
import ChildContactAndFinancialDetailsPage from 'client/pages/ChildContactAndFinancialDetailsPage'
import ChildPersonalDetailsPage from 'client/pages/ChildPersonalDetailsPage'
import CustomerIneligibilityPage from 'client/pages/CustomerIneligibilityPage'
import DetailsCheckingPage from 'client/pages/DetailsCheckingPage'
import FeatureFlags from 'client/pages/FeatureFlags'
import ProcessingPage from 'client/pages/ProcessingPage'
import ProductEligibilityCheckPage from 'client/pages/ProductEligibilityCheckPage'
import TechnicalErrorPage from 'client/pages/TechnicalErrorPage'
import TimeoutPage from 'client/pages/TimeoutPage'

export const formPages = {
  [PageKey.RootPage]: {
    id: 0,
    key: PageKey.RootPage,
    path: '/',
    title: 'HSBC MyMoney Application',
    Component: DetailsCheckingPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.FeatureFlags]: {
    id: 1,
    key: PageKey.FeatureFlags,
    path: `/${PageKey.FeatureFlags}`,
    title: 'HSBC MyMoney Application - Feature Flags',
    Component: FeatureFlags,
    withProductBasket: false,
    hasTimeout: false,
  },
  [PageKey.CustomerIneligibilityPage]: {
    id: 2,
    key: PageKey.CustomerIneligibilityPage,
    path: `/${PageKey.CustomerIneligibilityPage}`,
    title: 'HSBC MyMoney Application - Ineligible',
    Component: CustomerIneligibilityPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.ProductEligibilityCheckPage]: {
    id: 3,
    key: PageKey.ProductEligibilityCheckPage,
    path: `/${PageKey.ProductEligibilityCheckPage}`,
    title: 'HSBC MyMoney Application - Eligibility',
    Component: ProductEligibilityCheckPage,
    withProductBasket: false,
    hasTimeout: true,
    isFormPage: true,
  },
  [PageKey.ChildPersonalDetailsPage]: {
    id: 4,
    key: PageKey.ChildPersonalDetailsPage,
    path: `/${PageKey.ChildPersonalDetailsPage}`,
    title: 'HSBC MyMoney Application - Personal Details',
    Component: ChildPersonalDetailsPage,
    withProductBasket: true,
    hasTimeout: true,
    isFormPage: true,
  },
  [PageKey.ChildContactAndFinancialDetailsPage]: {
    id: 5,
    key: PageKey.ChildContactAndFinancialDetailsPage,
    path: `/${PageKey.ChildContactAndFinancialDetailsPage}`,
    title: 'HSBC MyMoney Application - Contact Details',
    Component: ChildContactAndFinancialDetailsPage,
    withProductBasket: true,
    hasTimeout: true,
    isFormPage: true,
  },
  [PageKey.AccountInformationPage]: {
    id: 6,
    key: PageKey.AccountInformationPage,
    path: `/${PageKey.AccountInformationPage}`,
    title: 'HSBC MyMoney Application - Account Details',
    Component: AccountInformationPage,
    withProductBasket: true,
    hasTimeout: true,
    isFormPage: true,
  },
  [PageKey.ProcessingPage]: {
    id: 7,
    key: PageKey.ProcessingPage,
    path: `/${PageKey.ProcessingPage}`,
    title: 'HSBC MyMoney Application - Processing',
    Component: ProcessingPage,
    withProductBasket: false,
    hasTimeout: true,
    backButtonDisabled: true,
  },
  [PageKey.ApplicationReceivedPage]: {
    id: 8,
    key: PageKey.ApplicationReceivedPage,
    path: `/${PageKey.ApplicationReceivedPage}`,
    title: 'HSBC MyMoney Application - Submitted',
    Component: ApplicationReceivedPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.ApplicationReferredPage]: {
    id: 9,
    key: PageKey.ApplicationReferredPage,
    path: `/${PageKey.ApplicationReferredPage}`,
    title: 'HSBC MyMoney Application - Referred',
    Component: ApplicationReferredPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.ApplicationDeclinedIneligibilityPage]: {
    id: 10,
    key: PageKey.ApplicationDeclinedIneligibilityPage,
    path: `/${PageKey.ApplicationDeclinedIneligibilityPage}`,
    title: 'HSBC MyMoney Application - Declined',
    Component: ApplicationDeclinedIneligibilityPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.ApplicationDeclinedInternalPolicyPage]: {
    id: 11,
    key: PageKey.ApplicationDeclinedInternalPolicyPage,
    path: `/${PageKey.ApplicationDeclinedInternalPolicyPage}`,
    title: 'HSBC MyMoney Application - Declined',
    Component: ApplicationDeclinedInternalPolicyPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.TechnicalErrorPage]: {
    id: 12,
    key: PageKey.TechnicalErrorPage,
    path: `/${PageKey.TechnicalErrorPage}`,
    title: 'HSBC MyMoney Application - Technical Error',
    Component: TechnicalErrorPage,
    withProductBasket: false,
    hasTimeout: true,
  },
  [PageKey.TimeoutPage]: {
    id: 13,
    key: PageKey.TimeoutPage,
    path: `/${PageKey.TimeoutPage}`,
    title: 'HSBC MyMoney Application - Timeout',
    Component: TimeoutPage,
    withProductBasket: false,
    hasTimeout: false,
  },
}
