import { isAnAdult } from 'client/helpers/dateTime'
import dict from './../dictionary'

export const isChildTooOld = function() {
  const value = this.parent.dateOfBirth
  return isAnAdult(value) ? this.createError({ message: dict.childIsTooOld }) : true
}

export default function() {
  return this.test({
    name: 'validateIfChildIsNotTooOld',
    test: isChildTooOld,
  })
}
