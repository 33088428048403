import React from 'react'
import { Heading, Paragraph, Expander, Link, Section } from '@piggybank/core'
import { get } from 'lodash'

import { CountrySelection } from 'client/components'
import { EXTRA_COUNTRY_OR_JURISDICTION_MAX } from 'constants/index'
import PageKey from 'enums/PageKey'
import { T_FormInput } from 'types/index'

import { TaxDetails } from './TaxDetails'
import dict, { whyDoWeNeedTaxResidencyDetailsLabel } from '../dictionary'
import { additionalCountriesOrJurisdictionsInitialValues } from '../getInitialValues'

export const countryOrJurisdictionFieldName = `${PageKey.ChildContactAndFinancialDetailsPage}.countryOrJurisdictionValue`
export const addAnotherCountryOrJurisdictionButtonName = `${PageKey.ChildContactAndFinancialDetailsPage}.countryOrJurisdictionAdditionalValues`

const { taxResidencySection } = dict

type T_Props = {
  pageKey: PageKey
  formValues: T_FormInput
  formErrors: any
  submitCount: number
}

const TaxResidency = ({ pageKey, formValues, formErrors, submitCount }: T_Props) => (
  <>
    <Heading level={2} accentBar={true}>
      {taxResidencySection.heading}
    </Heading>
    <Section marginBottom={4}>
      <Expander
        title={whyDoWeNeedTaxResidencyDetailsLabel}
        name={whyDoWeNeedTaxResidencyDetailsLabel}
      >
        <Paragraph>{taxResidencySection.expander.paragraph1}</Paragraph>
        <Paragraph>{taxResidencySection.expander.paragraph2}</Paragraph>
        <Paragraph>{taxResidencySection.expander.paragraph3}</Paragraph>
        <Paragraph>
          {taxResidencySection.expander.paragraph4}&nbsp;
          <Link elementType={'a'} href={taxResidencySection.expander.link.href} external={true}>
            {taxResidencySection.expander.link.text}
          </Link>
        </Paragraph>
      </Expander>
    </Section>
    <CountrySelection
      dict={dict.taxResidencySection.form.countryOrJurisdiction}
      maxNumberOfAdditionalCountries={EXTRA_COUNTRY_OR_JURISDICTION_MAX}
      countryFieldName={countryOrJurisdictionFieldName}
      additionalCountriesFieldName={addAnotherCountryOrJurisdictionButtonName}
      additionalCountryFieldName={'countryOrJurisdictionValue'}
      country={get(formValues, `${pageKey}.countryOrJurisdictionValue`)}
      initialItemValue={additionalCountriesOrJurisdictionsInitialValues}
      additionalCountries={get(formValues, `${pageKey}.countryOrJurisdictionAdditionalValues`)}
      mainCountrySlot={
        <TaxDetails
          path={pageKey}
          values={formValues[pageKey]}
          formErrors={formErrors}
          submitCount={submitCount}
        />
      }
      additionalCountriesSlot={(path, index) => (
        <TaxDetails
          path={path}
          values={formValues[pageKey].countryOrJurisdictionAdditionalValues[index]}
          formErrors={formErrors}
          submitCount={submitCount}
        />
      )}
    />
  </>
)

export default TaxResidency
