enum TealiumProductId {
  MySavings = 'mysavings',
  MyMoney = 'mymoney',
  PremierMySavings = 'premier mysavings',
  PremierMyMoney = 'premier mymoney',
  MyMoneyStaff = 'mymoney staff',
  PremierMyMoneyStaff = 'premier mymoney staff',
}

export default TealiumProductId
