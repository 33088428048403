import React from 'react'
import get from 'lodash/get'
import {
  Heading,
  HorizontalRule,
  IconStatusInformationOnlight,
  Paragraph,
  Section,
  withTrackPage,
} from '@piggybank/core'

import { ReturnToOnlineBanking } from 'client/components'
import { InteractiveContent } from 'client/components'
import { getDisplayedCustomerName } from 'client/helpers'
import { getStore } from 'client/store'
import { PageKey, TealiumComponent } from 'enums/index'
import dict from './dictionary'

const { mainHeading, nextStepsSection, whatCanIDoNowSection } = dict

const ApplicationReferredPage = () => {
  const { getState } = getStore()
  const {
    remoteData: { initialResponse },
  } = getState()

  const firstNames = get(initialResponse, 'personalDetails.firstNames', '')
  const customerName = getDisplayedCustomerName(firstNames)

  return (
    <Section marginBottom={7}>
      <Section>
        <div style={{ color: 'var(--sapphire)' }}>
          <IconStatusInformationOnlight width="2.5em" />
        </div>
        <Heading level={1} data-bdd={'main-heading'}>
          {mainHeading}
          {customerName}
        </Heading>
        <HorizontalRule />
      </Section>
      <Section marginBottom={4}>
        <Heading level={2} accentBar={true} data-bdd={'next-steps-heading'}>
          {nextStepsSection.heading}
        </Heading>
        {!!nextStepsSection.paragraphs &&
          nextStepsSection.paragraphs.map((paragraph, i) => (
            <Paragraph
              key={i}
              data-bdd={`next-steps-paragraph-${i + 1}`}
              marginBottom={i + 1 === nextStepsSection.paragraphs.length ? 4 : 2}
            >
              {paragraph}
            </Paragraph>
          ))}
      </Section>
      <Paragraph data-bdd={'for-more-information-paragraph'}>
        <InteractiveContent chunks={whatCanIDoNowSection.paragraphChunks} />
      </Paragraph>
      <ReturnToOnlineBanking />
    </Section>
  )
}

export default withTrackPage(
  PageKey.ApplicationReferredPage,
  TealiumComponent.NonWizardPage
)(ApplicationReferredPage)
