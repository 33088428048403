import { T_Action, T_Store } from 'types/index'
import {ActionName, PageKey} from 'enums/index'

const {
  StoreFormInput,
  StoreInitialResponse,
  StoreNewAuthToken,
  StoreSubmissionResponse,
  UpdateFeatureFlags,
  UpdateFakeProfiles,
  UpdatePrimaryProduct,
} = ActionName

export default (state: T_Store, action: T_Action) => {
  const { type, payload } = action

  switch (type) {
    case StoreFormInput:
      return {
        ...state,
        formInput: {
          ...state.formInput,
          ...payload,
        },
      }

    case StoreInitialResponse:
      return {
        ...state,
        remoteData: {
          ...state.remoteData,
          initialResponse: payload,
        },
      }

    case StoreNewAuthToken:
      return {
        ...state,
        authorization: {
          token: payload,
        },
      }

    case StoreSubmissionResponse:
      return {
        ...state,
        remoteData: {
          ...state.remoteData,
          submissionResponse: payload,
        },
      }

    case UpdateFeatureFlags:
      return {
        ...state,
        features: payload,
      }

    case UpdateFakeProfiles:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...payload,
        },
      }

    case UpdatePrimaryProduct:
      return {
        ...state,
        formInput: {
          ...state.formInput,
          [PageKey.ProductEligibilityCheckPage]: {
            ...state.formInput[PageKey.ProductEligibilityCheckPage],
            primaryProduct: payload,
          },
        },
      }

    default:
      return state
  }
}
