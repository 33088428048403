import React, { FC, useEffect, useState } from 'react'
import { useSessionRefresh } from '@atlas/session'
import { Loading, withTrackPage } from '@piggybank/core'

import { waitAtLeast } from 'client/helpers'
import FeatureFlagButtons from 'client/components/FeatureFlagButtons/FeatureFlagButtons'
import { formPages } from 'client/pages'
import { useService } from 'client/providers/Service'
import { getStore } from 'client/store'
import { storeInitialResponse } from 'client/store/actions'
import { DEFAULT_MIN_REQ_TIME_IN_MS } from 'constants/app'
import { PageKey, RequestStatus, TealiumComponent } from 'enums/index'
import { T_PageProps } from 'types/props'

export const getPage = data =>
  data.eligible ? PageKey.ProductEligibilityCheckPage : PageKey.CustomerIneligibilityPage

export const DetailsCheckingPage: FC<T_PageProps> = ({ onNavigate }) => {
  const refreshSession = useSessionRefresh()
  const { dispatch } = getStore()
  const [status, setStatus] = useState<RequestStatus | null>(null)
  const [{ service }] = useService()

  useEffect(() => {
    const ensureMinAnimationLength = waitAtLeast(DEFAULT_MIN_REQ_TIME_IN_MS)
    const onSuccess = async data => {
      setStatus(RequestStatus.Success)
      await refreshSession()
      dispatch(storeInitialResponse(data))
      ensureMinAnimationLength().then(() => onNavigate(formPages[getPage(data)].id))
    }
    const onError = async () => {
      setStatus(RequestStatus.Failure)
      await refreshSession()
      ensureMinAnimationLength().then(() => onNavigate(formPages[PageKey.TechnicalErrorPage].id))
    }
    if (!status) {
      setStatus(RequestStatus.Pending)
      try {
        service
          .checkEligibility()
          .then(onSuccess)
          .catch(onError)
      } catch (e) {
        onError()
      }
    }
  }, [status, setStatus, service, refreshSession, dispatch, onNavigate])

  return (
    <Loading show={true} getApplicationNode={() => document.getElementById('root')}>
      <FeatureFlagButtons onNavigate={onNavigate} />
    </Loading>
  )
}

export default withTrackPage(PageKey.RootPage, TealiumComponent.NonWizardPage)(DetailsCheckingPage)
