import React from 'react'
import { useSessionStartTime } from '@atlas/session'
import { Button, IconSettings, Section, detectBrowser } from '@piggybank/core'
import { Field, Form, Select, useWizard } from '@piggybank/form'
import { formPages } from 'client/pages'
import { getStore } from 'client/store'
import Screenshot from 'client/components/Screenshot'
import { isFeatureEnabled } from 'client/utils/featureEnabled'
import timestampedFilename from 'client/utils/timestampedFilename'
import useNow from 'client/utils/useNow'
import { FeatureFlags, PageKey } from 'enums/index'
import styles from './styles.module.css'

export const screenshotPrefix = 'OMAUK-'

const timerFormat = (ms: number) => {
  const seconds = Math.floor(ms / 1000) % 60
  const minutes = Math.floor(ms / 60000)
  if (minutes < 0 || seconds < 0) {
    return '00:00'
  }
  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
}

const FeatureFlagsButtons = ({ onNavigate }) => {
  const { getState } = getStore()
  const {
    environment: { FEATURES_OVERRIDES, TIMEOUT_CLIENT_SESSION, TIMEOUT_WARNING_COUNTER },
    features,
  } = getState()
  const startTime = useSessionStartTime()
  const { currentPage } = useWizard()
  const now = useNow()

  if (FEATURES_OVERRIDES !== 'YES') return null

  const customTimeout =
    isFeatureEnabled(features, FeatureFlags.CUSTOM_TIMEOUT) &&
    1000 * parseInt(features[FeatureFlags.CUSTOM_TIMEOUT].value, 10)
  const IDLE_TIMEOUT: number = customTimeout || parseInt(TIMEOUT_CLIENT_SESSION, 10)
  const IDLE_WARNING: number = IDLE_TIMEOUT - parseInt(TIMEOUT_WARNING_COUNTER, 10)

  const filter = node => {
    return node.id !== 'buttonSection' && node.id !== 'featureOverridesSpacer'
  }

  const pages = Object.keys(formPages).map(page => {
    return {
      label: formPages[page].title.replace('HSBC MyMoney Application - ', ''),
      value: `${formPages[page].id}`,
    }
  })

  const { ie11 } = detectBrowser

  return (
    <Section
      aria-hidden={true}
      {...{
        id: 'buttonSection',
        className:
          FEATURES_OVERRIDES === 'YES'
            ? ie11
              ? styles.buttonSectionIE11
              : styles.buttonSection
            : styles.hide,
      }}
    >
      <div className={styles.countdown}>
        <span>
          {IDLE_WARNING - now + startTime > 0
            ? timerFormat(IDLE_WARNING - (now - startTime))
            : timerFormat(IDLE_TIMEOUT - (now - startTime))}
        </span>
      </div>
      <div className={styles.buttons}>
        {features[FeatureFlags.COMPONENT_TESTING].enabled && (
          <Form initialValues={{ pageSelector: `${currentPage}` }}>
            <Field
              name="pageSelector"
              onChange={(next, rest) => {
                const pageId = parseInt(rest.value, 10)
                onNavigate(pageId)
                next(rest)
              }}
            >
              <Select options={pages} placeholder={''} {...{ className: styles.select }} />
            </Field>
          </Form>
        )}
        <Screenshot filename={timestampedFilename(screenshotPrefix)} filter={filter} />
        <Button
          data-bdd="feature-flags-settings-button"
          secondary={true}
          type="button"
          onClick={() => onNavigate(formPages[PageKey.FeatureFlags].id)}
          {...{ className: styles.button }}
        >
          <IconSettings inline={true} />
        </Button>
      </div>
    </Section>
  )
}

export default FeatureFlagsButtons
