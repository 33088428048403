export const hsbcAccountsLinkLabel = 'HSBC accounts '

export default {
  mainHeading: "Sorry, you can't apply for a MyMoney account at the moment",
  whyCantIApply: {
    heading: 'Why can’t I apply?',
    paragraph: 'You need to be a UK resident over 18 years old and hold an eligible HSBC account.',
  },
  whatCanIDoNow: {
    heading: 'What can I do now?',
    paragraph: [
      { text: 'Open or upgrade to any of the ' },
      {
        text: hsbcAccountsLinkLabel,
        link: 'https://www.hsbc.co.uk/current-accounts/products/',
        external: true,
      },
      { text: ' and reapply for a MyMoney account.' },
    ],
  },
}
