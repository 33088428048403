enum PageKey {
  FeatureFlags = 'feature-flags',
  AccountInformationPage = 'account-information-page',
  ChildContactAndFinancialDetailsPage = 'child-contact-and-financial-details-page',
  ChildPersonalDetailsPage = 'child-personal-details-page',
  CustomerIneligibilityPage = 'customer-ineligibility-page',
  ProcessingPage = 'processing-page',
  ProductEligibilityCheckPage = 'product-eligibility-check-page',
  RootPage = 'root-page',
  TimeoutPage = 'timeout-page',
  TechnicalErrorPage = 'technical-error-page',
  ApplicationReceivedPage = 'application-received-page',
  ApplicationReferredPage = 'application-referred-page',
  ApplicationDeclinedIneligibilityPage = 'application-declined-ineligibility-page',
  ApplicationDeclinedInternalPolicyPage = 'application-declined-internal-policy-page',
}

export default PageKey
