import React from 'react'
import { Section } from '@piggybank/core'

import { Field, FieldFeedback, Label, Select, TextInput } from '@piggybank/form'

import { countries } from 'lookups/index'
import replaceApostrophes from 'client/utils/replaceApostrophes'
import { MAX_BUSINESS_NAME_LENGTH, MAX_OCCUPATION_LENGTH } from 'constants/customer'
import EmploymentStatus from 'enums/EmploymentStatus'
import { AnnualSalary } from './commonComponents'
import dict from '../dictionary'

const { form } = dict.childFinancialDetailsSection

export const EmployeeFinancialDetails = ({ values, pageKey, employmentStatus }) => {
  const fullOrPartTime = [
    EmploymentStatus.FullTimeEmployed,
    EmploymentStatus.PartTimeEmployed,
  ].includes(employmentStatus)

  return (
    <Section>
      <Field
        name={`${pageKey}.occupation`}
        onChange={(next, rest) => {
          rest.value = replaceApostrophes(rest.value)
          next(rest)
        }}
        onBlur={(next, rest) => {
          values.occupation = values.occupation.trim()
          next(rest)
        }}
      >
        <Label>{form.occupation.label}</Label>
        <TextInput maxLength={MAX_OCCUPATION_LENGTH} />
        <FieldFeedback />
      </Field>
      <Field
        name={`${pageKey}.employerName`}
        onChange={(next, rest) => {
          rest.value = replaceApostrophes(rest.value)
          next(rest)
        }}
        onBlur={(next, rest) => {
          values.employerName = values.employerName.trim()
          next(rest)
        }}
      >
        <Label>{form.employerName.label}</Label>
        <TextInput maxLength={MAX_BUSINESS_NAME_LENGTH} />
        <FieldFeedback />
      </Field>
      <Field name={`${pageKey}.employerLocation`}>
        <Label>{form.employerLocation.label}</Label>
        <Select options={countries} />
        <FieldFeedback />
      </Field>
      {fullOrPartTime && <AnnualSalary pageKey={pageKey} />}
    </Section>
  )
}
