import { applyCommonHeaders, applyProxy, applyStatusMeaning, compose, fetch } from '@atlas/fetch'

interface Payload {
  body: any
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
}

export interface IFetchAttributes {
  method: Method
  payload?: Payload
  headers?: {
    [key: string]: string
  }
}

export const fetchData = (url, { method, payload, headers }: IFetchAttributes) => {
  if (method === Method.PUT || method === Method.POST) {
    headers['Content-Type'] = 'application/json'
  }
  const options = {
    method,
    ...(payload &&
      payload.body && {
        body: JSON.stringify(payload.body),
      }),
  }
  const request = compose(applyProxy(), applyStatusMeaning(), applyCommonHeaders(headers))(fetch)
  return request(url, options)
}
