export const BUSINESS_TYPES = [
  {
    label: 'Agriculture, Forestry and Fishing',
    value: '1',
  },
  {
    label: 'Mining and Quarrying',
    value: '2',
  },
  {
    label: 'Manufacturing',
    value: '3',
  },
  {
    label: 'Electricity, gas, steam and air conditioning supply',
    value: '4',
  },
  {
    label: 'Water supply, sewerage, waste management and remediation activities',
    value: '5',
  },
  {
    label: 'Construction',
    value: '6',
  },
  {
    label: 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
    value: '7',
  },
  {
    label: 'Transportation and storage',
    value: '8',
  },
  {
    label: 'Accommodation and food service activities',
    value: '9',
  },
  {
    label: 'Information and communication',
    value: '10',
  },
  {
    label: 'Financial and insurance activities',
    value: '11',
  },
  {
    label: 'Real estate activities',
    value: '12',
  },
  {
    label: 'Professional, scientific and technical activities',
    value: '13',
  },
  {
    label: 'Administrative and support service activities',
    value: '14',
  },
  {
    label: 'Public administration and defence; compulsory social security',
    value: '15',
  },
  {
    label: 'Education',
    value: '16',
  },
  {
    label: 'Human health and social work activities',
    value: '17',
  },
  {
    label: 'Arts, entertainment and recreation',
    value: '18',
  },
  {
    label: 'Other service activities',
    value: '19',
  },
  {
    label:
      'Activities as households as employers; undifferentiated goods- and services-producing activities of households for own use',
    value: '20',
  },
  {
    label: 'Activities of extraterritorial organisations and bodies',
    value: '21',
  },
]
