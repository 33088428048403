import { isNil } from 'lodash'

import { REGEX_NON_UK_POSTCODE, REGEX_UK_POSTCODE } from 'src/regex'

export const isValidUKPostCodeValidation = function(message: string) {
  return this.test({
    name: 'isValidUKPostCodeValidation',
    message,
    test: value => !value || REGEX_UK_POSTCODE.test(value.trim()),
  })
}

export const isValidNonUKPostCodeValidation = function(message: string) {
  return this.test({
    name: 'isValidNonUKPostCodeValidation',
    message,
    test: value => !isNil(value) && REGEX_NON_UK_POSTCODE.test(value),
  })
}
