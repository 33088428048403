import { CHILD_ELIGIBLE_MIN_AGE, CHILD_ELIGIBLE_MAX_AGE } from 'constants/index'
import { Age } from 'enums/index'

export default (age: number): Age => {
  if (age < CHILD_ELIGIBLE_MIN_AGE) {
    return Age.TooYoung
  } else if (age > CHILD_ELIGIBLE_MAX_AGE) {
    return Age.TooOld
  } else {
    return Age.ValidAge
  }
}
