import React, { FC } from 'react'
import get from 'lodash/get'
import {
  Heading,
  HorizontalRule,
  IconStatusConfirmationOnlight,
  Paragraph,
  Section,
  UnorderedList,
  ListItem,
  ButtonRow,
  withTrackPage,
} from '@piggybank/core'

import { ReturnToOnlineBanking } from 'client/components'
import { getChildFirstName } from 'client/helpers'
import { PageKey, TealiumComponent } from 'enums/index'
import { T_PageProps } from 'types/props'
import dict from './dictionary'

const {
  mainHeading,
  leadingParagraph,
  leadingParagraphLabel,
  documentUploadSection,
  visitBranchSection,
} = dict

const ApplicationReceivedPage: FC<T_PageProps> = ({ formInput }) => {
  const childFirstNames = getChildFirstName(
    get(formInput, 'child-personal-details-page.firstNames', '')
  )
  return (
    <>
      <Section data-bdd="section-1">
        <div style={{ color: 'var(--jade)' }}>
          <IconStatusConfirmationOnlight width="2.5em" />
        </div>
        <Heading level={1}>{mainHeading}</Heading>
        <Paragraph lead={true} aria-label={leadingParagraphLabel}>
          {leadingParagraph}
        </Paragraph>
        <HorizontalRule />
      </Section>
      <Section data-bdd="section-2">
        <Heading level={2} accentBar={true}>
          {documentUploadSection.heading}
        </Heading>
        <UnorderedList>
          <ListItem>
            {childFirstNames}
            {documentUploadSection.listItemOne}
          </ListItem>
          <ListItem>
            {childFirstNames}
            {documentUploadSection.listItemTwo}
          </ListItem>
        </UnorderedList>
        <Paragraph>{documentUploadSection.paragraph1}</Paragraph>
        <Paragraph>{documentUploadSection.paragraph2}</Paragraph>
      </Section>
      <Section data-bdd="section-3">
        <Heading level={2} accentBar={true}>
          {visitBranchSection.heading}
        </Heading>
        <Paragraph>{visitBranchSection.paragraph}</Paragraph>
        <UnorderedList>
          <ListItem aria-label={visitBranchSection.listItemOneLabel}>
            {visitBranchSection.listItemOne}
          </ListItem>
          <ListItem aria-label={visitBranchSection.listItemTwoLabel}>
            {visitBranchSection.listItemTwo}
          </ListItem>
          <ListItem>{visitBranchSection.listItemThree}</ListItem>
          <ListItem>{visitBranchSection.listItemFour}</ListItem>
        </UnorderedList>
      </Section>
      <ButtonRow align="left" marginBottom={7}>
        <ReturnToOnlineBanking />
      </ButtonRow>
    </>
  )
}

export default withTrackPage(
  PageKey.ApplicationReceivedPage,
  TealiumComponent.NonWizardPage
)(ApplicationReceivedPage)
