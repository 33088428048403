import getChildAgeEligibilityStatus from 'client/helpers/eligibility/getChildAgeEligibilityStatus'
import { getYearsSince } from 'client/helpers/dateTime'
import { Age } from 'enums/index'
import dict from './../dictionary'

export const isChildTooYoung = function() {
  const value = this.parent.dateOfBirth
  return getChildAgeEligibilityStatus(getYearsSince(value)) === Age.TooYoung
    ? this.createError({ message: dict.childIsTooYoung })
    : true
}

export default function() {
  return this.test({
    name: 'validateIfChildIsNotTooYoung',
    test: isChildTooYoung,
  })
}
