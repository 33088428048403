import { EmploymentStatus } from 'enums/index'

export const EMPLOYMENT_STATUSES = [
  {
    label: 'Full Time Employed',
    value: EmploymentStatus.FullTimeEmployed,
  },
  {
    label: 'Part Time Employed',
    value: EmploymentStatus.PartTimeEmployed,
  },
  {
    label: 'Self Employed',
    value: EmploymentStatus.SelfEmployed,
  },
  {
    label: 'Unemployed',
    value: EmploymentStatus.Unemployed,
  },
]
