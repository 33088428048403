import React from 'react'

import { Field, FieldFeedback, Label, CurrencyInput, Select } from '@piggybank/form'

import { BUSINESS_TYPES, INDUSTRIES, SECTORS, MAX_EARNINGS_LENGTH } from 'constants/index'
import dict from '../dictionary'
import { PageKey } from 'enums/index'

export const businessTypeFieldName = `${PageKey.ChildContactAndFinancialDetailsPage}.businessType`

const { form } = dict.childFinancialDetailsSection

export const AnnualSalary = ({ pageKey }) => (
  <Field name={`${pageKey}.annualSalary`}>
    <Label>{form.annualSalary.label}</Label>
    <CurrencyInput symbol="£" maxLength={MAX_EARNINGS_LENGTH} />
    <FieldFeedback />
  </Field>
)

export const BusinessType = ({ pageKey, setFieldValue }) => (
  <Field
    name={`${pageKey}.businessType`}
    onChange={(setValue, bag) => {
      setValue(bag)
      setFieldValue(`${pageKey}.industry`, '')
      setFieldValue(`${pageKey}.sector`, '')
    }}
  >
    <Label>{form.businessType.label}</Label>
    <Select options={BUSINESS_TYPES} />
    <FieldFeedback />
  </Field>
)

export const Industry = ({ pageKey, businessType, setFieldValue }) =>
  businessType ? (
    <Field
      name={`${pageKey}.industry`}
      onChange={(setValue, bag) => {
        setValue(bag)
        setFieldValue(`${pageKey}.sector`, '')
      }}
    >
      <Label>{form.industry.label}</Label>
      <Select options={INDUSTRIES.filter(i => i.parentValue === businessType)} />
      <FieldFeedback />
    </Field>
  ) : null

export const Sector = ({ pageKey, industry }) =>
  industry ? (
    <Field name={`${pageKey}.sector`}>
      <Label>{form.sector.label}</Label>
      <Select options={SECTORS.filter(i => i.parentValue === industry)} />
      <FieldFeedback />
    </Field>
  ) : null

export const AnnualIncome = ({ pageKey }) => (
  <Field name={`${pageKey}.annualIncome`}>
    <Label>{form.annualIncome.label}</Label>
    <CurrencyInput symbol="£" maxLength={MAX_EARNINGS_LENGTH} />
    <FieldFeedback />
  </Field>
)
