import { isFormFieldValueValid } from 'client/helpers'
import { REQUIRED_CHILD_ADDRESS_HISTORY_IN_YEARS, MOVE_IN_DATE_FORMAT } from 'constants/index'
import moment, { Moment } from 'moment'
import { last, isEqual } from 'lodash'
import { T_Address } from 'types/index'

const isDatesOrderCorrect = (dates: Moment[]): boolean => {
  const expectedDates = dates.slice().sort((a, b) => b.diff(a))
  return isEqual(dates, expectedDates)
}
export const getIsAddressHistoryComplete = (
  currentMoveInDate: string,
  previousAddresses: T_Address[]
): boolean => {
  const currentMoveInDateMoment = moment(currentMoveInDate, MOVE_IN_DATE_FORMAT)
  const allMoveInDateMoments = [
    currentMoveInDateMoment,
    ...previousAddresses.map(address => moment(address.moveInDate, MOVE_IN_DATE_FORMAT)),
  ]

  if (previousAddresses.length < 1) {
    return (
      isFormFieldValueValid({ value: currentMoveInDate }) &&
      currentMoveInDateMoment.isBefore(moment()) &&
      isDatesOrderCorrect(allMoveInDateMoments) &&
      moment()
        .subtract(REQUIRED_CHILD_ADDRESS_HISTORY_IN_YEARS, 'year')
        .isBefore(currentMoveInDateMoment)
    )
  }

  if (previousAddresses.length > 0) {
    const lastPreviousAddressMoveInDateMoment = moment(
      last(previousAddresses).moveInDate,
      MOVE_IN_DATE_FORMAT
    )
    return (
      isFormFieldValueValid({ value: last(previousAddresses).moveInDate }) &&
      lastPreviousAddressMoveInDateMoment.isBefore(moment()) &&
      isDatesOrderCorrect(allMoveInDateMoments) &&
      moment()
        .subtract(REQUIRED_CHILD_ADDRESS_HISTORY_IN_YEARS, 'year')
        .isBefore(lastPreviousAddressMoveInDateMoment)
    )
  }
}
