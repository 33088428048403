import React from 'react'
import { Heading, Paragraph, Section, LinkTile } from '@piggybank/core'

import dict from './dictionary'

const { heading, paragraph1, links } = dict

const ImportantInformationSection = ({ primaryProduct, features }) => (
  <Section marginBottom={5}>
    <Heading level={2} accentBar={true}>
      {heading}
    </Heading>
    <Paragraph>{paragraph1}</Paragraph>
    <Section marginBottom={4}>
      {links.map(
        ({ label, url, isVisible }, index) =>
          isVisible({ primaryProduct, features }) && (
            <LinkTile
              key={index}
              data-bdd={`important-information-link-tile-${index + 1}`}
              marginBottom={2}
              heading={label}
              href={url}
              displayStatus={false}
            />
          )
      )}
    </Section>
  </Section>
)

export default ImportantInformationSection
