import React from 'react'
import { VisuallyHidden, Section } from '@piggybank/core'
import { AddAnother, Field, Label, Hint, Select, FieldFeedback } from '@piggybank/form'

import { countries } from 'lookups/index'
import { PageKey } from 'enums/index'
import { MAX_NUMBER_OF_ADDITIONAL_NATIONALITIES } from 'constants/customer'

import dict from './dictionary'

const { nationalityAndCitizenship } = dict

// TODO: In the future this component should utilise CountrySelection from common.
//       (Same as on AccountInformationPage)
const ChildNationalitySection = ({ nationality, additionalNationalities = [] }) => {
  const getSelectableAdditionalNationalities = index =>
    countries.filter(({ value }) => {
      const isCurrentValue = additionalNationalities[index] === value
      const isSelectedElsewhere = nationality !== value && !additionalNationalities.includes(value)

      return isCurrentValue || isSelectedElsewhere
    })

  const selectableNationalities = countries.filter(
    ({ value }) => !additionalNationalities.includes(value)
  )

  const selectableAdditionalNationalities = additionalNationalities.map((_, index) =>
    getSelectableAdditionalNationalities(index)
  )

  return (
    <Section marginBottom={5}>
      <Field marginBottom={0} name={`${PageKey.ChildPersonalDetailsPage}.nationality`}>
        <Label marginBottom={1}>{nationalityAndCitizenship.label}</Label>
        <Hint>{nationalityAndCitizenship.hint}</Hint>
        <Select options={selectableNationalities} />
        <FieldFeedback />
      </Field>
      <AddAnother
        name={`${PageKey.ChildPersonalDetailsPage}.additionalNationalities`}
        itemInitialValue={''}
        min={0}
        max={MAX_NUMBER_OF_ADDITIONAL_NATIONALITIES}
        renderAddButtonText={({ length }) => (
          <>
            {nationalityAndCitizenship.addAnotherLabel}
            <VisuallyHidden>{length + 1}</VisuallyHidden>
          </>
        )}
        renderRemoveButtonText={({ index }) => (
          <>
            {nationalityAndCitizenship.removeLabel}
            <VisuallyHidden>
              {nationalityAndCitizenship.label} {index + 1}
            </VisuallyHidden>
          </>
        )}
      >
        {({ path, index }) => (
          <Field marginBottom={0} name={path}>
            <Label>{nationalityAndCitizenship.label}</Label>
            <Select options={selectableAdditionalNationalities[index]} />
            <FieldFeedback />
          </Field>
        )}
      </AddAnother>
    </Section>
  )
}

export default ChildNationalitySection
