export default data => ({
  APP_NAME: data.APP_NAME,
  EXPERIENCE_API_URL: data.EXPERIENCE_API_URL,
  FEATURE_SET: data.FEATURE_SET,
  FEATURES_OVERRIDES: data.FEATURES_OVERRIDES,
  GATEWAY_PROXY: data.GATEWAY_PROXY,
  HIDE_CDD_NEW_TEMPLATE_FIELDS: data.HIDE_CDD_NEW_TEMPLATE_FIELDS,
  NODE_ENV: data.NODE_ENV,
  NODE_PORT: data.NODE_PORT,
  PUBLIC_URL: data.PUBLIC_URL,
  REACT_APP_ENV: data.REACT_APP_ENV,
  TEALIUM_ENV: data.TEALIUM_ENV,
  TIMEOUT_CLIENT_SESSION: data.TIMEOUT_CLIENT_SESSION,
  TIMEOUT_WARNING_COUNTER: data.TIMEOUT_WARNING_COUNTER,
})
