import React, { FC } from 'react'
import {
  Heading,
  HorizontalRule,
  IconStatusInformationOnlight,
  Paragraph,
  Section,
  withTrackPage,
} from '@piggybank/core'

import { ContactMethods, InteractiveContent, ReturnToOnlineBanking } from 'client/components'
import { PageKey, TealiumComponent } from 'enums/index'
import { T_PageProps } from 'types/props'

import dictionary from './dictionary'

export const CustomerIneligibilityPage: FC<T_PageProps> = () => (
  <Section marginBottom={7}>
    <Section>
      <div style={{ color: 'var(--sapphire)' }}>
        <IconStatusInformationOnlight width="2.5em" />
      </div>
      <Heading level={1}>{dictionary.mainHeading}</Heading>
      <HorizontalRule marginBottom={0} />
    </Section>
    <Section>
      <Heading level={2} accentBar={true}>
        {dictionary.whyCantIApply.heading}
      </Heading>
      <Paragraph marginBottom={4}>{dictionary.whyCantIApply.paragraph}</Paragraph>
    </Section>
    <Section>
      <Heading level={2} accentBar={true}>
        {dictionary.whatCanIDoNow.heading}
      </Heading>
      <Paragraph marginBottom={4}>
        <InteractiveContent chunks={dictionary.whatCanIDoNow.paragraph} />
      </Paragraph>
    </Section>
    <ContactMethods withAppointment={true} />
    <ReturnToOnlineBanking />
  </Section>
)

export default withTrackPage(
  PageKey.CustomerIneligibilityPage,
  TealiumComponent.NonWizardPage
)(CustomerIneligibilityPage)
