enum TealiumComponent {
  WizardPage = 'WizardPage',
  NonWizardPage = 'NonWizardPage',
  ProductSection = 'ProductSection',
  Radio = 'Radio',
  Expander = 'Expander',
  Select = 'Select',
  TextInput = 'TextInput',
  Button = 'Button',
  AddAnother = 'AddAnother',
  TileGroup = 'TileGroup',
  LinkTile = 'LinkTile',
  FilterableSelect = 'FilterableSelect',
  ChildBirthDetailsSection = 'ChildBirthDetailsSection',
  Link = 'Link',
  TimeoutWarningDialog = 'TimeoutWarningDialog',
}

export default TealiumComponent
