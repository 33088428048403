import React from 'react'
import { Heading, Paragraph, Section } from '@piggybank/core'
import { TileGroup, IconTile, Fieldset, Legend } from '@piggybank/form'

import { PageKey } from 'enums/index'
import dict from './dictionary'

const { contactOptions, heading, paragraph } = dict

const MarketingPreferencesSection = () => (
  <Section>
    <Fieldset name={`${PageKey.AccountInformationPage}.marketingPreferences`}>
      <Legend marginBottom={0}>
        <Heading level={2} accentBar={true}>
          {heading}
        </Heading>
      </Legend>
      <Paragraph>{paragraph}</Paragraph>
      <TileGroup multiSelect={true}>
        {contactOptions.map(({ label, icon, value }) => (
          <IconTile key={label} value={value} icon={icon}>
            {label}
          </IconTile>
        ))}
      </TileGroup>
    </Fieldset>
  </Section>
)

export default MarketingPreferencesSection
