enum Honorific {
  Mr = 'MR',
  Mrs = 'MRS',
  Miss = 'MISS',
  Ms = 'MS',
  Mx = 'MX',
  M = 'M',
  Dr = 'DR',
}

export default Honorific
