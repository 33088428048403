import { FeatureFlags } from 'enums/index'
import { mapValues } from 'lodash'
import flow from 'lodash/fp/flow'
import pickBy from 'lodash/fp/pickBy'
import { T_Env } from 'types/store'

export const FEATURE_FLAGS_COOKIE_NAME = 'featureFlagOverride'
export const FEATURE_FLAGS_HEADER_NAME = 'X-HSBC-Override-Feature-Flags'

export const featureConfig: FeatureConfig = {
  [FeatureFlags.COMPONENT_TESTING]: {
    description: 'Testing standalone pages enabled',
    sendToApi: false,
    enabled: {
      development: false,
      cert: false,
      production: false,
    },
  },
  [FeatureFlags.CUSTOM_TIMEOUT]: {
    description: 'Custom timeout in seconds',
    sendToApi: false,
    enabled: {
      development: false,
      cert: false,
      production: false,
    },
    defaultValue: '100',
  },
  [FeatureFlags.FAKE_CIN]: {
    description: 'Use fake CIN',
    sendToApi: false,
    enabled: {
      development: true,
      cert: false,
      production: false,
    },
    defaultValue: '1121841430',
  },
  [FeatureFlags.FAKE_NTB_PROFILE]: {
    description: 'Use mock serviceId',
    sendToApi: false,
    enabled: {
      development: true,
      cert: false,
      production: false,
    },
    defaultValue: '1',
  },
  [FeatureFlags.CUSTOMER_MATCH_SHP_ENABLED]: {
    description: 'Switch from Customer Match SAPI from PCF to SHP',
    sendToApi: true,
    enabled: {
      development: true,
      cert: true,
      production: false,
    },
  },
  [FeatureFlags.FVQ_SHP_ENABLED]: {
    description: 'Switch from PCF FVQ SAPI v3 to SHP FVQ SAPI v4',
    sendToApi: true,
    enabled: {
      development: true,
      cert: true,
      production: false,
    },
  },
  [FeatureFlags.NOV_ENABLED]: {
    description: 'Show Notice of Variation for Terms and Conditions',
    sendToApi: false,
    enabled: {
      development: false,
      cert: false,
      production: false,
    },
  },
  [FeatureFlags.SHOW_API_ERROR]: {
    description: 'Show black box with info in case of API errors',
    sendToApi: true,
    enabled: {
      development: true,
      cert: true,
      production: false,
    },
  },
}

const prepareHeader = flow(
  pickBy((_: FeatureValue, key: FeatureFlags) => key in featureConfig),
  pickBy((_: FeatureValue, key: FeatureFlags) => featureConfig[key].sendToApi)
)

export function getFeaturesHeaders(features, featureOverrides): FeaturesHeaders {
  if (!featureOverrides) {
    return {}
  }
  const featureFlags = mapValues(prepareHeader(features), (value: FeatureValue) => value.enabled)
  return { [FEATURE_FLAGS_HEADER_NAME]: JSON.stringify(featureFlags) }
}

export interface SingleFeatureOptions {
  description: string
  sendToApi: boolean
  enabled: { [key in FeatureSet]: boolean }
  defaultValue?: string | number | Partial<T_Env>
}

export interface FeatureValue {
  enabled: boolean
  value?: string
}

export type FeatureSet = 'development' | 'cert' | 'production'
export type FeaturesHeaders = { [FEATURE_FLAGS_HEADER_NAME]?: string }
export type FeatureConfig = { [key in FeatureFlags]: SingleFeatureOptions }
export type FeatureState = { [key in FeatureFlags]: FeatureValue }