export default {
  nationalityAndCitizenship: {
    label: 'Nationality and citizenship',
    hint: 'Select your child’s nationality and any countries where the child is a citizen.',
    addAnotherLabel: 'Add another nationality',
    removeLabel: 'Remove',
  },
  validation: {
    nationality: {
      required:
        'Please select your child’s nationality and any countries the child is a citizen of',
    },
  },
}
