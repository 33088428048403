import React, { FC } from 'react';
import { IconShortcutsActive } from '@piggybank/core';
import { Checkbox, Field, FieldFeedback } from '@piggybank/form';

const ApiIcon = ({ sendToApi }: { sendToApi: boolean }) => {
  return sendToApi ? (
    <>
      <IconShortcutsActive inline />{' '}
    </>
  ) : null;
};

const Feature: FC<FeatureProps> = ({ name, description, sendToApi }) => {
  return (
    <Field name={`${name}.enabled`} marginBottom={0}>
      <Checkbox>
        <ApiIcon sendToApi={sendToApi} />
        <strong>{name}</strong> &ndash; {description}
        <FieldFeedback />
      </Checkbox>
    </Field>
  );
};

interface FeatureProps {
  name: string;
  description: string;
  sendToApi: boolean;
}

export default Feature;
