import yup from 'client/validation'
import { PageKey, YesNoBoolean } from 'enums/index'

import { ChildNamesSectionValidationSchema } from 'client/pages/ChildPersonalDetailsPage/ChildNamesSection/validationSchema'
import { ChildNationalitySectionValidationSchema } from 'client/pages/ChildPersonalDetailsPage/ChildNationalitySection/validationSchema'

import {
  ChildBirthPlaceValidationSchema,
  ChildDateOfBirthValidationSchema,
} from 'client/pages/ChildPersonalDetailsPage/ChildBirthDetailsSection/validationSchema'

import dict from './dictionary'

export default (hideCddNewTemplateFields: YesNoBoolean) =>
  yup.object().shape({
    [PageKey.ChildPersonalDetailsPage]: yup.object().shape({
      ...ChildNamesSectionValidationSchema,
      gender: yup.string().required(dict.formSection.gender.validation.required),
      ...ChildDateOfBirthValidationSchema,
      ...ChildBirthPlaceValidationSchema,
      permanentResidence: yup
        .string()
        .required(dict.formSection.permanentResidence.validation.required),
      cityOfPermanentResidence:
        hideCddNewTemplateFields !== YesNoBoolean.Yes
          ? yup.string().required(dict.formSection.cityOfPermanentResidence.validation.required)
          : undefined,
      ...ChildNationalitySectionValidationSchema,
    }),
  })
