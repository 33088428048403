import { Status } from 'enums/index'

// TODO: Check later if this can be replaced with isFormFieldValueValid function
export const isValid = ({ countryCode, raw, value }) => {
  const isEmpty = !value
  const isIncompleteOrInvalid = [Status.Incomplete, Status.Invalid].includes(value)
  return !isEmpty && !isIncompleteOrInvalid
}

export function getIsValidMethod(errMsg: string) {
  return function() {
    const fieldName = this.path.split('.').reverse()[0]
    const value = this.parent[fieldName]
    return isValid(value) || this.createError({ message: errMsg })
  }
}

export default function(errMsg: string) {
  return this.test({
    name: 'phoneNumberValidation',
    test: getIsValidMethod(errMsg),
  })
}
