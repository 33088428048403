import React, { FC } from 'react'

import { Section, Heading, Paragraph, HorizontalRule } from '@piggybank/core'
import { Form, FormFeedback, Navigation, ProgressIndicator } from '@piggybank/form'

import { getStore } from 'client/store'
import { PageKey } from 'enums/index'
import { T_PageProps } from 'types/index'

import ChildAddressSection from './ChildAddressSection'
import ParentContactDetails from './ParentContactDetails'
import TaxResidency from './TaxResidency'
import ChildFinancialDetails from './ChildFinancialDetails'

import getValidationSchema from './getValidationSchema'
import getInitialValues from './getInitialValues'
import dict from './dictionary'

const pageKey = PageKey.ChildContactAndFinancialDetailsPage

const ChildContactAndFinancialDetailsPage: FC<T_PageProps> = ({ formInput }) => {
  const {
    remoteData,
    configuration: { ntbProfileId },
  } = getStore().getState()
  const { line1, postCode, addressType } = remoteData.initialResponse.currentAddress

  const dateOfBirth = !!formInput[PageKey.ProductEligibilityCheckPage]
    ? formInput[PageKey.ProductEligibilityCheckPage].dateOfBirth
    : ''
  let hideDoesLiveAtQuestion = false
  if (!line1 || !postCode || !addressType || addressType !== 'UK') hideDoesLiveAtQuestion = true

  return (
    <Form
      initialValues={getInitialValues(ntbProfileId, remoteData, hideDoesLiveAtQuestion)}
      validationSchema={getValidationSchema(dateOfBirth)}
      autoComplete="off"
    >
      {({ values, errors, setFieldValue, submitCount }) => {
        const {
          doesLiveAt,
          isStudent,
          employmentStatus,
          businessRole,
          businessType,
          industry,
        } = values[pageKey]

        return (
          <>
            <ProgressIndicator current={3} total={4} />
            <FormFeedback />
            <Section>
              <Heading level={1}>{dict.heading}</Heading>
              <Paragraph lead={true}>{dict.paragraph}</Paragraph>
              <HorizontalRule marginBottom={0} />
            </Section>
            <ChildAddressSection
              pageKey={pageKey}
              customer={remoteData.initialResponse}
              doesLiveAt={doesLiveAt}
              hideDoesLiveAtQuestion={hideDoesLiveAtQuestion}
              setFormFieldValue={setFieldValue}
              values={values}
              errors={errors}
              submitCount={submitCount}
            />
            <ParentContactDetails pageKey={pageKey} values={values[pageKey]} />
            <TaxResidency
              pageKey={pageKey}
              formValues={values}
              formErrors={errors}
              submitCount={submitCount}
            />
            <ChildFinancialDetails
              values={values[pageKey]}
              pageKey={pageKey}
              isStudent={isStudent}
              employmentStatus={employmentStatus}
              businessRole={businessRole}
              businessType={businessType}
              industry={industry}
              setFieldValue={setFieldValue}
            />
            <Navigation marginBottom={7} textMap={dict.navigation} />
          </>
        )
      }}
    </Form>
  )
}

export default ChildContactAndFinancialDetailsPage
