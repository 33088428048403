import React, { FC } from 'react'
import { Heading, IconStatusInformationOnlight, Paragraph, withTrackPage } from '@piggybank/core'

import { ReturnToOnlineBanking } from 'client/components'
import { PageKey, TealiumComponent } from 'enums/index'
import { storeNewAuthToken } from 'client/store/actions'
import { getStore } from 'client/store'
import { T_PageProps } from 'types/props'

import dict from './dictionary'

const { heading, paragraph } = dict

export const TimeoutPage: FC<T_PageProps> = () => {
  const { dispatch, getState } = getStore()
  const { authorization } = getState()
  if (authorization.token) {
    dispatch(storeNewAuthToken(null))
  }
  return (
    <>
      <span style={{ position: 'relative', left: '-5px', color: 'var(--sapphire)' }}>
        <IconStatusInformationOnlight marginBottom={2} width="3em" />
      </span>
      <Heading level={1} marginBottom={4}>
        {heading}
      </Heading>
      <Paragraph marginBottom={6}>{paragraph}</Paragraph>
      <ReturnToOnlineBanking />
    </>
  )
}

export default withTrackPage(PageKey.TimeoutPage, TealiumComponent.NonWizardPage)(TimeoutPage)
