import React from 'react'
import { Section } from '@piggybank/core'

import { Field, FieldFeedback, Label, TextInput, Select } from '@piggybank/form'

import { countries } from 'lookups/index'
import replaceApostrophes from 'client/utils/replaceApostrophes'
import { MAX_BUSINESS_NAME_LENGTH } from 'constants/customer'
import { AnnualIncome, AnnualSalary, BusinessType, Industry, Sector } from './commonComponents'
import dict from '../dictionary'

const { form } = dict.childFinancialDetailsSection

export const CompanyDirectorFinancialDetails = ({
  values,
  pageKey,
  businessType,
  industry,
  setFieldValue,
}) => (
  <Section>
    <Field
      name={`${pageKey}.controlledBusinessName`}
      onChange={(next, rest) => {
        rest.value = replaceApostrophes(rest.value)
        next(rest)
      }}
      onBlur={(next, rest) => {
        values.controlledBusinessName = values.controlledBusinessName.trim()
        next(rest)
      }}
    >
      <Label>{form.controlledBusinessName.label}</Label>
      <TextInput maxLength={MAX_BUSINESS_NAME_LENGTH} />
      <FieldFeedback />
    </Field>
    <Field name={`${pageKey}.controlledBusinessLocation`}>
      <Label>{form.controlledBusinessLocation.label}</Label>
      <Select options={countries} />
      <FieldFeedback />
    </Field>
    <BusinessType pageKey={pageKey} setFieldValue={setFieldValue} />
    <Industry pageKey={pageKey} businessType={businessType} setFieldValue={setFieldValue} />
    <Sector pageKey={pageKey} industry={industry} />
    <AnnualSalary pageKey={pageKey} />
    <AnnualIncome pageKey={pageKey} />
  </Section>
)
