/* istanbul ignore file */
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import qs from 'qs'

import createStore from 'client/store'
import { parseDomInitState } from 'client/helpers'
import { T_QueryParams } from 'types/index'
import getRootComponent from './getRootComponent'

let history: any

export const getHistory = () => history

export default () => {
  // When SSR on, initialState will be defined here
  const rootElement = document.getElementById('root')
  const initialState = parseDomInitState()

  let store: any

  // SSR on
  if (initialState) {
    store = createStore(initialState)
    ReactDOM.hydrate(getRootComponent(history, store), rootElement)
  } else {
    // SRR off
    const url = new URL(window.location.href)
    const queryString = url.search.slice(1)
    const { cin, ntbProfileId } = qs.parse(queryString) as T_QueryParams

    history = createBrowserHistory()

    if (cin) {
      store = createStore({
        authorization: {
          token: cin,
        },
        configuration: {
          serverSideRendered: false,
          cin,
          ntbProfileId,
        },
      })
      ReactDOM.render(getRootComponent(history, store), rootElement)
    } else {
      // Adding cin param and reloading
      window.location.assign(new URL(process.env.PUBLIC_URL + '?cin=1').href)
    }
  }
}
