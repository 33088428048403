import { get } from 'lodash'

import { getStore } from 'client/store'
import { getApplicationId, getCommonTags, getPageTags } from 'client/helpers'
import { T_TaggingSpecs } from 'types/index'

import {
  FinalDecision,
  MatchType,
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'

const pageKey = PageKey.ApplicationReferredPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const customApplicationReferredPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => {
      const store = getStore().getState()
      const customerMatchType = get(store, 'remoteData.submissionResponse.customerMatchType')
      const finalDecision = get(store, 'remoteData.submissionResponse.decision')
      const commonTags = {
        ...getAllCommonTags(),
        ...getApplicationId(),
        application_event: 'referred',
        funnel_step: '7',
        raw_datalayer: '3406v55',
      }

      if (
        customerMatchType === MatchType.PARTIAL_MATCH ||
        customerMatchType === MatchType.DEFINITE_MATCH
      ) {
        return {
          ...commonTags,
          application_decision: 'customer match',
        }
      }

      if (finalDecision === FinalDecision.REFER_ERROR) {
        return {
          ...commonTags,
          application_decision: 'refer technical error',
        }
      }

      return {
        ...commonTags,
        application_decision: 'refer',
      }
    },
  },
  {
    description: `Fired on ${pageKey} when customer clicks on return to online banking button.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Button,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_category: TealiumEventCategory.Content,
      event_content: 'return to online banking',
      raw_datalayer: '6067v2',
    }),
  },
]
