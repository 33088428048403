import { ariaTextBloat } from 'client/helpers/misc'
import { returnToOnlineBankingLinkLabel } from 'client/components/common/ReturnToOnlineBanking'
export const returnToOnlineBankingFullLinkLabel = `${returnToOnlineBankingLinkLabel} Opens in a new window`

export default {
  mainHeading: 'Thanks for completing your application',
  leadingParagraph:
    "We've sent a text message to the mobile number you provided with a link to upload your child's ID.",
  leadingParagraphLabel: `We've sent a text message to the mobile number you provided with a link to upload your child's ${ariaTextBloat(
    'ID'
  )}.`,
  documentUploadSection: {
    heading: "You'll need:",
    listItemOne: `'s valid passport OR UK photocard driving licence`,
    listItemTwo: ' nearby so they can take a selfie or a video',
    paragraph1: 'The link will be valid for 72 hours from the time we send it to you. ',
    paragraph2:
      "If we're not able to accept your documents for any reason we'll contact you by text or email. ",
  },
  visitBranchSection: {
    heading: 'Or visit us in branch',
    paragraph:
      "If you're not able to upload the documents within 72 hours you'll need to visit us in branch before we can open the account. Please book an appointment and bring a valid form of identification for your child. You'll need:",
    listItemOne: 'your photo ID, such as a passport or driving licence',
    listItemOneLabel: `your photo ${ariaTextBloat('ID')} such as a passport or driving licence`,
    listItemTwo:
      "your child's ID, such as a passport, birth certificate, adoption certificate or NHS letter",
    listItemTwoLabel: `your child's ${ariaTextBloat(
      'ID'
    )} such as a passport, birth certificate, adoption certificate or NHS letter`,
    listItemThree: "proof of your child's address if different to yours",
    listItemFour: 'proof of your address if this has changed',
  },
}
