export const INDUSTRIES = [
  {
    label: 'Crop and animal production, hunting and related service activities',
    value: '1',
    parentValue: '1',
  },
  {
    label: 'Forestry and logging',
    value: '2',
    parentValue: '1',
  },
  {
    label: 'Fishing and aquaculture',
    value: '3',
    parentValue: '1',
  },
  {
    label: 'Mining of coal and lignite',
    value: '4',
    parentValue: '2',
  },
  {
    label: 'Extraction of crude petroleum and natural gas',
    value: '5',
    parentValue: '2',
  },
  {
    label: 'Mining of metal ores',
    value: '6',
    parentValue: '2',
  },
  {
    label: 'Other mining and quarrying',
    value: '7',
    parentValue: '2',
  },
  {
    label: 'Mining support service activities',
    value: '8',
    parentValue: '2',
  },
  {
    label: 'Manufacture of food products',
    value: '9',
    parentValue: '3',
  },
  {
    label: 'Manufacture of beverages',
    value: '10',
    parentValue: '3',
  },
  {
    label: 'Manufacture of tobacco products',
    value: '11',
    parentValue: '3',
  },
  {
    label: 'Manufacture of textiles',
    value: '12',
    parentValue: '3',
  },
  {
    label: 'Manufacture of wearing apparel',
    value: '13',
    parentValue: '3',
  },
  {
    label: 'Manufacture of leather and related products',
    value: '14',
    parentValue: '3',
  },
  {
    label:
      'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
    value: '15',
    parentValue: '3',
  },
  {
    label: 'Manufacture of paper and paper products',
    value: '16',
    parentValue: '3',
  },
  {
    label: 'Printing and reproduction of recorded media',
    value: '17',
    parentValue: '3',
  },
  {
    label: 'Manufacture of coke and refined petroleum products',
    value: '18',
    parentValue: '3',
  },
  {
    label: 'Manufacture of chemicals and chemical products',
    value: '19',
    parentValue: '3',
  },
  {
    label: 'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
    value: '20',
    parentValue: '3',
  },
  {
    label: 'Manufacture of rubber and plastic products',
    value: '21',
    parentValue: '3',
  },
  {
    label: 'Manufacture of other non-metallic mineral products',
    value: '22',
    parentValue: '3',
  },
  {
    label: 'Manufacture of basic metals',
    value: '23',
    parentValue: '3',
  },
  {
    label: 'Manufacture of fabricated metal products, except machinery and equipment',
    value: '24',
    parentValue: '3',
  },
  {
    label: 'Manufacture of computer, electronic and optical products',
    value: '25',
    parentValue: '3',
  },
  {
    label: 'Manufacture of electrical equipment',
    value: '26',
    parentValue: '3',
  },
  {
    label: 'Manufacture of machinery and equipment n.e.c.',
    value: '27',
    parentValue: '3',
  },
  {
    label: 'Manufacture of motor vehicles, trailers and semi-trailers',
    value: '28',
    parentValue: '3',
  },
  {
    label: 'Manufacture of other transport equipment',
    value: '29',
    parentValue: '3',
  },
  {
    label: 'Manufacture of furniture',
    value: '30',
    parentValue: '3',
  },
  {
    label: 'Other manufacturing',
    value: '31',
    parentValue: '3',
  },
  {
    label: 'Repair and installation of machinery and equipment',
    value: '32',
    parentValue: '3',
  },
  {
    label: 'Electricity, gas, steam and air conditioning supply',
    value: '33',
    parentValue: '4',
  },
  {
    label: 'Water collection, treatment and supply',
    value: '34',
    parentValue: '5',
  },
  {
    label: 'Sewerage',
    value: '35',
    parentValue: '5',
  },
  {
    label: 'Waste collection, treatment and disposal activities; materials recovery',
    value: '36',
    parentValue: '5',
  },
  {
    label: 'Remediation activities and other waste management services',
    value: '37',
    parentValue: '5',
  },
  {
    label: 'Construction of buildings',
    value: '38',
    parentValue: '6',
  },
  {
    label: 'Civil engineering',
    value: '39',
    parentValue: '6',
  },
  {
    label: 'Specialised construction activities',
    value: '40',
    parentValue: '6',
  },
  {
    label: 'Wholesale and retail trade and repair of motor vehicles and motorcycles',
    value: '41',
    parentValue: '7',
  },
  {
    label: 'Wholesale trade, except of motor vehicles and motorcycles',
    value: '42',
    parentValue: '7',
  },
  {
    label: 'Retail trade, except of motor vehicles and motorcycles',
    value: '43',
    parentValue: '7',
  },
  {
    label: 'Land transport and transport via pipelines',
    value: '44',
    parentValue: '8',
  },
  {
    label: 'Water transport',
    value: '45',
    parentValue: '8',
  },
  {
    label: 'Air transport',
    value: '46',
    parentValue: '8',
  },
  {
    label: 'Warehousing and support activities for transportation',
    value: '47',
    parentValue: '8',
  },
  {
    label: 'Postal and courier activities',
    value: '48',
    parentValue: '8',
  },
  {
    label: 'Accomodation',
    value: '49',
    parentValue: '9',
  },
  {
    label: 'Food and beverage service activities',
    value: '50',
    parentValue: '9',
  },
  {
    label: 'Publishing activities',
    value: '51',
    parentValue: '10',
  },
  {
    label:
      'Motion picture, video and television programme production, sound recording and music publishing activities',
    value: '52',
    parentValue: '10',
  },
  {
    label: 'Programming and broadcasting activities',
    value: '53',
    parentValue: '10',
  },
  {
    label: 'Telecommunications',
    value: '54',
    parentValue: '10',
  },
  {
    label: 'Computer programming, consultancy and related activities',
    value: '55',
    parentValue: '10',
  },
  {
    label: 'Information service activities',
    value: '56',
    parentValue: '10',
  },
  {
    label: 'Financial service activities, except insurance and pension funding',
    value: '57',
    parentValue: '11',
  },
  {
    label: 'Insurance, reinsurance and pension funding, except compulsory social security',
    value: '58',
    parentValue: '11',
  },
  {
    label: 'Activities auxiliary to financial services and insurance activities',
    value: '59',
    parentValue: '11',
  },
  {
    label: 'Real estate activities',
    value: '60',
    parentValue: '12',
  },
  {
    label: 'Legal and accounting activities',
    value: '61',
    parentValue: '13',
  },
  {
    label: 'Activities of head offices; management consultancy activities',
    value: '62',
    parentValue: '13',
  },
  {
    label: 'Architectural and engineering activities; technical testing and analysis',
    value: '63',
    parentValue: '13',
  },
  {
    label: 'Scientific research and development',
    value: '64',
    parentValue: '13',
  },
  {
    label: 'Advertising and market research',
    value: '65',
    parentValue: '13',
  },
  {
    label: 'Other professional, scientific and technical activities',
    value: '66',
    parentValue: '13',
  },
  {
    label: 'Veterinary activities',
    value: '67',
    parentValue: '13',
  },
  {
    label: 'Rental and leasing activities',
    value: '68',
    parentValue: '14',
  },
  {
    label: 'Employment activities',
    value: '69',
    parentValue: '14',
  },
  {
    label: 'Travel agency, tour operator and other reservation service and related activities',
    value: '70',
    parentValue: '14',
  },
  {
    label: 'Security and investigation activities',
    value: '71',
    parentValue: '14',
  },
  {
    label: 'Services to buildings and landscape activities',
    value: '72',
    parentValue: '14',
  },
  {
    label: 'Office administrative, office support and other business support activities',
    value: '73',
    parentValue: '14',
  },
  {
    label: 'Public administration and defence; compulsory social security',
    value: '74',
    parentValue: '15',
  },
  {
    label: 'Education',
    value: '75',
    parentValue: '16',
  },
  {
    label: 'Human health activities',
    value: '76',
    parentValue: '17',
  },
  {
    label: 'Residential care activities',
    value: '77',
    parentValue: '17',
  },
  {
    label: 'Social work activities without accommodation',
    value: '78',
    parentValue: '17',
  },
  {
    label: 'Creative, arts and entertainment activities',
    value: '79',
    parentValue: '18',
  },
  {
    label: 'Libraries, archives, museums and other cultural activities',
    value: '80',
    parentValue: '18',
  },
  {
    label: 'Gambling and betting activities',
    value: '81',
    parentValue: '18',
  },
  {
    label: 'Sports activities and amusement and recreation activities',
    value: '82',
    parentValue: '18',
  },
  {
    label: 'Activities of membership organisations',
    value: '83',
    parentValue: '19',
  },
  {
    label: 'Repair of computers and personal and household goods',
    value: '84',
    parentValue: '19',
  },
  {
    label: 'Other personal service activities',
    value: '85',
    parentValue: '19',
  },
  {
    label: 'Activities of households as employers of domestic personnel',
    value: '86',
    parentValue: '20',
  },
  {
    label:
      'Undifferentiated goods- and services-producing activities of private households for own use',
    value: '87',
    parentValue: '20',
  },
  {
    label: 'Activities of extraterritorial organisations and bodies',
    value: '88',
    parentValue: '21',
  },
]
