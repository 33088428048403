enum TealiumEventAction {
  Dropdown = 'dropdown',
  Radio = 'radio',
  Accordion = 'accordion',
  Button = 'button',
  Textbox = 'textbox',
  Application = 'application',
  System = 'system',
  Checkbox = 'checkbox',
  OnSite = 'onsite',
  Popup = 'popup',
}

export default TealiumEventAction
