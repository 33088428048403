import { PageKey, YesNoBoolean, MoneySource } from 'enums/index'
import { T_FormInput } from 'types/form'

export default {
  [PageKey.ProductEligibilityCheckPage]: {
    dateOfBirth: '2006-05-27',
  },
  [PageKey.ChildPersonalDetailsPage]: {
    title: 'MR',
    firstNames: 'John',
    familyName: 'Johnson',
    hasPreviousNames: 'no',
    gender: 'MALE',
    dateOfBirth: '2006-08-23',
    cityOfPermanentResidence: 'London',
  },
  [PageKey.ChildContactAndFinancialDetailsPage]: {
    doesLiveAt: YesNoBoolean.Yes,
    whenDidStartLivingHere: '2006-08',
    phoneNumber: {
      countryCode: 'GB',
      raw: '7845305853',
      value: `+447845305853`,
    },
    email: 'john.johnson@mail.com',
    isStudent: YesNoBoolean.Yes,
    institutionName: 'School Of Rock',
  },
  [PageKey.AccountInformationPage]: {
    regularPaymentSource: MoneySource.TransferFromFamily,
    firstDepositSource: MoneySource.TransferFromFamily,
    initialDeposit: '100',
    marketingPreferences: ['TELEPHONE', 'SMS', 'EMAIL', 'LETTER'],
    accountOpeningConsent1: true,
    accountOpeningConsent2: true,
    accountOpeningConsent3: true,
  },
} as T_FormInput
