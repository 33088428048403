import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  ButtonRow,
  ModalDialog,
  Paragraph,
  Section,
  useTrackingContext,
  VisuallyHidden,
} from '@piggybank/core'
import { SessionState, useSessionRefresh, useSessionState } from '@atlas/session'
import { timeoutModalOpenEvent } from 'client/components/singletons/Analytics'
import { formPages } from 'client/pages'
import { getStore } from 'client/store'
import interpolate from 'client/utils/interpolate'
import useNow from 'client/utils/useNow'
import { PageKey } from 'enums/index'
import copy from './dictionary'

const TimeoutWarning: FC<{
  onNavigate: (pageId: number, values?: any) => void
}> = ({ onNavigate }) => {
  const sessionState = useSessionState()
  const now = useNow()
  const refreshSession = useSessionRefresh()
  const { getState } = getStore()
  const {
    environment: { TIMEOUT_WARNING_COUNTER },
  } = getState()
  const [startCounter, setStartCounter] = useState(0)
  const [clickable, setClickable] = useState(true)
  const { track } = useTrackingContext()
  const { title, content, timeRemaining, buttonLabel } = copy

  useEffect(() => {
    if (sessionState === SessionState.TIMEOUT) {
      onNavigate(formPages[PageKey.TimeoutPage].id)
    }
    if (sessionState === SessionState.WARNING) {
      track(timeoutModalOpenEvent)
      setStartCounter(Date.now())
    }
  }, [sessionState, track, onNavigate])

  const health = async () => {
    try {
      await refreshSession()
      setClickable(true)
    } catch (error) {
      if (sessionState === SessionState.TIMEOUT) {
        return
      }
      onNavigate(formPages[PageKey.TechnicalErrorPage].id)
    }
  }

  const handleClick = () => {
    setClickable(false)
    return health()
  }

  const timeRemainingCounter =
    Math.floor((parseInt(TIMEOUT_WARNING_COUNTER, 10) - now + startCounter) / 1000) || 1

  return (
    <ModalDialog
      data-bdd="modal"
      getApplicationNode={() => document.getElementById('root')}
      onClose={clickable ? handleClick : null}
      show={sessionState === SessionState.WARNING}
      title={title}
    >
      <VisuallyHidden data-bdd="hidden-title">{title}</VisuallyHidden>
      <Section marginBottom={2}>
        {content.map((text, index) => (
          <Paragraph data-bdd={`paragraph-${index + 1}`} key={`paragraph-${index}`}>
            {text}
          </Paragraph>
        ))}
        <Paragraph data-bdd={`paragraph-${content.length + 1}`}>
          <span aria-hidden={true} data-bdd="counter">
            {timeRemaining[0]} <strong>{timeRemainingCounter}</strong> {timeRemaining[1]}
          </span>
          <VisuallyHidden aria-live="polite" data-bdd="hidden-counter" {...{ role: 'timer' }}>
            {interpolate(timeRemaining.join(' {counter} '), {
              counter: `${timeRemainingCounter}`,
            })}
          </VisuallyHidden>
        </Paragraph>
      </Section>
      <ButtonRow>
        <Button data-bdd="button" onClick={clickable ? handleClick : null}>
          {buttonLabel}
        </Button>
      </ButtonRow>
    </ModalDialog>
  )
}

export default TimeoutWarning
