export const SECTORS = [
  { label: 'Growing cereal(nonrice)/pod crop/oilseed', value: '01110', parentValue: '1' },
  { label: 'Growing of rice', value: '01120', parentValue: '1' },
  { label: 'Growing of veg, melons, roots and tubers', value: '01130', parentValue: '1' },
  { label: 'Growing of sugar cane', value: '01140', parentValue: '1' },
  { label: 'Growing of tobacco', value: '01150', parentValue: '1' },
  { label: 'Growing of fibre crops', value: '01160', parentValue: '1' },
  { label: 'Growing of other non-perennial crops', value: '01190', parentValue: '1' },
  { label: 'Growing of grapes', value: '01210', parentValue: '1' },
  { label: 'Growing of tropical or subtropical fruit', value: '01220', parentValue: '1' },
  { label: 'Growing of citrus fruits', value: '01230', parentValue: '1' },
  { label: 'Growing of pome fruits and stone fruits', value: '01240', parentValue: '1' },
  { label: 'Growing other tree or bush fruit or nuts', value: '01250', parentValue: '1' },
  { label: 'Growing of oleaginous fruits', value: '01260', parentValue: '1' },
  { label: 'Growing of beverage crops', value: '01270', parentValue: '1' },
  { label: 'Growing spices, aromatic and drug crops', value: '01280', parentValue: '1' },
  { label: 'Growing of other perennial crops', value: '01290', parentValue: '1' },
  { label: 'Plant propagation', value: '01300', parentValue: '1' },
  { label: 'Raising of dairy cattle', value: '01410', parentValue: '1' },
  { label: 'Raising of other cattle and buffaloes', value: '01420', parentValue: '1' },
  { label: 'Farming of cattle in less favoured areas', value: '01421', parentValue: '1' },
  { label: 'Farming mixed beef & dairy cattle', value: '01422', parentValue: '1' },
  { label: 'Raising of horses and other equines', value: '01430', parentValue: '1' },
  { label: 'Raising of camels and camelids', value: '01440', parentValue: '1' },
  { label: 'Raising of sheep and goats', value: '01450', parentValue: '1' },
  { label: 'Farm sheep and goat - less favoured area', value: '01451', parentValue: '1' },
  { label: 'Raising of swine/pigs', value: '01460', parentValue: '1' },
  { label: 'Raising of poultry', value: '01470', parentValue: '1' },
  { label: 'Farming poultry for eggs', value: '01471', parentValue: '1' },
  { label: 'Raising of other animals', value: '01490', parentValue: '1' },
  { label: 'Mixed farming', value: '01500', parentValue: '1' },
  { label: 'Support activities for crop production', value: '01610', parentValue: '1' },
  { label: 'Farm animal boarding and care', value: '01621', parentValue: '1' },
  { label: 'Animal production support(not farm help)', value: '01629', parentValue: '1' },
  { label: 'Post-harvest crop activities', value: '01630', parentValue: '1' },
  { label: 'Seed processing for propagation', value: '01640', parentValue: '1' },
  { label: 'Hunter, trapper or related activities', value: '01700', parentValue: '1' },
  { label: 'Silviculture and other forestry activity', value: '02100', parentValue: '2' },
  { label: 'Logging', value: '02200', parentValue: '2' },
  { label: 'Gathering of wild growing non-wood items', value: '02300', parentValue: '2' },
  { label: 'Support services to forestry', value: '02400', parentValue: '2' },
  { label: 'Marine fishing', value: '03110', parentValue: '3' },
  { label: 'Freshwater fishing', value: '03120', parentValue: '3' },
  { label: 'Marine aquaculture', value: '03210', parentValue: '3' },
  { label: 'Freshwater aquaculture', value: '03220', parentValue: '3' },
  { label: 'Mining hard coal from underground mines', value: '05101', parentValue: '4' },
  { label: 'Mining coal from open cast coal working', value: '05102', parentValue: '5' },
  { label: 'Mining of lignite', value: '05200', parentValue: '7' },
  { label: 'Extraction of crude petroleum', value: '06100', parentValue: '5' },
  { label: 'Extraction of natural gas', value: '06200', parentValue: '5' },
  { label: 'Mining of iron ores', value: '07100', parentValue: '6' },
  { label: 'Mining of uranium and thorium ores', value: '07210', parentValue: '7' },
  { label: 'Mining of other non-ferrous metal ores', value: '07290', parentValue: '7' },
  { label: 'Quarrying ornamental/building stone etc', value: '08110', parentValue: '7' },
  { label: 'Ops gravel/sand pits;mining clay/kaolin', value: '08120', parentValue: '7' },
  { label: 'Mining of chemical or fertiliser mineral', value: '08910', parentValue: '4' },
  { label: 'Extraction of peat', value: '08920', parentValue: '5' },
  { label: 'Extraction of salt', value: '08930', parentValue: '5' },
  { label: 'Other mining and quarrying n.e.c.', value: '08990', parentValue: '7' },
  { label: 'Support activity petrol/gas extraction', value: '09100', parentValue: '8' },
  { label: 'Support activity for other mines quarry', value: '09900', parentValue: '8' },
  { label: 'Processing and preserving of meat', value: '10110', parentValue: '9' },
  { label: 'Processing or preserving of poultry meat', value: '10120', parentValue: '9' },
  { label: 'Production of meat products', value: '10130', parentValue: '9' },
  { label: 'Processing of fish, crustacean, molluscs', value: '10200', parentValue: '9' },
  { label: 'Processing and preserving of potatoes', value: '10310', parentValue: '9' },
  { label: 'Manufacture of fruit and vegetable juice', value: '10320', parentValue: '9' },
  { label: 'Other process/preserving of fruit & veg', value: '10390', parentValue: '9' },
  { label: 'Manufacture of oils and fats', value: '10410', parentValue: '9' },
  { label: 'Manufac margarine and similar edible fat', value: '10420', parentValue: '9' },
  { label: 'Liquid milk and cream production', value: '10511', parentValue: '9' },
  { label: 'Butter and cheese production', value: '10512', parentValue: '9' },
  { label: 'Manufac milk products (not milk/cheese)', value: '10519', parentValue: '9' },
  { label: 'Manufacture of ice cream', value: '10520', parentValue: '9' },
  { label: 'Grain milling', value: '10611', parentValue: '9' },
  { label: "Manufac b'fast cereals/cereal-based food", value: '10612', parentValue: '9' },
  { label: 'Manufac of starches and starch products', value: '10620', parentValue: '9' },
  { label: 'Manufac bread; fresh pastry goods/cakes', value: '10710', parentValue: '9' },
  { label: 'Manufac rusks/biscuits/pastry goods/cake', value: '10720', parentValue: '9' },
  { label: 'Manufac macaroni/noodles/couscous etc', value: '10730', parentValue: '9' },
  { label: 'Manufacture of sugar', value: '10810', parentValue: '9' },
  { label: 'Manufac cocoa/chocolate confectionery', value: '10821', parentValue: '9' },
  { label: 'Manufacture of sugar confectionery', value: '10822', parentValue: '9' },
  { label: 'Tea processing', value: '10831', parentValue: '9' },
  { label: 'Coffee production and coffee substitutes', value: '10832', parentValue: '9' },
  { label: 'Manufacture of condiments and seasonings', value: '10840', parentValue: '9' },
  { label: 'Manufacture of prepared meals and dishes', value: '10850', parentValue: '9' },
  { label: 'Manufac homogenised/dietetic food stuffs', value: '10860', parentValue: '9' },
  { label: 'Manufac of other food products n.e.c.', value: '10890', parentValue: '9' },
  { label: 'Manufac prepared feeds for farm animals', value: '10910', parentValue: '9' },
  { label: 'Manufacture of prepared pet foods', value: '10920', parentValue: '9' },
  { label: 'Distilling and blending of spirits', value: '11010', parentValue: '10' },
  { label: 'Manufacture of wine from grape', value: '11020', parentValue: '10' },
  { label: 'Manufac of cider and other fruit wines', value: '11030', parentValue: '10' },
  { label: 'Manufac other nondistilld fermentd drink', value: '11040', parentValue: '10' },
  { label: 'Manufacture of beer', value: '11050', parentValue: '10' },
  { label: 'Manufacture of malt', value: '11060', parentValue: '10' },
  { label: 'Manufac soft drinks/mineral waters', value: '11070', parentValue: '10' },
  { label: 'Manufacture of tobacco products', value: '12000', parentValue: '11' },
  { label: 'Preparing and spinning of textile fibre', value: '13100', parentValue: '12' },
  { label: 'Weaving of textiles', value: '13200', parentValue: '12' },
  { label: 'Finishing of textiles', value: '13300', parentValue: '12' },
  { label: 'Manufac of knitted and crocheted fabrics', value: '13910', parentValue: '12' },
  { label: 'Manufacture of soft furnishings', value: '13921', parentValue: '12' },
  { label: 'Manufacture of canvas goods, sacks etc.', value: '13922', parentValue: '12' },
  { label: "Manufac h'hold textile not soft furnish", value: '13923', parentValue: '12' },
  { label: 'Manufac woven or tufted carpets and rugs', value: '13931', parentValue: '12' },
  { label: 'Manufac carpet/rug (not woven or tufted)', value: '13939', parentValue: '12' },
  { label: 'Manufac cordage, rope, twine and netting', value: '13940', parentValue: '12' },
  { label: 'Manufac non-wovens, except apparel', value: '13950', parentValue: '12' },
  { label: 'Manufac other technical/industrl textile', value: '13960', parentValue: '12' },
  { label: 'Manufacture of other textiles n.e.c.', value: '13990', parentValue: '12' },
  { label: 'Manufacture of leather clothes', value: '14110', parentValue: '13' },
  { label: 'Manufacture of workwear', value: '14120', parentValue: '13' },
  { label: "Manufac men's outwear(not leather/work)", value: '14131', parentValue: '13' },
  { label: 'Manufac womens outwear(not leatherwear)', value: '14132', parentValue: '13' },
  { label: "Manufacture of men's underwear", value: '14141', parentValue: '13' },
  { label: "Manufacture of women's underwear", value: '14142', parentValue: '13' },
  { label: 'Manufac other wearing apparel/accessory', value: '14190', parentValue: '13' },
  { label: 'Manufacture of articles of fur', value: '14200', parentValue: '13' },
  { label: 'Manufac of knitted and crocheted hosiery', value: '14310', parentValue: '13' },
  { label: 'Manufac knitted and crocheted apparel', value: '14390', parentValue: '13' },
  { label: 'Leather/fur; tanning/dressing/dyeing', value: '15110', parentValue: '14' },
  { label: 'Manufac luggage/handbag/saddlery/harness', value: '15120', parentValue: '14' },
  { label: 'Manufacture of footwear', value: '15200', parentValue: '14' },
  { label: 'Sawmilling and planing of wood', value: '16100', parentValue: '15' },
  { label: 'Manufac veneer sheets/wood-based panels', value: '16210', parentValue: '15' },
  { label: 'Manufacture of assembled parquet floors', value: '16220', parentValue: '15' },
  { label: "Manufac other builders' carpentry etc", value: '16230', parentValue: '15' },
  { label: 'Manufacture of wooden containers', value: '16240', parentValue: '15' },
  { label: 'Manufac other product of wood/cork/straw', value: '16290', parentValue: '15' },
  { label: 'Manufacture of pulp', value: '17110', parentValue: '16' },
  { label: 'Manufacture of paper and paperboard', value: '17120', parentValue: '16' },
  { label: 'Manufac ribbed paper;paper sacks/bags', value: '17211', parentValue: '16' },
  { label: 'Manufac paper containers not sacks/bags', value: '17219', parentValue: '16' },
  { label: 'Manufac household and sanitary goods', value: '17220', parentValue: '16' },
  { label: 'Manufacture of paper stationery', value: '17230', parentValue: '16' },
  { label: 'Manufacture of wallpaper', value: '17240', parentValue: '16' },
  { label: 'Manufac other items of paper/paperboard', value: '17290', parentValue: '16' },
  { label: 'Printing of newspapers', value: '18110', parentValue: '17' },
  { label: 'Manufacture of printed labels', value: '18121', parentValue: '17' },
  { label: 'Printing (not newspapers/labels) n.e.c.', value: '18129', parentValue: '17' },
  { label: 'Pre-press and pre-media services', value: '18130', parentValue: '17' },
  { label: 'Binding and related services', value: '18140', parentValue: '17' },
  { label: 'Reproduction of sound recording', value: '18201', parentValue: '17' },
  { label: 'Reproduction of video recording', value: '18202', parentValue: '17' },
  { label: 'Reproduction of computer media', value: '18203', parentValue: '17' },
  { label: 'Manufacture of coke oven products', value: '19100', parentValue: '18' },
  { label: 'Mineral oil refining', value: '19201', parentValue: '18' },
  { label: 'Other treatment of petroleum products', value: '19209', parentValue: '18' },
  { label: 'Manufacture of industrial gases', value: '20110', parentValue: '19' },
  { label: 'Manufacture of dyes and pigments', value: '20120', parentValue: '19' },
  { label: 'Manufac other inorganic basic chemicals', value: '20130', parentValue: '19' },
  { label: 'Manufac of other organic basic chemicals', value: '20140', parentValue: '19' },
  { label: 'Manufac fertiliser or nitrogen compounds', value: '20150', parentValue: '19' },
  { label: 'Manufacture of plastics in primary forms', value: '20160', parentValue: '19' },
  { label: 'Manufac synthetic rubber in primary form', value: '20170', parentValue: '19' },
  { label: 'Manufac pesticide/other agrochem product', value: '20200', parentValue: '19' },
  { label: 'Manufac paint/varnishes/mastics/sealants', value: '20301', parentValue: '19' },
  { label: 'Manufacture of printing ink', value: '20302', parentValue: '19' },
  { label: 'Manufacture of soap and detergents', value: '20411', parentValue: '19' },
  { label: 'Manufac cleaning/polishing preparations', value: '20412', parentValue: '19' },
  { label: 'Manufac perfumes and toilet preparations', value: '20420', parentValue: '19' },
  { label: 'Manufacture of explosives', value: '20510', parentValue: '19' },
  { label: 'Manufacture of glues', value: '20520', parentValue: '19' },
  { label: 'Manufacture of essential oils', value: '20530', parentValue: '19' },
  { label: 'Manufac of other chemical product n.e.c.', value: '20590', parentValue: '19' },
  { label: 'Manufacture of man-made fibres', value: '20600', parentValue: '19' },
  { label: 'Manufac of basic pharmaceutical products', value: '21100', parentValue: '20' },
  { label: 'Manufac of pharmaceutical preparations', value: '21200', parentValue: '20' },
  { label: 'Manufac rubber tyre/tube; retread/builds', value: '22110', parentValue: '21' },
  { label: 'Manufacture of other rubber products', value: '22190', parentValue: '21' },
  { label: 'Manufac plastic plates, sheets, tube etc', value: '22210', parentValue: '21' },
  { label: 'Manufacture of plastic packing goods', value: '22220', parentValue: '21' },
  { label: "Manufacture of builders' ware of plastic", value: '22230', parentValue: '21' },
  { label: 'Manufacture of other plastic products', value: '22290', parentValue: '21' },
  { label: 'Manufacture of flat glass', value: '23110', parentValue: '22' },
  { label: 'Shaping and processing of flat glass', value: '23120', parentValue: '22' },
  { label: 'Manufacture of hollow glass', value: '23130', parentValue: '22' },
  { label: 'Manufacture of glass fibres', value: '23140', parentValue: '22' },
  { label: 'Manufac other glass & special glassware', value: '23190', parentValue: '22' },
  { label: 'Manufacture of refractory products', value: '23200', parentValue: '22' },
  { label: 'Manufacture of ceramic tiles and flags', value: '23310', parentValue: '22' },
  { label: 'Manufac clay baked bricks/tiles etc', value: '23320', parentValue: '22' },
  { label: 'Manufac ceramic/ornament household items', value: '23410', parentValue: '22' },
  { label: 'Manufacture of ceramic sanitary fixtures', value: '23420', parentValue: '22' },
  { label: 'Manufac ceramic insulators/fittings', value: '23430', parentValue: '22' },
  { label: 'Manufac other technical ceramic products', value: '23440', parentValue: '22' },
  { label: 'Manufacture of other ceramic products', value: '23490', parentValue: '22' },
  { label: 'Manufacture of cement', value: '23510', parentValue: '22' },
  { label: 'Manufacture of lime and plaster', value: '23520', parentValue: '22' },
  { label: 'Manufac concrete products (construction)', value: '23610', parentValue: '22' },
  { label: 'Manufac plaster product for building use', value: '23620', parentValue: '22' },
  { label: 'Manufacture of ready-mixed concrete', value: '23630', parentValue: '22' },
  { label: 'Manufacture of mortars', value: '23640', parentValue: '22' },
  { label: 'Manufacture of fibre cement', value: '23650', parentValue: '22' },
  { label: 'Manufac othr itm concrete/plaster/cement', value: '23690', parentValue: '22' },
  { label: 'Cutting, shaping and finishing of stone', value: '23700', parentValue: '22' },
  { label: 'Production of abrasive products', value: '23910', parentValue: '22' },
  { label: 'Manufac other non-metallic mineral prods', value: '23990', parentValue: '22' },
  { label: 'Manufac basic iron/steel/ferro-alloys', value: '24100', parentValue: '23' },
  { label: 'Manufac steel tubes/pipes/other fittings', value: '24200', parentValue: '23' },
  { label: 'Cold drawing of bars', value: '24310', parentValue: '23' },
  { label: 'Cold rolling of narrow strip', value: '24320', parentValue: '23' },
  { label: 'Cold forming or folding', value: '24330', parentValue: '23' },
  { label: 'Cold drawing of wire', value: '24340', parentValue: '23' },
  { label: 'Precious metals production', value: '24410', parentValue: '23' },
  { label: 'Aluminium production', value: '24420', parentValue: '23' },
  { label: 'Lead, zinc and tin production', value: '24430', parentValue: '23' },
  { label: 'Copper production', value: '24440', parentValue: '23' },
  { label: 'Other non-ferrous metal production', value: '24450', parentValue: '23' },
  { label: 'Processing of nuclear fuel', value: '24460', parentValue: '23' },
  { label: 'Casting of iron', value: '24510', parentValue: '23' },
  { label: 'Casting of steel', value: '24520', parentValue: '23' },
  { label: 'Casting of light metals', value: '24530', parentValue: '23' },
  { label: 'Casting of other non-ferrous metals', value: '24540', parentValue: '23' },
  { label: 'Manufac metal structures and (parts of)', value: '25110', parentValue: '24' },
  { label: 'Manufac of doors and windows of metal', value: '25120', parentValue: '24' },
  { label: 'Manufac central heating radiator/boilers', value: '25210', parentValue: '24' },
  { label: 'Manufac other tanks and metal containers', value: '25290', parentValue: '24' },
  { label: 'Manufac steam producers not water boiler', value: '25300', parentValue: '24' },
  { label: 'Manufacture of weapons and ammunition', value: '25400', parentValue: '24' },
  { label: 'Forge/press/stamp/roll-form of metal', value: '25500', parentValue: '24' },
  { label: 'Treatment and coating of metals', value: '25610', parentValue: '24' },
  { label: 'Machining', value: '25620', parentValue: '24' },
  { label: 'Manufacture of cutlery', value: '25710', parentValue: '24' },
  { label: 'Manufacture of locks and hinges', value: '25720', parentValue: '24' },
  { label: 'Manufacture of tools', value: '25730', parentValue: '24' },
  { label: 'Manufac steel drum or similar containers', value: '25910', parentValue: '24' },
  { label: 'Manufacture of light metal packaging', value: '25920', parentValue: '24' },
  { label: 'Manufac wire products, chain and springs', value: '25930', parentValue: '24' },
  { label: 'Manufac rivets, screws, nuts, bolts etc', value: '25940', parentValue: '24' },
  { label: 'Manufac other fabricated metal products', value: '25990', parentValue: '24' },
  { label: 'Manufacture of electronic components', value: '26110', parentValue: '25' },
  { label: 'Manufacture of loaded electronic boards', value: '26120', parentValue: '25' },
  { label: 'Manufac computer or peripheral equipment', value: '26200', parentValue: '25' },
  { label: 'Manufac telegraph/telephone apparatus', value: '26301', parentValue: '25' },
  { label: 'Manufac communication kit (not phones)', value: '26309', parentValue: '25' },
  { label: 'Manufacture of consumer electronics', value: '26400', parentValue: '25' },
  { label: 'Manufac electric instruments for testing', value: '26511', parentValue: '25' },
  { label: 'Manufac electronic process control kit', value: '26512', parentValue: '25' },
  { label: 'Manufac non-electric instrument for test', value: '26513', parentValue: '25' },
  { label: 'Manufac nonelectronic process contrl kit', value: '26514', parentValue: '25' },
  { label: 'Manufacture of watches and clocks', value: '26520', parentValue: '25' },
  { label: 'Manufac irradiation, electromedical kit', value: '26600', parentValue: '25' },
  { label: 'Manufac of optical precision instruments', value: '26701', parentValue: '25' },
  { label: 'Manufac photographic/filming equipment', value: '26702', parentValue: '25' },
  { label: 'Manufac of magnetic and optical media', value: '26800', parentValue: '25' },
  { label: 'Manufac electric motors, generators etc', value: '27110', parentValue: '26' },
  { label: 'Manufac electricity distribution/control', value: '27120', parentValue: '26' },
  { label: 'Manufac of batteries and accumulators', value: '27200', parentValue: '26' },
  { label: 'Manufacture of fibre optic cables', value: '27310', parentValue: '26' },
  { label: 'Manufac othr electronic and wires/cables', value: '27320', parentValue: '26' },
  { label: 'Manufacture of wiring devices', value: '27330', parentValue: '26' },
  { label: 'Manufac of electric lighting equipment', value: '27400', parentValue: '26' },
  { label: 'Manufac of electric domestic appliances', value: '27510', parentValue: '26' },
  { label: 'Manufac non-electric domestic appliances', value: '27520', parentValue: '26' },
  { label: 'Manufac of other electrical equipment', value: '27900', parentValue: '26' },
  { label: 'Manufac engines not aircraft/car engines', value: '28110', parentValue: '27' },
  { label: 'Manufacture of fluid power equipment', value: '28120', parentValue: '27' },
  { label: 'Manufacture of pumps', value: '28131', parentValue: '27' },
  { label: 'Manufacture of compressors', value: '28132', parentValue: '27' },
  { label: 'Manufacture of other taps and valves', value: '28140', parentValue: '27' },
  { label: 'Manufac bearings/gears/driving elements', value: '28150', parentValue: '27' },
  { label: 'Manufac ovens, furnaces, furnace burners', value: '28210', parentValue: '27' },
  { label: 'Manufac lifting and handling equipment', value: '28220', parentValue: '27' },
  { label: 'Manufac office machines(not computers)', value: '28230', parentValue: '27' },
  { label: 'Manufacture of power-driven hand tools', value: '28240', parentValue: '27' },
  { label: 'Manufac nondomestic cooling/venting gear', value: '28250', parentValue: '27' },
  { label: 'Manufac other general-purpose machinery', value: '28290', parentValue: '27' },
  { label: 'Manufacture of agricultural tractors', value: '28301', parentValue: '27' },
  { label: 'Manufac agriculture machine(not tractor)', value: '28302', parentValue: '27' },
  { label: 'Manufacture of metal forming machinery', value: '28410', parentValue: '27' },
  { label: 'Manufacture of other machine tools', value: '28490', parentValue: '27' },
  { label: 'Manufacture of machinery for metallurgy', value: '28910', parentValue: '27' },
  { label: 'Manufacture of machinery for mining', value: '28921', parentValue: '27' },
  { label: 'Manufacture of earthmoving equipment', value: '28922', parentValue: '27' },
  { label: 'Manufac concrete crusher/roadwork screen', value: '28923', parentValue: '27' },
  { label: 'Manufac machine food/dk/tobacco process', value: '28930', parentValue: '27' },
  { label: 'Manufac machinery for textile production', value: '28940', parentValue: '27' },
  { label: 'Manufac machinery for paper production', value: '28950', parentValue: '27' },
  { label: 'Manufac of plastics and rubber machinery', value: '28960', parentValue: '27' },
  { label: 'Manufac other special-purpose machinery', value: '28990', parentValue: '27' },
  { label: 'Manufacture of motor vehicles', value: '29100', parentValue: '28' },
  { label: 'Manufac motor vehicle bodies;not caravan', value: '29201', parentValue: '28' },
  { label: 'Manufac of trailers and semi-trailers', value: '29202', parentValue: '28' },
  { label: 'Manufacture of caravans', value: '29203', parentValue: '28' },
  { label: 'Manufac motor vehicle electric equipment', value: '29310', parentValue: '28' },
  { label: 'Manufac other parts for motor vehicles', value: '29320', parentValue: '28' },
  { label: 'Building of ships or floating structures', value: '30110', parentValue: '29' },
  { label: 'Building of pleasure and sporting boats', value: '30120', parentValue: '29' },
  { label: 'Manufac rail locomotives/rolling stock', value: '30200', parentValue: '29' },
  { label: 'Manufac air/spacecraft/related machinery', value: '30300', parentValue: '29' },
  { label: 'Manufac of military fighting vehicles', value: '30400', parentValue: '29' },
  { label: 'Manufacture of motorcycles', value: '30910', parentValue: '29' },
  { label: 'Manufac of bicycle and invalid carriages', value: '30920', parentValue: '29' },
  { label: 'Manufac other transport equipment n.e.c.', value: '30990', parentValue: '29' },
  { label: 'Manufacture of office and shop furniture', value: '31010', parentValue: '30' },
  { label: 'Manufacture of kitchen furniture', value: '31020', parentValue: '30' },
  { label: 'Manufacture of mattresses', value: '31030', parentValue: '30' },
  { label: 'Manufacture of other furniture', value: '31090', parentValue: '30' },
  { label: 'Striking of coins', value: '32110', parentValue: '31' },
  { label: 'Manufac of jewellery and related article', value: '32120', parentValue: '31' },
  { label: 'Manufac fake jewellery/related articles', value: '32130', parentValue: '31' },
  { label: 'Manufacture of musical instruments', value: '32200', parentValue: '31' },
  { label: 'Manufacture of sports goods', value: '32300', parentValue: '31' },
  { label: 'Manufac prof/arcade games/toys', value: '32401', parentValue: '31' },
  { label: 'Manufac games and toys (not arcade)', value: '32409', parentValue: '31' },
  { label: 'Manufac medical/dental instruments/stock', value: '32500', parentValue: '31' },
  { label: 'Manufacture of brooms and brushes', value: '32910', parentValue: '31' },
  { label: 'Other manufacturing n.e.c.', value: '32990', parentValue: '31' },
  { label: 'Repair of fabricated metal products', value: '33110', parentValue: '32' },
  { label: 'Repair of machinery', value: '33120', parentValue: '32' },
  { label: 'Repair electronic and optical equipment', value: '33130', parentValue: '32' },
  { label: 'Repair of electrical equipment', value: '33140', parentValue: '32' },
  { label: 'Repair and maintenance of ships or boats', value: '33150', parentValue: '32' },
  { label: 'Repair/maintenance of air or space craft', value: '33160', parentValue: '32' },
  { label: 'Repair/maintain other transprt equipment', value: '33170', parentValue: '32' },
  { label: 'Repair of other equipment', value: '33190', parentValue: '32' },
  { label: 'Installing industrial machinery/equipmnt', value: '33200', parentValue: '32' },
  { label: 'Production of electricity', value: '35110', parentValue: '33' },
  { label: 'Transmission of electricity', value: '35120', parentValue: '33' },
  { label: 'Distribution of electricity', value: '35130', parentValue: '33' },
  { label: 'Trade of electricity', value: '35140', parentValue: '33' },
  { label: 'Manufacture of gas', value: '35210', parentValue: '33' },
  { label: 'Distribution of gaseous fuels via mains', value: '35220', parentValue: '33' },
  { label: 'Trade of gas through mains', value: '35230', parentValue: '33' },
  { label: 'Steam and air conditioning supply', value: '35300', parentValue: '33' },
  { label: 'Water collection, treatment and supply', value: '36000', parentValue: '34' },
  { label: 'Sewerage', value: '37000', parentValue: '35' },
  { label: 'Collection of non-hazardous waste', value: '38110', parentValue: '36' },
  { label: 'Collection of hazardous waste', value: '38120', parentValue: '36' },
  { label: 'Treat and dispose of non-hazardous waste', value: '38210', parentValue: '36' },
  { label: 'Treating and disposal of hazardous waste', value: '38220', parentValue: '36' },
  { label: 'Dismantling of wrecks', value: '38310', parentValue: '36' },
  { label: 'Recovery of sorted materials', value: '38320', parentValue: '36' },
  { label: 'Decontamination & other waste removal', value: '39000', parentValue: '37' },
  { label: 'Development of building projects', value: '41100', parentValue: '38' },
  { label: 'Dev and selling - residential', value: '41101', parentValue: '38' },
  { label: 'Dev and selling - office', value: '41102', parentValue: '38' },
  { label: 'Dev and selling - industrial', value: '41103', parentValue: '38' },
  { label: 'Dev and selling - retail', value: '41104', parentValue: '38' },
  { label: 'Dev and selling - other', value: '41105', parentValue: '38' },
  { label: 'Construction of commercial buildings', value: '41201', parentValue: '38' },
  { label: 'Construction of domestic buildings', value: '41202', parentValue: '38' },
  { label: 'Construction of roads and motorways', value: '42110', parentValue: '39' },
  { label: 'Construction of railways and underground', value: '42120', parentValue: '39' },
  { label: 'Construction of bridges and tunnels', value: '42130', parentValue: '39' },
  { label: 'Building of utility projects for fluids', value: '42210', parentValue: '39' },
  { label: 'Electricity/telecom construction project', value: '42220', parentValue: '39' },
  { label: 'Construction of water projects', value: '42910', parentValue: '39' },
  { label: 'Construction/other civil engineer projct', value: '42990', parentValue: '39' },
  { label: 'Demolition', value: '43110', parentValue: '40' },
  { label: 'Site preparation', value: '43120', parentValue: '40' },
  { label: 'Test drilling and boring', value: '43130', parentValue: '40' },
  { label: 'Electrical installation', value: '43210', parentValue: '40' },
  { label: 'Plumbing, heat and air-con installation', value: '43220', parentValue: '40' },
  { label: 'Other construction installation', value: '43290', parentValue: '40' },
  { label: 'Plastering', value: '43310', parentValue: '40' },
  { label: 'Joinery installation', value: '43320', parentValue: '40' },
  { label: 'Floor and wall covering', value: '43330', parentValue: '40' },
  { label: 'Painting', value: '43341', parentValue: '40' },
  { label: 'Glazing', value: '43342', parentValue: '40' },
  { label: 'Other building completion and finishing', value: '43390', parentValue: '40' },
  { label: 'Roofing activities', value: '43910', parentValue: '40' },
  { label: 'Scaffold erection', value: '43991', parentValue: '40' },
  { label: 'Specialist build activity (not scaffold)', value: '43999', parentValue: '40' },
  { label: 'Sale of new cars or light motor vehicles', value: '45111', parentValue: '41' },
  { label: 'Sale of used cars or light motor vehicle', value: '45112', parentValue: '41' },
  { label: 'Sale of other motor vehicles', value: '45190', parentValue: '41' },
  { label: 'Maintenance and repair of motor vehicles', value: '45200', parentValue: '41' },
  { label: "W'sale trade of motor vehicle parts etc", value: '45310', parentValue: '41' },
  { label: 'Retail trade of motor vehicle parts etc', value: '45320', parentValue: '41' },
  { label: 'Sale/repair motorcycles & related parts', value: '45400', parentValue: '41' },
  { label: 'Agents selling live animals/textiles etc', value: '46110', parentValue: '42' },
  { label: 'Agents selling fuel/ore/metal/chemicals', value: '46120', parentValue: '42' },
  { label: 'Agents for sale timber/building material', value: '46130', parentValue: '42' },
  { label: 'Agents selling machinery/ships/aircraft', value: '46140', parentValue: '42' },
  { label: "Agent selling furniture/h'hold goods etc", value: '46150', parentValue: '42' },
  { label: 'Agents sell clothes/footwear/leatherwear', value: '46160', parentValue: '42' },
  { label: 'Agents for sale of food/drink/tobacco', value: '46170', parentValue: '42' },
  { label: 'Agents for sales other specific products', value: '46180', parentValue: '42' },
  { label: 'Agents involved in sale of various goods', value: '46190', parentValue: '42' },
  { label: "W'sale of grain, unmanufacd tobacco/seed", value: '46210', parentValue: '42' },
  { label: 'Wholesale of flowers and plants', value: '46220', parentValue: '42' },
  { label: 'Wholesale of live animals', value: '46230', parentValue: '42' },
  { label: 'Wholesale of hides, skins and leather', value: '46240', parentValue: '42' },
  { label: 'Wholesale of fruit and vegetables', value: '46310', parentValue: '42' },
  { label: 'Wholesale of meat and meat products', value: '46320', parentValue: '42' },
  { label: "W'sale dairy product/eggs/edible oil/fat", value: '46330', parentValue: '42' },
  { label: "W'sale juices/mineral water/soft drink", value: '46341', parentValue: '42' },
  { label: "W'sale of wine, beer, spirits etc", value: '46342', parentValue: '42' },
  { label: 'Wholesale of tobacco products', value: '46350', parentValue: '42' },
  { label: "W'sale sugar & chocolate & sugar sweets", value: '46360', parentValue: '42' },
  { label: 'Wholesale of coffee, tea, cocoa, spices', value: '46370', parentValue: '42' },
  { label: "W'sale other food;fish/crustacean/molusc", value: '46380', parentValue: '42' },
  { label: "Non-specialist w'sale food/drink/tobacco", value: '46390', parentValue: '42' },
  { label: 'Wholesale of textiles', value: '46410', parentValue: '42' },
  { label: 'Wholesale of clothing and footwear', value: '46420', parentValue: '42' },
  { label: "W'sale records/tapes/cds/videos/players", value: '46431', parentValue: '42' },
  { label: "W'sale radio/tv/h'hold electricals n.e.c", value: '46439', parentValue: '42' },
  { label: "W'sale china/glassware/cleaning material", value: '46440', parentValue: '42' },
  { label: 'Wholesale of perfume and cosmetics', value: '46450', parentValue: '42' },
  { label: 'Wholesale of pharmaceutical goods', value: '46460', parentValue: '42' },
  { label: "W'sale of furniture, carpet and lighting", value: '46470', parentValue: '42' },
  { label: 'Wholesale of watches and jewellery', value: '46480', parentValue: '42' },
  { label: 'Wholesale of musical instruments', value: '46491', parentValue: '42' },
  { label: "W'sale h'hold good(not music instrument)", value: '46499', parentValue: '42' },
  { label: "W'sale of computers/peripheral/software", value: '46510', parentValue: '42' },
  { label: "W'sale of electronic/telecom equipment", value: '46520', parentValue: '42' },
  { label: "W'sale agriculture machines/equipmnt etc", value: '46610', parentValue: '42' },
  { label: 'Wholesale of machine tools', value: '46620', parentValue: '42' },
  { label: "W'sale mine/build/civil engineer machine", value: '46630', parentValue: '42' },
  { label: "W'sale machines for textile industry", value: '46640', parentValue: '42' },
  { label: 'Wholesale of office furniture', value: '46650', parentValue: '42' },
  { label: "W'sale of other office machines or gear", value: '46660', parentValue: '42' },
  { label: 'Wholesale of other machines or equipment', value: '46690', parentValue: '42' },
  { label: "W'sale petroleum and petroleum products", value: '46711', parentValue: '42' },
  { label: "W'sale grease/lubricant/oil(not petrol)", value: '46719', parentValue: '42' },
  { label: 'Wholesale of metals and metal ores', value: '46720', parentValue: '42' },
  { label: "W'sale wood/build material/sanitary kit", value: '46730', parentValue: '42' },
  { label: "W'sale hardware/plumb/heat equipment", value: '46740', parentValue: '42' },
  { label: 'Wholesale of chemical products', value: '46750', parentValue: '42' },
  { label: 'Wholesale of other intermediate products', value: '46760', parentValue: '42' },
  { label: 'Wholesale of waste and scrap', value: '46770', parentValue: '42' },
  { label: 'Non-specialised wholesale trade', value: '46900', parentValue: '42' },
  { label: 'Non specialised retail sale mainly food', value: '47110', parentValue: '43' },
  { label: 'Other retail sale in general stores', value: '47190', parentValue: '43' },
  { label: 'Retail sale fruit & veg in special shops', value: '47210', parentValue: '43' },
  { label: 'Specialist retail sale meat/meat product', value: '47220', parentValue: '43' },
  { label: 'Specialist retail sale fish/shellfish', value: '47230', parentValue: '43' },
  { label: 'Specialist retail sale bread/cakes etc', value: '47240', parentValue: '43' },
  { label: 'Retail sale beverages in specialist shop', value: '47250', parentValue: '43' },
  { label: 'Retail sale tobacco product (specialist)', value: '47260', parentValue: '43' },
  { label: 'Other retail food sales in special shops', value: '47290', parentValue: '43' },
  { label: 'Retail sale vehicle fuel in special shop', value: '47300', parentValue: '43' },
  { label: 'Specialist retail sale computer/software', value: '47410', parentValue: '43' },
  { label: 'Retail sale mobile phone in special shop', value: '47421', parentValue: '43' },
  { label: 'Special retail sale comms (not mobiles)', value: '47429', parentValue: '43' },
  { label: 'Specialist retail sale audio/video kit', value: '47430', parentValue: '43' },
  { label: 'Sales of textiles in specialist stores', value: '47510', parentValue: '43' },
  { label: 'Specialist retailer hardware/paint/glass', value: '47520', parentValue: '43' },
  { label: 'Specialist retail:wall & floor coverings', value: '47530', parentValue: '43' },
  { label: 'Specialst retail sale electric appliance', value: '47540', parentValue: '43' },
  { label: 'Specialist retail sale music instrument', value: '47591', parentValue: '43' },
  { label: 'Special retail sale furniture/lights etc', value: '47599', parentValue: '43' },
  { label: 'Retail sale of books in specialist store', value: '47610', parentValue: '43' },
  { label: 'Specialist retail sale papers/stationery', value: '47620', parentValue: '43' },
  { label: 'Specialist retailer music/video recordng', value: '47630', parentValue: '43' },
  { label: 'Retail sale sports gear in special shops', value: '47640', parentValue: '43' },
  { label: 'Retail sale games/toys in special stores', value: '47650', parentValue: '43' },
  { label: 'Sales of clothing in specialised stores', value: '47710', parentValue: '43' },
  { label: 'Sales of footwear in specialised stores', value: '47721', parentValue: '43' },
  { label: 'Retail sale leather gear in special shop', value: '47722', parentValue: '43' },
  { label: 'Dispensing chemist in specialised stores', value: '47730', parentValue: '43' },
  { label: 'Retail sale hearing aids in special shop', value: '47741', parentValue: '43' },
  { label: 'Special retail sale of medic/orthopaedic', value: '47749', parentValue: '43' },
  { label: 'Special retail sale cosmetic/toilet item', value: '47750', parentValue: '43' },
  { label: 'Specialist retail sale floristry or pets', value: '47760', parentValue: '43' },
  { label: 'Retail sale in jewelry/watch stores', value: '47770', parentValue: '43' },
  { label: 'Retail sale in commercial art galleries', value: '47781', parentValue: '43' },
  { label: 'Retail sale by opticians', value: '47782', parentValue: '43' },
  { label: 'Other specialist retail sale new goods', value: '47789', parentValue: '43' },
  { label: 'Retail sale in antique/antique book shop', value: '47791', parentValue: '43' },
  { label: 'Retail sale 2nd-hand goods(not antiques)', value: '47799', parentValue: '43' },
  { label: 'Food/drink/tobacco sale via markets', value: '47810', parentValue: '43' },
  { label: 'Retail sale clothing via markets/stalls', value: '47820', parentValue: '43' },
  { label: 'Retail sale other goods via stall/market', value: '47890', parentValue: '43' },
  { label: 'Retail sale via mail or internet', value: '47910', parentValue: '43' },
  { label: 'Other retail sale not store/stall/market', value: '47990', parentValue: '43' },
  { label: 'Passenger rail transport, interurban', value: '49100', parentValue: '44' },
  { label: 'Freight rail transport', value: '49200', parentValue: '44' },
  { label: 'Passenger rail transport (underground)', value: '49311', parentValue: '44' },
  { label: '(nonunderground rail)passenger transport', value: '49319', parentValue: '44' },
  { label: 'Taxi operation', value: '49320', parentValue: '44' },
  { label: 'Other passenger land transport n.e.c.', value: '49390', parentValue: '44' },
  { label: 'Freight transport by road', value: '49410', parentValue: '44' },
  { label: 'Removal services', value: '49420', parentValue: '44' },
  { label: 'Transport via pipeline', value: '49500', parentValue: '44' },
  { label: 'Sea or coastal passenger water transport', value: '50100', parentValue: '45' },
  { label: 'Sea and coastal freight water transport', value: '50200', parentValue: '45' },
  { label: 'Inland passenger water transport', value: '50300', parentValue: '45' },
  { label: 'Inland freight water transport', value: '50400', parentValue: '45' },
  { label: 'Scheduled passenger air transport', value: '51101', parentValue: '46' },
  { label: 'Non-scheduled passenger air transport', value: '51102', parentValue: '46' },
  { label: 'Freight air transport', value: '51210', parentValue: '46' },
  { label: 'Space transport', value: '51220', parentValue: '46' },
  { label: 'Operation of storage for water transport', value: '52101', parentValue: '47' },
  { label: 'Operation of storage for air transport', value: '52102', parentValue: '47' },
  { label: 'Operation of storage for land transport', value: '52103', parentValue: '47' },
  { label: 'Operation of rail freight terminals', value: '52211', parentValue: '47' },
  { label: 'Operating rail user facilities instation', value: '52212', parentValue: '47' },
  { label: 'Ops bus/coach rider facility at stations', value: '52213', parentValue: '47' },
  { label: 'Other service acts to land transport nec', value: '52219', parentValue: '47' },
  { label: 'Servce activity related to water transpt', value: '52220', parentValue: '47' },
  { label: 'Service activity related to air transprt', value: '52230', parentValue: '47' },
  { label: 'Water transport cargo handling activity', value: '52241', parentValue: '47' },
  { label: 'Cargo handling for air transprt activity', value: '52242', parentValue: '47' },
  { label: 'Cargo handlng of land transport activity', value: '52243', parentValue: '47' },
  { label: 'Other transportation support activities', value: '52290', parentValue: '47' },
  { label: 'Postal work under universal service duty', value: '53100', parentValue: '48' },
  { label: 'Licensed carriers', value: '53201', parentValue: '48' },
  { label: 'Unlicensed carriers', value: '53202', parentValue: '48' },
  { label: 'Hotels and similar accommodation', value: '55100', parentValue: '49' },
  { label: 'Holiday centres and villages', value: '55201', parentValue: '49' },
  { label: 'Youth hostels', value: '55202', parentValue: '49' },
  { label: 'Other holiday/short-stay accomm n.e.c.', value: '55209', parentValue: '49' },
  { label: 'Camping grounds, recreation vehicle park', value: '55300', parentValue: '49' },
  { label: 'Other accommodation', value: '55900', parentValue: '49' },
  { label: 'Licensed restaurants', value: '56101', parentValue: '50' },
  { label: 'Unlicensed restaurants and cafes', value: '56102', parentValue: '50' },
  { label: 'Takeaway food shops or mobile food stand', value: '56103', parentValue: '50' },
  { label: 'Event catering activities', value: '56210', parentValue: '50' },
  { label: 'Other food service activities', value: '56290', parentValue: '50' },
  { label: 'Licensed clubs', value: '56301', parentValue: '50' },
  { label: 'Public houses and bars', value: '56302', parentValue: '50' },
  { label: 'Book publishing', value: '58110', parentValue: '51' },
  { label: 'Publishing directories and mailing lists', value: '58120', parentValue: '51' },
  { label: 'Publishing of newspapers', value: '58130', parentValue: '51' },
  { label: 'Publishing of learned journals', value: '58141', parentValue: '51' },
  { label: 'Publishing consumer/professional journal', value: '58142', parentValue: '51' },
  { label: 'Other publishing activities', value: '58190', parentValue: '51' },
  { label: 'Publishing of computer games', value: '58210', parentValue: '51' },
  { label: 'Other software publishing', value: '58290', parentValue: '51' },
  { label: 'Motion picture production activities', value: '59111', parentValue: '52' },
  { label: 'Video production activities', value: '59112', parentValue: '52' },
  { label: 'Tv programme production activities', value: '59113', parentValue: '52' },
  { label: 'Post-production of film/video/tv program', value: '59120', parentValue: '52' },
  { label: 'Motion picture distribution activities', value: '59131', parentValue: '52' },
  { label: 'Video distribution activities', value: '59132', parentValue: '52' },
  { label: 'Tv programme distribution activities', value: '59133', parentValue: '52' },
  { label: 'Motion picture projection activities', value: '59140', parentValue: '52' },
  { label: 'Sound recording/music publisher activity', value: '59200', parentValue: '52' },
  { label: 'Radio broadcasting', value: '60100', parentValue: '53' },
  { label: 'TV programming and broadcasting activity', value: '60200', parentValue: '53' },
  { label: 'Wired telecommunications activities', value: '61100', parentValue: '54' },
  { label: 'Wireless telecommunications activities', value: '61200', parentValue: '54' },
  { label: 'Satellite telecommunications activities', value: '61300', parentValue: '54' },
  { label: 'Other telecommunications activities', value: '61900', parentValue: '54' },
  { label: 'Interactive leisure software development', value: '62011', parentValue: '55' },
  { label: 'Business or domestic software developing', value: '62012', parentValue: '55' },
  { label: 'Computer consultancy activities', value: '62020', parentValue: '55' },
  { label: 'Computer facilities management activity', value: '62030', parentValue: '55' },
  { label: 'Other info tech/computer service activit', value: '62090', parentValue: '55' },
  { label: 'Data processing and related activity', value: '63110', parentValue: '56' },
  { label: 'Web portals', value: '63120', parentValue: '56' },
  { label: 'News agency activities', value: '63910', parentValue: '56' },
  { label: 'Other info service activity n.e.c.', value: '63990', parentValue: '56' },
  { label: 'Central banking', value: '64110', parentValue: '57' },
  { label: 'Banks', value: '64191', parentValue: '57' },
  { label: 'Building societies', value: '64192', parentValue: '57' },
  { label: 'Activity agricultural holding companies', value: '64201', parentValue: '57' },
  { label: 'Activity of production holding companies', value: '64202', parentValue: '57' },
  { label: 'Activity construction holding companies', value: '64203', parentValue: '57' },
  { label: 'Activity distribution holding companies', value: '64204', parentValue: '57' },
  { label: "Activity financial services holding co's", value: '64205', parentValue: '57' },
  { label: 'Activity other holding companies n.e.c.', value: '64209', parentValue: '57' },
  { label: 'Activities of investment trusts', value: '64301', parentValue: '57' },
  { label: 'Activities of unit trusts', value: '64302', parentValue: '57' },
  { label: "Activity venture/developmnt capital co's", value: '64303', parentValue: '57' },
  { label: 'Activity of open-ended investment co.s', value: '64304', parentValue: '57' },
  { label: 'Activities of property unit trusts', value: '64305', parentValue: '57' },
  { label: 'Activity of real estate investment trust', value: '64306', parentValue: '57' },
  { label: 'Financial leasing', value: '64910', parentValue: '57' },
  { label: 'Credit granting by non-deposit fin house', value: '64921', parentValue: '57' },
  { label: 'Activities of mortgage finance companies', value: '64922', parentValue: '57' },
  { label: 'Other credit granting n.e.c.', value: '64929', parentValue: '57' },
  { label: 'Security dealing on own account', value: '64991', parentValue: '57' },
  { label: 'Factoring', value: '64992', parentValue: '57' },
  { label: 'Other financial service activity n.e.c.', value: '64999', parentValue: '57' },
  { label: 'Life insurance', value: '65110', parentValue: '58' },
  { label: 'Non-life insurance', value: '65120', parentValue: '58' },
  { label: 'Life reinsurance', value: '65201', parentValue: '58' },
  { label: 'Non-life reinsurance', value: '65202', parentValue: '58' },
  { label: 'Pension funding', value: '65300', parentValue: '58' },
  { label: 'Administration of financial markets', value: '66110', parentValue: '59' },
  { label: 'Security and commodity contracts broking', value: '66120', parentValue: '59' },
  { label: 'Financial serv support acts(not funding)', value: '66190', parentValue: '59' },
  { label: 'Risk and damage evaluation', value: '66210', parentValue: '59' },
  { label: 'Activity of insurance agents and brokers', value: '66220', parentValue: '59' },
  { label: 'Other acts auxilary to insurance/pension', value: '66290', parentValue: '59' },
  { label: 'Fund management activities', value: '66300', parentValue: '59' },
  { label: 'Buying and selling of own real estate', value: '68100', parentValue: '60' },
  { label: "Rent/operation housing assoc'n property", value: '68201', parentValue: '60' },
  { label: 'Let/operate conference/exhibition centre', value: '68202', parentValue: '60' },
  { label: 'Letting own property-residential', value: '68203', parentValue: '60' },
  { label: 'Letting own property-office', value: '68204', parentValue: '60' },
  { label: 'Letting own property-industrial', value: '68205', parentValue: '60' },
  { label: 'Letting own property-retail', value: '68206', parentValue: '60' },
  { label: 'Letting/operating own/leased real estate', value: '68209', parentValue: '60' },
  { label: 'Real estate agencies', value: '68310', parentValue: '60' },
  { label: 'Manage real estate on fee/contract basis', value: '68320', parentValue: '60' },
  { label: 'Barristers at law', value: '69101', parentValue: '61' },
  { label: 'Solicitors', value: '69102', parentValue: '61' },
  { label: 'Other legal (executors/patent/copyright)', value: '69109', parentValue: '61' },
  { label: 'Accounting, and auditing activities', value: '69201', parentValue: '61' },
  { label: 'Bookkeeping activities', value: '69202', parentValue: '61' },
  { label: 'Tax consultancy', value: '69203', parentValue: '61' },
  { label: 'Activities of head offices', value: '70100', parentValue: '62' },
  { label: 'Public relation or communicator activity', value: '70210', parentValue: '62' },
  { label: 'Financial management', value: '70221', parentValue: '62' },
  { label: 'Mngmnt consultant activity(not fnancial)', value: '70229', parentValue: '62' },
  { label: 'Architectural activities', value: '71111', parentValue: '63' },
  { label: 'Urban plan/landscape architectur activit', value: '71112', parentValue: '63' },
  { label: 'Engineering design of industrial process', value: '71121', parentValue: '63' },
  { label: 'Engineering related consulting activity', value: '71122', parentValue: '63' },
  { label: 'Other engineering activity n.e.c', value: '71129', parentValue: '63' },
  { label: 'Technical testing and analysis', value: '71200', parentValue: '63' },
  { label: 'Research/experiment development biotech', value: '72110', parentValue: '64' },
  { label: 'Other r & d natural science/engineering', value: '72190', parentValue: '64' },
  { label: 'R & dev on social science and humanities', value: '72200', parentValue: '64' },
  { label: 'Advertising agencies', value: '73110', parentValue: '65' },
  { label: 'Media representation', value: '73120', parentValue: '65' },
  { label: 'Market research or public opinion polls', value: '73200', parentValue: '65' },
  { label: 'Specialised design activities', value: '74100', parentValue: '66' },
  { label: 'Portrait photographic activities', value: '74201', parentValue: '66' },
  { label: 'Other special photography (not portrait)', value: '74202', parentValue: '66' },
  { label: 'Film processing', value: '74203', parentValue: '66' },
  { label: 'Other photographic activity n.e.c.', value: '74209', parentValue: '66' },
  { label: 'Translation and interpretation activity', value: '74300', parentValue: '66' },
  { label: 'Environmental consulting activities', value: '74901', parentValue: '66' },
  { label: 'Quantity surveying activities', value: '74902', parentValue: '66' },
  { label: 'Other professional/sciency/technical act', value: '74909', parentValue: '66' },
  { label: 'Veterinary activities', value: '75000', parentValue: '67' },
  { label: 'Rent/lease of cars/light motor vehicles', value: '77110', parentValue: '68' },
  { label: 'Renting and leasing of trucks', value: '77120', parentValue: '68' },
  { label: 'Rent/lease of recreation and sport goods', value: '77210', parentValue: '68' },
  { label: 'Renting of video tapes and disks', value: '77220', parentValue: '68' },
  { label: 'Rent/lease media entertainment equipment', value: '77291', parentValue: '68' },
  { label: "Rent/lease other personal/h'hold goods", value: '77299', parentValue: '68' },
  { label: 'Rent/lease agriculture machine/equipment', value: '77310', parentValue: '68' },
  { label: 'Rent/lease building/engineering machines', value: '77320', parentValue: '68' },
  { label: 'Rent/lease office machinery & equipment', value: '77330', parentValue: '68' },
  { label: 'Rent/lease passenger water transprt gear', value: '77341', parentValue: '68' },
  { label: 'Rent/lease freight water transport equip', value: '77342', parentValue: '68' },
  { label: 'Rent/lease passenger air transport equip', value: '77351', parentValue: '68' },
  { label: 'Rent/lease freight air transport gear', value: '77352', parentValue: '68' },
  { label: 'Rent/lease other machinery/equipment etc', value: '77390', parentValue: '68' },
  { label: 'Leasing intellectual ppty(not copyright)', value: '77400', parentValue: '68' },
  { label: 'Film, tv and other theatrical casting', value: '78101', parentValue: '69' },
  { label: 'Activity employment placing agencies', value: '78109', parentValue: '69' },
  { label: 'Temporary employment agency activities', value: '78200', parentValue: '69' },
  { label: 'Other human resources provision', value: '78300', parentValue: '69' },
  { label: 'Travel agency activities', value: '79110', parentValue: '70' },
  { label: 'Tour operator activities', value: '79120', parentValue: '70' },
  { label: 'Activities of tourist guides', value: '79901', parentValue: '70' },
  { label: 'Other reservation activity(not guides)', value: '79909', parentValue: '70' },
  { label: 'Private security activities', value: '80100', parentValue: '71' },
  { label: 'Security systems service activities', value: '80200', parentValue: '71' },
  { label: 'Investigation activities', value: '80300', parentValue: '71' },
  { label: 'Combined facilities support activities', value: '81100', parentValue: '72' },
  { label: 'General cleaning of buildings', value: '81210', parentValue: '72' },
  { label: 'Window cleaning services', value: '81221', parentValue: '72' },
  { label: 'Specialised cleaning services', value: '81222', parentValue: '72' },
  { label: 'Furnace and chimney cleaning services', value: '81223', parentValue: '72' },
  { label: 'Building/industrial cleaning n.e.c.', value: '81229', parentValue: '72' },
  { label: 'Disinfecting and extermination services', value: '81291', parentValue: '72' },
  { label: 'Cleaning services (not disinfecting)', value: '81299', parentValue: '72' },
  { label: 'Landscape service activities', value: '81300', parentValue: '72' },
  { label: 'Combined office admin service activity', value: '82110', parentValue: '73' },
  { label: 'Photocopy & other office support activit', value: '82190', parentValue: '73' },
  { label: 'Activities of call centres', value: '82200', parentValue: '73' },
  { label: 'Activity exhibition and fair organizers', value: '82301', parentValue: '73' },
  { label: 'Activities of conference organizers', value: '82302', parentValue: '73' },
  { label: 'Activities of collection agencies', value: '82911', parentValue: '73' },
  { label: 'Activities of credit bureaus', value: '82912', parentValue: '73' },
  { label: 'Packaging activities', value: '82920', parentValue: '73' },
  { label: 'Business support service activity n.e.c.', value: '82990', parentValue: '73' },
  { label: 'General public administration activities', value: '84110', parentValue: '74' },
  { label: 'Regulation health care/education etc', value: '84120', parentValue: '74' },
  { label: "Regul/aiding of efficient ops of co's", value: '84130', parentValue: '74' },
  { label: 'Foreign affairs', value: '84210', parentValue: '74' },
  { label: 'Defence activities', value: '84220', parentValue: '74' },
  { label: 'Justice and judicial activities', value: '84230', parentValue: '74' },
  { label: 'Public order and safety activities', value: '84240', parentValue: '74' },
  { label: 'Fire service activities', value: '84250', parentValue: '74' },
  { label: 'Compulsory social security activities', value: '84300', parentValue: '74' },
  { label: 'Pre-primary education', value: '85100', parentValue: '75' },
  { label: 'Primary education', value: '85200', parentValue: '75' },
  { label: 'General secondary education', value: '85310', parentValue: '75' },
  { label: 'Technical/vocational secondary education', value: '85320', parentValue: '75' },
  { label: 'Post-secondary non-tertiary education', value: '85410', parentValue: '75' },
  { label: 'First-degree level higher education', value: '85421', parentValue: '75' },
  { label: 'Post-graduate level higher education', value: '85422', parentValue: '75' },
  { label: 'Sports and recreation education', value: '85510', parentValue: '75' },
  { label: 'Cultural education', value: '85520', parentValue: '75' },
  { label: 'Driving school activities', value: '85530', parentValue: '75' },
  { label: 'Other education n.e.c.', value: '85590', parentValue: '75' },
  { label: 'Educational support activities', value: '85600', parentValue: '75' },
  { label: 'Hospital activities', value: '86101', parentValue: '76' },
  { label: 'Medical nursing home activities', value: '86102', parentValue: '76' },
  { label: 'General medical practice activities', value: '86210', parentValue: '76' },
  { label: 'Specialist medical practice activities', value: '86220', parentValue: '76' },
  { label: 'Dental practice activities', value: '86230', parentValue: '76' },
  { label: 'Other human health activities', value: '86900', parentValue: '76' },
  { label: 'Residential nursing care activities', value: '87100', parentValue: '77' },
  { label: 'Residential care for mental health etc', value: '87200', parentValue: '77' },
  { label: 'Residential care for elderly/disabled', value: '87300', parentValue: '77' },
  { label: 'Other residential care activities', value: '87900', parentValue: '77' },
  { label: 'Social work(no accommodation for infirm)', value: '88100', parentValue: '78' },
  { label: 'Child day-care activities', value: '88910', parentValue: '78' },
  { label: 'Other social work activity without board', value: '88990', parentValue: '78' },
  { label: 'Performing arts', value: '90010', parentValue: '79' },
  { label: 'Support activities to performing arts', value: '90020', parentValue: '79' },
  { label: 'Artistic creation', value: '90030', parentValue: '79' },
  { label: 'Operation of arts facilities', value: '90040', parentValue: '79' },
  { label: 'Library activities', value: '91011', parentValue: '80' },
  { label: 'Archive activities', value: '91012', parentValue: '80' },
  { label: 'Museum activities', value: '91020', parentValue: '80' },
  { label: 'Operation historical sites/buildings etc', value: '91030', parentValue: '80' },
  { label: 'Botanical/zoological gardens activity', value: '91040', parentValue: '80' },
  { label: 'Gambling and betting activities', value: '92000', parentValue: '81' },
  { label: 'Operation of sports facilities', value: '93110', parentValue: '82' },
  { label: 'Activities of sport clubs', value: '93120', parentValue: '82' },
  { label: 'Fitness facilities', value: '93130', parentValue: '82' },
  { label: 'Activities of racehorse owners', value: '93191', parentValue: '82' },
  { label: 'Other sport activity(no racehorse owner)', value: '93199', parentValue: '82' },
  { label: 'Activity of amusement and theme parks', value: '93210', parentValue: '82' },
  { label: 'Other amusement and recreation activity', value: '93290', parentValue: '82' },
  { label: 'Activity of busines/employer member orgs', value: '94110', parentValue: '83' },
  { label: 'Activity of professional membership orgs', value: '94120', parentValue: '83' },
  { label: 'Activities of trade unions', value: '94200', parentValue: '83' },
  { label: 'Activities of religious organisations', value: '94910', parentValue: '83' },
  { label: 'Activities of political organisations', value: '94920', parentValue: '83' },
  { label: 'Activity of other membership orgs n.e.c.', value: '94990', parentValue: '83' },
  { label: 'Repair of computer or peripheral items', value: '95110', parentValue: '84' },
  { label: 'Repair of communication equipment', value: '95120', parentValue: '84' },
  { label: 'Repair of consumer electronics', value: '95210', parentValue: '84' },
  { label: 'Repairs home appliance/garden equipment', value: '95220', parentValue: '84' },
  { label: 'Repair of footwear and leather goods', value: '95230', parentValue: '84' },
  { label: 'Repair of furniture and home furnishings', value: '95240', parentValue: '84' },
  { label: 'Repair of watches, clocks and jewellery', value: '95250', parentValue: '84' },
  { label: 'Repair of other household goods', value: '95290', parentValue: '84' },
  { label: 'Washing/dry-cleaning of textile and furs', value: '96010', parentValue: '85' },
  { label: 'Hairdressing and other beauty treatment', value: '96020', parentValue: '85' },
  { label: 'Funeral and related activities', value: '96030', parentValue: '85' },
  { label: 'Physical well-being activities', value: '96040', parentValue: '85' },
  { label: 'Other personal service activities n.e.c.', value: '96090', parentValue: '85' },
  { label: 'Households employing domestic helpers', value: '97000', parentValue: '86' },
  { label: "Private h'holds producing goods(own use)", value: '98100', parentValue: '87' },
  { label: "Private h'holds making services(own use)", value: '98200', parentValue: '87' },
  { label: 'Extraterritorial organisations or bodies', value: '99000', parentValue: '88' },
]
