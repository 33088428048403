import React from 'react'
import { useSessionStart } from '@atlas/session'
import { FormLayout, Page } from '@piggybank/core'
import { Wizard, WizardPage } from '@piggybank/form'

import { formPages } from 'client/pages'
import FeatureFlagsButtons from 'client/components/FeatureFlagButtons'
import TimeoutWarning from 'client/components/TimeoutWarning'
import { MastheadWithProductBasket } from 'client/components'

const WizardForm = () => {
  useSessionStart()
  return (
    <Wizard onSubmit={() => {}}>
      {Object.entries(formPages).map((pageEntry, index) => {
        const [pageKey, { Component, hasTimeout, withProductBasket }] = pageEntry
        return (
          <WizardPage data-bdd={'wizard-page'} key={index} name={pageKey}>
            {({ navigateTo, values }) => (
              <Page headerSlot={<MastheadWithProductBasket formInput={values} basketVisible={withProductBasket} />}>
                <FormLayout>
                  <FeatureFlagsButtons onNavigate={navigateTo} />
                  <Component id="component" formInput={values} onNavigate={navigateTo} />
                  {hasTimeout && <TimeoutWarning onNavigate={navigateTo} />}
                </FormLayout>
              </Page>
            )}
          </WizardPage>
        )
      })}
    </Wizard>
  )
}

export default WizardForm
