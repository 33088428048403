import * as yup from 'yup'

import {
  noFutureDateValidation,
  isCurrentOrFutureDateValidation,
  isSameMonthOrAfterDateValidation,
  isAddressMoveInDateInOrderValidation,
} from './date'
import emailValidation from './email'
import phoneNumberValidation from './phoneNumber'
import validateIfChildIsNotTooYoung from './validateIfChildIsNotTooYoung'
import validateIfChildIsNotTooOld from './validateIfChildIsNotTooOld'
import {
  isValidUKPostCodeValidation,
  isValidNonUKPostCodeValidation,
} from 'client/validation/postCode'

// adding custom methods
yup.addMethod(yup.string, 'noFutureDateValidation', noFutureDateValidation)
yup.addMethod(yup.string, 'isCurrentOrFutureDateValidation', isCurrentOrFutureDateValidation)
yup.addMethod(yup.string, 'emailValidation', emailValidation)
yup.addMethod(yup.object, 'phoneNumberValidation', phoneNumberValidation)
yup.addMethod(yup.string, 'validateIfChildIsNotTooYoung', validateIfChildIsNotTooYoung)
yup.addMethod(yup.string, 'validateIfChildIsNotTooOld', validateIfChildIsNotTooOld)
yup.addMethod(yup.string, 'isValidUKPostCodeValidation', isValidUKPostCodeValidation)
yup.addMethod(yup.string, 'isValidNonUKPostCodeValidation', isValidNonUKPostCodeValidation)
yup.addMethod(yup.string, 'isSameMonthOrAfterDateValidation', isSameMonthOrAfterDateValidation)
yup.addMethod(
  yup.string,
  'isAddressMoveInDateInOrderValidation',
  isAddressMoveInDateInOrderValidation
)

export default yup
