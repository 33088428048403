import React from 'react'
import get from 'lodash/get'
import { honorifics } from 'lookups/index'
import { Reveal, Section } from '@piggybank/core'
import {
  Field,
  Fieldset,
  Hint,
  Label,
  Legend,
  Select,
  TextInput,
  YesNoRadio,
  FieldFeedback,
} from '@piggybank/form'

import replaceApostrophes from 'client/utils/replaceApostrophes'
import { FAMILY_NAME_MAX_LENGTH, FIRST_NAMES_MAX_LENGTH, NAME_MAX_LENGTH } from 'constants/customer'
import { PageKey, YesNoBoolean } from 'enums/index'
import dict from './dictionary'

const pageKey = PageKey.ChildPersonalDetailsPage

const ChildNamesSection = ({ formValues }) => (
  <>
    <Field name={`${pageKey}.title`}>
      <Label>{dict.title.label}</Label>
      <Select options={honorifics} />
      <FieldFeedback />
    </Field>

    <Field
      name={`${pageKey}.firstNames`}
      onChange={(next, rest) => {
        rest.value = replaceApostrophes(rest.value)
        next(rest)
      }}
      onBlur={(next, rest) => {
        formValues[pageKey].firstNames = formValues[pageKey].firstNames.trim()
        next(rest)
      }}
    >
      <Label marginBottom={1}>{dict.firstNames.label}</Label>
      <Hint>{dict.firstNames.hint}</Hint>
      <TextInput maxLength={NAME_MAX_LENGTH} />
      <FieldFeedback />
    </Field>

    <Field
      name={`${pageKey}.familyName`}
      onChange={(next, rest) => {
        rest.value = replaceApostrophes(rest.value)
        next(rest)
      }}
      onBlur={(next, rest) => {
        formValues[pageKey].familyName = formValues[pageKey].familyName.trim()
        next(rest)
      }}
    >
      <Label marginBottom={1}>{dict.familyName.label}</Label>
      <Hint>{dict.familyName.hint}</Hint>
      <TextInput maxLength={NAME_MAX_LENGTH} />
      <FieldFeedback />
    </Field>

    <Section marginBottom={4}>
      <Fieldset name={`${pageKey}.hasPreviousNames`} marginBottom={0}>
        <Legend>{dict.hasPreviousNames.label}</Legend>
        <YesNoRadio />
        <FieldFeedback />
      </Fieldset>
      <Reveal accentBar={true}>
        {formValues[pageKey] &&
          get(formValues, `${pageKey}.hasPreviousNames`) === YesNoBoolean.Yes && (
            <>
              <Field
                name={`${pageKey}.previousFirstNames`}
                onChange={(next, rest) => {
                  rest.value = replaceApostrophes(rest.value)
                  next(rest)
                }}
                onBlur={(next, rest) => {
                  formValues[pageKey].previousFirstNames = formValues[pageKey].previousFirstNames.trim()
                  next(rest)
                }}
              >
                <Label marginBottom={1}>{dict.previousFirstNames.label}</Label>
                <Hint>{dict.previousFirstNames.hint}</Hint>
                <TextInput maxLength={FIRST_NAMES_MAX_LENGTH} />
                <FieldFeedback />
              </Field>
              <Field
                name={`${pageKey}.previousFamilyName`}
                onChange={(next, rest) => {
                  rest.value = replaceApostrophes(rest.value)
                  next(rest)
                }}
                onBlur={(next, rest) => {
                  formValues[pageKey].previousFamilyName = formValues[pageKey].previousFamilyName.trim()
                  next(rest)
                }}
                marginBottom={1}
              >
                <Label marginBottom={1}>{dict.previousFamilyName.label}</Label>
                <Hint>{dict.previousFamilyName.hint}</Hint>
                <TextInput maxLength={FAMILY_NAME_MAX_LENGTH} />
                <FieldFeedback />
              </Field>
            </>
          )}
      </Reveal>
    </Section>
  </>
)

export default ChildNamesSection
