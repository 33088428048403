import get from 'lodash/get'

import { getStore } from 'client/store'
import { Product, TealiumCustomerGroup, TealiumProductId } from 'enums/index'
import { PAGE_TAGS } from 'constants/analytics'

export const getProductId = (primaryProduct: Product): string => {
  const productConfigurations = {
    [Product.MY_SAVINGS]: TealiumProductId.MySavings,
    [Product.MY_MONEY]: TealiumProductId.MyMoney,
    [Product.PREMIER_MY_SAVINGS]: TealiumProductId.PremierMySavings,
    [Product.PREMIER_MY_MONEY]: TealiumProductId.PremierMyMoney,
  }
  return primaryProduct ? productConfigurations[primaryProduct] : ''
}

export const getCustomerGroup = (primaryProduct: Product): string => {
  if (!primaryProduct) return ''
  return [Product.PREMIER_MY_SAVINGS, Product.PREMIER_MY_MONEY].includes(primaryProduct)
    ? TealiumCustomerGroup.Premier
    : TealiumCustomerGroup.General
}

export const getCommonTags = commonPageTags => () => {
  const state = getStore().getState()
  const primaryProduct = get(state, 'formInput.product-eligibility-check-page.primaryProduct')

  return {
    page_category: 'my money',
    customer_group: getCustomerGroup(primaryProduct),
    funnel_name: 'mymoney youth bank accounts',
    page_business_line: 'rbwm',
    page_customer_group: 'general',
    page_language: 'en',
    page_security_level: '40',
    page_subcategory: 'youth bank account',
    product_category: 'youth accounts',
    product_id: getProductId(primaryProduct),
    site_section: 'ib',
    internal_reference_id: get(state, 'configuration.nodeRequestID', 'only available if SRR is ON'),
    // Reset values to defaults
    event_category: '',
    ...commonPageTags,
  }
}

export const getPageTags = pageKey => PAGE_TAGS[pageKey]

export const getApplicationId = (): { application_id: string } => {
  const store = getStore().getState()
  return {
    application_id: get(store, 'remoteData.submissionResponse.applicationWipId'),
  }
}
