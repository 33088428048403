import * as yup from 'yup';
import { FeatureFlags } from 'enums/index';

const isGreaterThan65 = (value: string) => parseInt(value) >= 65;

const validationSchema = yup.object().shape({
  [FeatureFlags.CUSTOM_TIMEOUT]: yup.object().shape({
    value: yup.string().when('enabled', {
      is: (enabled: boolean) => enabled,
      then: yup
        .string()
        .test(
          'isGreaterThan65',
          'timeoutWarning will appear to quickly if less than 65 seconds',
          isGreaterThan65
        )
        .required(
          'If CUSTOM_TIMEOUT is enabled this field should be over 65. timeoutWarning appears counting 60 seconds and would appear to quickly'
        ),
    }),
  }),
});

export default validationSchema;
