import {
  BusinessRole,
  EmploymentStatus,
  MissingTaxIdOrReferenceNumberReason,
  YesNoBoolean,
} from 'enums/index'
import { unitedKingdom } from 'src/lookups/countries'
import { BUSINESS_TYPES } from 'constants/businessTypes'
import { countries } from 'lookups/index'

const unitedKingdomValue = unitedKingdom.value

export const sampleChildContactAndFinancialDetails = {
  doesLiveAt: YesNoBoolean.Yes,
  whenDidStartLivingHere: '01-2005',
  newCurrentAddress: {
    building: '158',
    street: 'Lytchett Drive',
    townOrCity: 'Broadstone',
    countryOrRegion: unitedKingdomValue,
    postCode: 'BH189NY',
    moveInDate: '',
  },
  previousAddresses: [],
  phoneNumber: '+447841234453',
  email: 'batman@gotham.com',
  countryOrJurisdictionValue: countries[0].value,
  countryOrJurisdictionAdditionalValues: [
    {
      countryOrJurisdictionValue: unitedKingdomValue,
      reasonForMissingChildTaxIdOrReferenceNumber:
        MissingTaxIdOrReferenceNumberReason.UnableToObtain,
    },
    {
      countryOrJurisdictionValue: countries[1].value,
      childHasTaxIdOrReferenceNumber: YesNoBoolean.No,
      reasonForMissingChildTaxIdOrReferenceNumber:
        MissingTaxIdOrReferenceNumberReason.UnableToObtain,
    },
  ],
  reasonForMissingChildTaxIdOrReferenceNumber: '',
  isStudent: YesNoBoolean.No,
  institutionName: 'MIT',
  employmentStatus: EmploymentStatus.FullTimeEmployed,
  occupation: 'something',
  employerName: 'HSBC',
  employerLocation: unitedKingdomValue,
  businessRole: BusinessRole.BusinessOwner,
  businessName: 'HSBC',
  businessType: BUSINESS_TYPES[0].value,
  businessLocation: unitedKingdomValue,
  traderName: 'HSBC Trader',
  workLocation: unitedKingdomValue,
  controlledBusinessName: 'HSBC',
  controlledBusinessLocation: unitedKingdomValue,
  annualSalary: '£100000',
  annualIncome: '£53000',
  childHasTaxIdOrReferenceNumber: YesNoBoolean.Yes,
  taxIdentificationOrReferenceNumber: 'AB127391723',
}
