import { MoneySource } from 'enums/index'

export const sampleAccountInformation = {
  regularPaymentSource: MoneySource.TransferFromWork,
  firstDepositSource: MoneySource.TransferFromWork,
  initialDeposit: '100',
  countryOfInitialDeposit: 'GB',
  additionalCountriesOfInitialDeposit: [],
  marketingPreferences: [],
  accountOpeningConsent1: false,
  accountOpeningConsent2: false,
  accountOpeningConsent3: false,
}
