export default {
  mainHeading: 'Thanks for your application, ',
  nextStepsSection: {
    heading: 'Next steps',
    paragraphs: [
      "We'll be in touch soon to let you know if your child's application is successful or if we need more information.",
      'This usually takes up to 3-5 working days, but could take up to 10 days during busy periods.',
    ],
  },
  whatCanIDoNowSection: {
    paragraphChunks: [
      { text: 'For more information, ' },
      {
        text: 'contact us',
        link: 'https://www.hsbc.co.uk/contact',
        external: true,
        dataBdd: 'contact-us-link',
      },
      { text: '.' },
    ],
  },
}
