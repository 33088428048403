import {
  Age,
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags, getProductId } from 'client/helpers'
import {
  authorisedAdultLinkText,
  seeOurCurrentAccountsButtonText,
  seeOurSavingsAccountsButtonText,
} from 'client/pages/ProductEligibilityCheckPage'
import { T_TaggingSpecs } from 'types/index'
import TealiumEventAction from 'enums/TealiumEventAction'

const pageKey = PageKey.ProductEligibilityCheckPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const productEligibilityCheckPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.WizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      funnel_step: '1',
      raw_datalayer: '3406v1',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks the "continue" navigation button.`,
    eventIdentifier: {
      component: TealiumComponent.Button,
      text: 'Continue',
      trackingPageName: pageKey,
      type: PiggyEvent.OnClick,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: 'continue',
      application_event: 'started',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v2',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer enters child's date of birth which is below the required age.`,
    eventIdentifier: {
      component: TealiumComponent.ProductSection,
      trackingPageName: pageKey,
      ageStatus: Age.TooYoung,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_category: TealiumEventCategory.Content,
      event_action: TealiumEventAction.Application,
      event_content: 'child not yet seven',
      raw_datalayer: '3406v3',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer enters child's date of birth which is above the required age.`,
    eventIdentifier: {
      component: TealiumComponent.ProductSection,
      trackingPageName: pageKey,
      ageStatus: Age.TooOld,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_category: TealiumEventCategory.Content,
      event_action: TealiumEventAction.Application,
      event_content: 'child no longer eligible',
      raw_datalayer: '3406v4',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on "${seeOurCurrentAccountsButtonText}" button.`,
    eventIdentifier: {
      component: TealiumComponent.Button,
      text: seeOurCurrentAccountsButtonText,
      trackingPageName: pageKey,
      type: PiggyEvent.OnClick,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: 'see current accounts',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v7',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on "${seeOurSavingsAccountsButtonText}" button.`,
    eventIdentifier: {
      component: TealiumComponent.Button,
      text: seeOurSavingsAccountsButtonText,
      trackingPageName: pageKey,
      type: PiggyEvent.OnClick,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: 'see our savings accounts',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v8',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on "${authorisedAdultLinkText}" link.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Link,
      type: PiggyEvent.OnClick,
      text: authorisedAdultLinkText,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.OnSite,
      event_content: 'authorised adult',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v66',
    }),
  },
]

export const customProductEligibilityCheckPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired on ${pageKey} when customer enters child's date which is eligible to apply.`,
    eventIdentifier: {
      component: TealiumComponent.ProductSection,
      trackingPageName: pageKey,
      type: PiggyEvent.ProductUpdate,
    },
    content: event => ({
      ...getAllCommonTags(),
      product_id: getProductId(event.primaryProduct),
      event_action: TealiumEventAction.Textbox,
      event_category: TealiumEventCategory.Content,
      event_content: event.childYearOfBirth,
      raw_datalayer: '3406v5',
    }),
  },
]
