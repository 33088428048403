import { Age, Product } from 'enums/index'
import validationDict from 'client/validation/dictionary'

const otherHSBCProductsLink = 'https://www.hsbc.co.uk/current-accounts/products/'
const getValidationTooYoung = offer => [
  {
    text: `Your child must be at least 7 years old to open a ${offer} account. Until then, you can still put money aside for your child in one our `,
  },
  {
    text: 'savings or current accounts',
    link: otherHSBCProductsLink,
    external: true,
  },
  { text: '.' },
]

const getValidationTooOld = (offer, customText?) => [
  {
    text:
      customText ||
      `Your child can no longer open a ${offer} account. However, they can now access a range of `,
  },
  {
    text: 'HSBC savings and current accounts',
    link: otherHSBCProductsLink,
    external: true,
  },
  { text: '.' },
]

export default {
  dateOfBirth: {
    label: 'Date of birth',
    errMsg: validationDict.wrongDate,
    validation: {
      [Age.TooYoung]: {
        [Product.MY_MONEY]: getValidationTooYoung('MyMoney'),
        [Product.PREMIER_MY_MONEY]: getValidationTooYoung('Premier MyMoney'),
        [Product.MY_SAVINGS]: getValidationTooYoung('MySavings'),
        [Product.PREMIER_MY_SAVINGS]: getValidationTooYoung('Premier MySavings'),
      },
      [Age.TooOld]: {
        [Product.MY_MONEY]: getValidationTooOld('MyMoney'),
        [Product.PREMIER_MY_MONEY]: getValidationTooOld('Premier MyMoney'),
        [Product.MY_SAVINGS]: getValidationTooOld('MySavings'),
        [Product.PREMIER_MY_SAVINGS]: getValidationTooOld(
          'Premier MySavings',
          'Your child can no longer open a Premier MySavings account. Please see range of '
        ),
      },
    },
  },
  birthPlace: {
    label: 'Country or region of birth',
    validation: {
      required: "Please select your child's country or region of birth",
    },
  },
  modalDetails: {
    title: "You've updated your child's date of birth",
    continueButtonLabel: "I'm happy to continue",
    updatingTo: {
      [Product.MY_MONEY]: {
        description:
          "Based on the new date, you're eligible to apply for MyMoney on your child's behalf.",
      },
      [Product.PREMIER_MY_MONEY]: {
        description:
          "Based on the new date, you're eligible to apply for Premier MyMoney on your child's behalf.",
      },
      [Product.MY_SAVINGS]: {
        description:
          "Based on this date, your child isn't eligible for a MyMoney account. However, you can still apply for a MySavings account on behalf of your child.",
      },
      [Product.PREMIER_MY_SAVINGS]: {
        description:
          "Based on this date, your child isn't eligible for a Premier MyMoney account. However, you can still apply for a Premier MySavings account on behalf of your child.",
      },
    },
  },
}
