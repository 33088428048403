// Api
export const CUSTOMER_API_PATH = '/customer'
export const POST_CHECKS_API_PATH = '/checks'
export const POST_DECISION_API_PATH = '/decision'
export const HEALTH_API_PATH = '/health'

// Node
export const GET_CUSTOMER_NODE_PATH = '/api/customer'
export const POST_APPLICATION_NODE_PATH = '/api/application'
export const GET_EXTENDED_AUTH_TOKEN_NODE_PATH = '/api/extend-auth-token'
