import React from 'react'
import { get } from 'lodash'
import { Reveal } from '@piggybank/core'
import { Field, FieldFeedback, Label, Select, TextInput } from '@piggybank/form'

import { PageKey, MoneySource, YesNoBoolean } from 'enums/index'
import { T_FormInput } from 'types/index'
import { moneySources } from 'lookups/index'

import dict from '../../dictionary'

type T_Props = {
  hideCddNewTemplateFields?: YesNoBoolean
  formValues: T_FormInput
  paymentSourceFieldName: string
  cashSourceFieldName: string
}

const pageKey = PageKey.AccountInformationPage

const MoneySourceFields = ({
  hideCddNewTemplateFields,
  formValues,
  paymentSourceFieldName,
  cashSourceFieldName,
}: T_Props) => {
  const cashSourceSectionVisible =
    get(formValues, `${pageKey}.${paymentSourceFieldName}`) === MoneySource.CashFromAnotherSource

  return (
    <>
      <Field name={`${pageKey}.${paymentSourceFieldName}`}>
        <Label>{get(dict, ['accountUsageSection', paymentSourceFieldName, 'label'])}</Label>
        <Select options={moneySources} />
        <FieldFeedback />
      </Field>
      {hideCddNewTemplateFields !== YesNoBoolean.Yes && (
        <Reveal>
          {cashSourceSectionVisible && (
            <Field name={`${pageKey}.${cashSourceFieldName}`}>
              <Label>{get(dict, ['accountUsageSection', cashSourceFieldName, 'label'])}</Label>
              <TextInput />
              <FieldFeedback />
            </Field>
          )}
        </Reveal>
      )}
    </>
  )
}

export default MoneySourceFields
