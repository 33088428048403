import acceptProfile from './data/accept'
import referProfile from './data/refer'
import declineProfile from './data/decline'
import declineElProfile from './data/declineEl'
import declineProfileW2 from './data/declineW2'
import definiteMatch from './data/definiteMatch'
import partialMatch from './data/partialMatch'

export default [
  {
    id: '1',
    name: 'Accept',
    data: acceptProfile,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'NO_MATCH',
        childCin: '1234567890',
        globalChecksStatus: 'COMPLETE',
        applicationWipId: '292976529431323D',
        decision: 'ACCEPT',
        divaCallSuccessful: true,
      },
    },
  },
  {
    id: '2',
    name: 'Refer',
    data: referProfile,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'NO_MATCH',
        childCin: '1234567111',
        globalChecksStatus: 'COMPLETE',
        applicationWipId: '471996626001468D',
        decision: 'REFER',
        divaCallSuccessful: true,
      },
    },
  },
  {
    id: '3',
    name: 'Decline K4',
    data: declineProfile,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'NO_MATCH',
        childCin: '1234567222',
        globalChecksStatus: 'COMPLETE',
        applicationWipId: '471996626001690D',
        decision: 'DECLINE_POLICY',
      },
    },
  },
  {
    id: '4',
    name: 'Decline W2',
    data: declineProfileW2,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'NO_MATCH',
        childCin: '1824124848',
        globalChecksStatus: 'COMPLETE',
        applicationWipId: '007491054001722D',
        decision: 'DECLINE_ELIGIBILITY',
      },
    },
  },
  {
    id: '5',
    name: 'Definite Match',
    data: definiteMatch,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'DEFINITE_MATCH',
        divaCallSuccessful: true,
      },
    },
  },
  {
    id: '6',
    name: 'Partial Match',
    data: partialMatch,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'PARTIAL_MATCH',
      },
    },
  },
  {
    id: '7',
    name: 'Decline EL assessment code',
    data: declineElProfile,
    submitResponse: {
      status: 200,
      body: {
        customerMatchType: 'NO_MATCH',
        childCin: '1133541430',
        globalChecksStatus: 'COMPLETE',
        applicationWipId: '064038411151509D',
        decision: 'DECLINE_POLICY',
      },
    },
  },
]
