import React from 'react'
import { Page, FormLayout } from '@piggybank/core'
import TechnicalErrorPage from 'client/pages/TechnicalErrorPage'

type T_Props = {
  children: any
}

type T_State = {
  hasError: boolean
}

class MainErrorBoundary extends React.Component<T_Props, T_State> {
  constructor(props: T_Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    return this.state.hasError ? (
      <Page>
        <FormLayout>
          <TechnicalErrorPage />
        </FormLayout>
      </Page>
    ) : (
      this.props.children
    )
  }
}

export default MainErrorBoundary
