export const eligibilityRequirementsLinkLabel = 'eligibility requirements'

export default {
  mainHeading: ["We can't offer you a MyMoney account at the moment"],
  whyCantIApplySection: {
    heading: "Why can't we offer you this product?",
    internalPolicyParagraph:
      "We've made this decision based on the information you've given us. Your application has been declined due to one, or more, of the internal policies that help us make decisions.",
    ineligibilityParagraph:
      "Unfortunately, on this occasion your application was declined because you haven't met our eligibility requirements.",
  },
  whatCanIDoNowSection: {
    heading: 'What can you do now?',
    paragraphChunks: [
      { text: 'For more information, ' },
      {
        text: 'contact us',
        link: 'https://www.hsbc.co.uk/contact',
        external: true,
        dataBdd: 'contact-us-link',
      },
      { text: '.' },
    ],
  },
}
