import React, { useEffect, useCallback, useState } from 'react'
import {
  Field,
  FieldFeedback,
  Fieldset,
  Label,
  Legend,
  Radio,
  TextInput,
  YesNoRadio,
} from '@piggybank/form'
import { Reveal, Section } from '@piggybank/core'
import { get } from 'lodash'

import dict from '../dictionary'
import { MissingTaxIdOrReferenceNumberReason, YesNoBoolean } from 'enums/index'
import { unitedKingdom } from 'lookups/countries'
import replaceApostrophes from 'client/utils/replaceApostrophes'
import { staticChildContactAndFinancialDetailsInitialValues } from 'client/pages/ChildContactAndFinancialDetailsPage/getInitialValues'
import { MAX_TAX_ID_OR_REF_NUMBER_LENGTH } from 'constants/customer'

const { form } = dict.taxResidencySection

type T_Props = {
  path: string
  values: {
    countryOrJurisdictionValue: string
    childHasTaxIdOrReferenceNumber: YesNoBoolean
    reasonForMissingChildTaxIdOrReferenceNumber: string
    taxIdentificationOrReferenceNumber: string
  }
  formErrors: any
  submitCount: number
}

export const TaxDetails = ({ path, values, formErrors, submitCount }: T_Props) => {
  const childHasTaxIdOrRefNumberPath = `${path}.childHasTaxIdOrReferenceNumber`
  const reasonForMissingTaxIdOrRefNumberPath = `${path}.reasonForMissingChildTaxIdOrReferenceNumber`
  const taxIdOrRefNumberPath = `${path}.taxIdentificationOrReferenceNumber`
  const currentCountryValue = values.countryOrJurisdictionValue

  const displayHasTaxIdOrRefNumberField =
    currentCountryValue && currentCountryValue !== unitedKingdom.value
  const hasNoTin = values.childHasTaxIdOrReferenceNumber === YesNoBoolean.No
  const hasTin = values.childHasTaxIdOrReferenceNumber === YesNoBoolean.Yes
  const [previousCountryValue, setPreviousCountryValue] = useState(currentCountryValue)

  const resetReasonForMissingTaxIdOrRefNumberField = useCallback(() => {
    let newReasonForMissingTaxIdOrRefNumber = values.reasonForMissingChildTaxIdOrReferenceNumber

    if (
      previousCountryValue === unitedKingdom.value &&
      currentCountryValue !== unitedKingdom.value
    ) {
      newReasonForMissingTaxIdOrRefNumber = ''
    } else if (currentCountryValue === unitedKingdom.value) {
      newReasonForMissingTaxIdOrRefNumber = MissingTaxIdOrReferenceNumberReason.UnableToObtain
    }

    setPreviousCountryValue(currentCountryValue)
    values.reasonForMissingChildTaxIdOrReferenceNumber = newReasonForMissingTaxIdOrRefNumber
  }, [currentCountryValue, values, previousCountryValue])

  const resetTaxIdOrRefNumberPath = () => {
    values.taxIdentificationOrReferenceNumber =
      staticChildContactAndFinancialDetailsInitialValues.taxIdentificationOrReferenceNumber
  }

  // NOTE: For some reason the field feedback doesn't work automatically
  const getFormFeedback = fieldPath => (submitCount > 0 ? get(formErrors, fieldPath) : undefined)

  useEffect(() => {
    if (currentCountryValue && !displayHasTaxIdOrRefNumberField) {
      values.childHasTaxIdOrReferenceNumber = '' as YesNoBoolean
      resetReasonForMissingTaxIdOrRefNumberField()
      resetTaxIdOrRefNumberPath()
    }
  }, [values, displayHasTaxIdOrRefNumberField]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(resetReasonForMissingTaxIdOrRefNumberField, [
    currentCountryValue,
    resetReasonForMissingTaxIdOrRefNumberField,
  ])

  useEffect(() => {
    if (currentCountryValue && !hasTin) resetTaxIdOrRefNumberPath()
  }, [hasTin]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {displayHasTaxIdOrRefNumberField && (
        <Section aria-hidden="true" marginBottom={5}>
          {'' /* Just to ensure the right spacing */}
        </Section>
      )}
      <Reveal>
        {displayHasTaxIdOrRefNumberField && (
          <Fieldset marginBottom={4} name={childHasTaxIdOrRefNumberPath}>
            <Legend marginBottom={1}>{form.childHasTaxIdOrReferenceNumber.label}</Legend>
            <YesNoRadio />
            <FieldFeedback>{getFormFeedback(childHasTaxIdOrRefNumberPath)}</FieldFeedback>
          </Fieldset>
        )}
      </Reveal>
      <Reveal>
        {hasNoTin && (
          <Fieldset marginBottom={0} name={reasonForMissingTaxIdOrRefNumberPath}>
            <Legend marginBottom={1}>
              {form.reasonForMissingChildTaxIdOrReferenceNumber.label}
            </Legend>
            {form.reasonForMissingChildTaxIdOrReferenceNumber.options.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
            <FieldFeedback>{getFormFeedback(reasonForMissingTaxIdOrRefNumberPath)}</FieldFeedback>
          </Fieldset>
        )}
      </Reveal>
      <Reveal>
        {hasTin && (
          <Field
            name={taxIdOrRefNumberPath}
            onChange={(next, rest) => {
              rest.value = replaceApostrophes(rest.value)
              next(rest)
            }}
            onBlur={(next, rest) => {
              values.taxIdentificationOrReferenceNumber = values.taxIdentificationOrReferenceNumber.trim()
              next(rest)
            }}
            marginBottom={0}
          >
            <Label>{form.taxIdentificationOrReferenceNumber.label}</Label>
            <TextInput maxLength={MAX_TAX_ID_OR_REF_NUMBER_LENGTH} />
            <FieldFeedback>{getFormFeedback(taxIdOrRefNumberPath)}</FieldFeedback>
          </Field>
        )}
      </Reveal>
    </>
  )
}
