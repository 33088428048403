import React from 'react'
import { Callout, Heading, Paragraph, Section } from '@piggybank/core'
import { CurrencyInput, Field, Label, FieldFeedback } from '@piggybank/form'
import { get } from 'lodash'

import { CountrySelection } from 'client/components'
import { YesNoBoolean, PageKey, Product } from 'enums/index'
import { T_FormInput } from 'types/index'

import {
  MAX_INITIAL_DEPOSIT_LENGTH,
  MAX_NUMBER_OF_ADDITIONAL_INITIAL_DEPOSIT_COUNTRIES,
} from 'constants/index'

import MoneySourceFields from './MoneySourceFields'
import dict from '../dictionary'

type T_Props = {
  hideCddNewTemplateFields: YesNoBoolean
  formValues: T_FormInput
  primaryProduct: Product
}

const AccountUsageSection = ({ hideCddNewTemplateFields, formValues, primaryProduct }: T_Props) => {
  const initialDepositKeyInformation = get(
    dict,
    ['accountUsageSection', 'initialDepositImportantInformation', primaryProduct],
    '...'
  )

  return (
    <Section marginBottom={5}>
      <Heading accentBar={true} level={2}>
        {dict.accountUsageSection.mainHeading}
      </Heading>
      {hideCddNewTemplateFields !== YesNoBoolean.Yes && (
        <MoneySourceFields
          formValues={formValues}
          paymentSourceFieldName="regularPaymentSource"
          cashSourceFieldName="regularPaymentCashSource"
        />
      )}
      <Callout type="key-information">
        <Paragraph>{initialDepositKeyInformation}</Paragraph>
      </Callout>
      <MoneySourceFields
        formValues={formValues}
        paymentSourceFieldName="firstDepositSource"
        cashSourceFieldName="firstDepositCashSource"
        hideCddNewTemplateFields={hideCddNewTemplateFields}
      />
      <Field name={`${PageKey.AccountInformationPage}.initialDeposit`}>
        <Label>{dict.accountUsageSection.initialDeposit.label}</Label>
        <CurrencyInput symbol="£" maxLength={MAX_INITIAL_DEPOSIT_LENGTH} />
        <FieldFeedback />
      </Field>

      <CountrySelection
        dict={dict.accountUsageSection.countriesOfInitialDeposit}
        maxNumberOfAdditionalCountries={MAX_NUMBER_OF_ADDITIONAL_INITIAL_DEPOSIT_COUNTRIES}
        countryFieldName={`${PageKey.AccountInformationPage}.countryOfInitialDeposit`}
        additionalCountriesFieldName={`${PageKey.AccountInformationPage}.additionalCountriesOfInitialDeposit`}
        country={get(formValues, `${PageKey.AccountInformationPage}.countryOfInitialDeposit`)}
        additionalCountries={get(
          formValues,
          `${PageKey.AccountInformationPage}.additionalCountriesOfInitialDeposit`
        )}
      />
    </Section>
  )
}

export default AccountUsageSection
