import { Env } from 'enums/index'

export const getRandomLetters = (length: number = 7) => {
  const dict = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  if (process.env.NODE_ENV === Env.Test) return 'test'
  return Array.from({ length })
    .map(() => dict.charAt(Math.floor(Math.random() * dict.length)))
    .join('')
}

export const getRandomEmail = () => {
  const username =
    process.env.NODE_ENV !== Env.Test ? 'User' + Math.floor(Math.random() * 10000000) : 'test'
  return username + '@example.com'
}
