import React, { useState } from 'react'
import get from 'lodash/get'

import {
  Button,
  ButtonRow,
  ListItem,
  ModalDialog,
  Paragraph,
  UnorderedList,
  useTrackingContext,
} from '@piggybank/core'

import { Field, FieldFeedback, Label, Legend, Select } from '@piggybank/form'

import {
  getAgeIneligibilityEvent,
  getIneligibilityModalConfirmationEvent,
  getIneligibilityModalEvent,
} from 'client/components/singletons/Analytics'

import validationDict from 'client/validation/dictionary'
import { getChildEligibilityInfo, isFormFieldValueValid } from 'client/helpers'
import { AccessibleDateInput, InteractiveContent, ReturnToOnlineBanking } from 'client/components'
import { ActionName, Age, PageKey, Product } from 'enums/index'
import { countries } from 'lookups/index'
import { T_FormInput } from 'types/index'

import dict from './dictionary'
import productEligibilityCheckPageDict from 'client/pages/ProductEligibilityCheckPage/dictionary'
import { getStore } from 'client/store'

type T_Props = {
  availableProducts: Product[]
  formInput: T_FormInput
  childDateOfBirthError: string
}

const pageKey = PageKey.ChildPersonalDetailsPage

const ChildBirthDetailsSection = ({
  availableProducts,
  formInput,
  childDateOfBirthError,
}: T_Props) => {
  const { dispatch } = getStore()
  const [modalVisible, updateModalVisibility] = useState<boolean>(false)
  const [newPrimaryProduct, updatePrimaryProduct] = useState<Product | null>(null)
  const [childAgeStatus, updateChildAgeStatus] = useState<Age | null>(null)
  const { track } = useTrackingContext()
  let listItems

  const handleDateOfBirthChange = (continueFieldValueUpdate, field) => {
    if (isFormFieldValueValid(field)) {
      const { ageStatus, primaryProduct, additionalProduct } = getChildEligibilityInfo(
        availableProducts,
        field.value
      )
      updateChildAgeStatus(ageStatus)

      const customerProductEligibilityChanged =
        primaryProduct &&
        primaryProduct !== formInput[PageKey.ProductEligibilityCheckPage].primaryProduct

      formInput[PageKey.ProductEligibilityCheckPage].dateOfBirth = field.value
      if (customerProductEligibilityChanged) {
        updateModalVisibility(true)
        updatePrimaryProduct(primaryProduct)
        track(
          getIneligibilityModalEvent(formInput[PageKey.ProductEligibilityCheckPage].primaryProduct)
        )
        formInput[PageKey.ProductEligibilityCheckPage].primaryProduct = primaryProduct
        formInput[PageKey.ProductEligibilityCheckPage].additionalProduct = additionalProduct
      }
    }

    continueFieldValueUpdate(field)
  }

  const handleBankAccountChangeConfirmation = () => {
    updateModalVisibility(false)
    track(getIneligibilityModalConfirmationEvent({ primaryProduct: newPrimaryProduct }))
    dispatch({
      type: ActionName.UpdatePrimaryProduct,
      payload: newPrimaryProduct,
    })
  }

  const getDateOfBirthFieldFeedback = () => {
    if (childDateOfBirthError === validationDict.wrongDate) return validationDict.wrongDate
    const ineligibleAge = [Age.TooYoung, Age.TooOld].includes(childAgeStatus)

    if (ineligibleAge) {
      track(getAgeIneligibilityEvent(childAgeStatus))
      const primaryProduct = formInput[PageKey.ProductEligibilityCheckPage].primaryProduct
      return (
        <InteractiveContent
          chunks={get(dict, ['dateOfBirth', 'validation', childAgeStatus, primaryProduct], [])}
        />
      )
    } else {
      return null
    }
  }

  if (newPrimaryProduct) {
    listItems = productEligibilityCheckPageDict.productSection.offers[newPrimaryProduct].list
  }

  return (
    <>
      <Field name={`${pageKey}.dateOfBirth`} onChange={handleDateOfBirthChange}>
        <Legend>{dict.dateOfBirth.label}</Legend>
        <AccessibleDateInput />
        {/* NOTE: there is a custom field feedback here to enable displaying links and store access */}
        <FieldFeedback>{getDateOfBirthFieldFeedback()}</FieldFeedback>
      </Field>

      <Field name={`${PageKey.ChildPersonalDetailsPage}.birthPlace`}>
        <Label>{dict.birthPlace.label}</Label>
        <Select options={countries} />
        <FieldFeedback />
      </Field>

      <ModalDialog
        show={modalVisible}
        title={dict.modalDetails.title}
        dismissible={false}
        getApplicationNode={() => document.getElementById('root')}
      >
        {newPrimaryProduct && (
          <>
            <Paragraph marginBottom={6}>
              {dict.modalDetails.updatingTo[newPrimaryProduct].description}
            </Paragraph>
            <UnorderedList marginBottom={6} type="tick">
              {listItems.map((item, i) => (
                <ListItem key={i} marginBottom={i === listItems.length - 1 ? 0 : 4}>
                  {item}
                </ListItem>
              ))}
            </UnorderedList>
            <ButtonRow>
              <Button type="button" onClick={handleBankAccountChangeConfirmation}>
                {dict.modalDetails.continueButtonLabel}
              </Button>
              <ReturnToOnlineBanking isSecondary={true} />
            </ButtonRow>
          </>
        )}
      </ModalDialog>
    </>
  )
}

export default ChildBirthDetailsSection
