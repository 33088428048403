import {
  IneligibilityReason,
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { hsbcAccountsLinkLabel } from 'client/pages/CustomerIneligibilityPage'
import { bookAppointmentLinkLabel } from 'client/components/common/ContactMethods'
import { getStore } from 'client/store'
import { get } from 'lodash'
import { T_TaggingSpecs } from 'types/index'

const pageKey = PageKey.CustomerIneligibilityPage

const getFormField1 = () => {
  const formField1TextMap = {
    [IneligibilityReason.Underaged]: 'under 18',
    [IneligibilityReason.NonUKAddress]: 'non uk address',
    [IneligibilityReason.NoRequiredAccount]: 'no required account',
  }
  const store = getStore().getState()
  const ineligibilityReason = get(store, 'remoteData.initialResponse.customerEligibility.reason')

  return { form_field_1: `ineligible reason: ${formField1TextMap[ineligibilityReason]}` }
}

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const customCustomerIneligibilityPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      ...getFormField1(),
      raw_datalayer: '3406v9',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on HSBC bank account link.`,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Link,
      text: hsbcAccountsLinkLabel.trim(),
    },
    content: () => ({
      ...getAllCommonTags(),
      ...getFormField1(),
      event_action: TealiumEventAction.OnSite,
      event_category: TealiumEventCategory.Content,
      event_content: 'hsbc bank account',
      raw_datalayer: '3406v10',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on book an appointment link.`,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Link,
      text: bookAppointmentLinkLabel,
    },
    content: () => ({
      ...getAllCommonTags(),
      ...getFormField1(),
      event_action: TealiumEventAction.OnSite,
      event_category: TealiumEventCategory.Content,
      event_content: 'book an appointment',
      raw_datalayer: '3406v11',
    }),
  },
]
