import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { FormikErrors } from 'formik'

import { Section, Heading, Paragraph, Callout, VisuallyHidden } from '@piggybank/core'
import { Field, YesNoRadio, Label, Legend, FieldFeedback, AddAnother } from '@piggybank/form'

import { addressInitialValues } from 'client/pages/ChildContactAndFinancialDetailsPage/getInitialValues'
import { getDisplayedCustomerAddress } from 'client/helpers'
import { MOVE_IN_DATE_FORMAT, PREVIOUS_ADDRESS_FORMAT } from 'constants/customer'
import { PageKey, YesNoBoolean } from 'enums/index'
import { T_InitialResponse, T_FormInput } from 'types/index'
import { AccessibleDateInput } from 'client/components'

import dict from '../dictionary'
import AddressForm from './AddressForm'

type T_Props = {
  pageKey: PageKey
  customer: T_InitialResponse
  doesLiveAt: YesNoBoolean
  hideDoesLiveAtQuestion: boolean
  setFormFieldValue: any
  values: any
  errors: FormikErrors<T_FormInput>
  submitCount: number
}

const { childAddressSection } = dict
const { form } = childAddressSection
const { fields } = form

export const doesYourChildLiveAtFieldName = `${PageKey.ChildContactAndFinancialDetailsPage}.doesLiveAt`

const ChildAddressSection = ({
  pageKey,
  customer,
  doesLiveAt,
  hideDoesLiveAtQuestion,
  setFormFieldValue,
  values,
  errors,
  submitCount,
}: T_Props) => {
  const addressHistoryIncomplete = get(errors, [
    PageKey.ChildContactAndFinancialDetailsPage,
    'isAddressHistoryComplete',
  ])

  const currentMoveInDate =
    doesLiveAt === YesNoBoolean.Yes
      ? ['whenDidStartLivingHere']
      : ['newCurrentAddress', 'moveInDate']
  const currentMoveInDatePath = [PageKey.ChildContactAndFinancialDetailsPage, ...currentMoveInDate]
  const moveInDate = get(values, [
    PageKey.ChildContactAndFinancialDetailsPage,
    ...currentMoveInDate,
  ])

  const dateMovedIn = moment(moveInDate, MOVE_IN_DATE_FORMAT)
  const currentDate = moment()
  const showAddAnother = currentDate.diff(dateMovedIn, 'days') <= 1095

  if (!showAddAnother) {
    values[PageKey.ChildContactAndFinancialDetailsPage].previousAddresses = []
  }

  const handleDoesLiveAtChange = (setFieldValue, value) => {
    // Reset list of previous addresses
    setFormFieldValue(`${PageKey.ChildContactAndFinancialDetailsPage}.previousAddresses`, [])
    setFieldValue(value)
  }

  const renderAddInfo = isError => (
    // This is a phantom field, only here to prevent customer from submitting form,
    // when the address history is incomplete.
    <Field name={`${PageKey.ChildContactAndFinancialDetailsPage}.isAddressHistoryComplete`}>
      <Callout type={isError ? 'error' : 'warning'} marginBottom={0}>
        <Paragraph marginBottom={0}>{fields.isAddressHistoryComplete.errMsg}</Paragraph>
      </Callout>
    </Field>
  )

  const getPreviousAddressDateFormatted = index => {
    const lastPreviousMoveInDatePath = [
      PageKey.ChildContactAndFinancialDetailsPage,
      'previousAddresses',
      index - 1,
      'moveInDate',
    ]

    const date =
      index > 0
        ? !get(errors, lastPreviousMoveInDatePath) && get(values, lastPreviousMoveInDatePath)
        : !get(errors, currentMoveInDatePath) && get(values, currentMoveInDatePath)

    return date ? moment(date, MOVE_IN_DATE_FORMAT).format(PREVIOUS_ADDRESS_FORMAT) : ''
  }

  return (
    <>
      {!hideDoesLiveAtQuestion && (
        <Section marginBottom={4}>
          <Heading level={2} marginBottom={4} accentBar={true}>
            {childAddressSection.heading}
          </Heading>
          <Field
            name={doesYourChildLiveAtFieldName}
            marginBottom={0}
            onChange={handleDoesLiveAtChange}
          >
            <Legend>{fields.doesLiveAt.label}</Legend>
            <Paragraph marginBottom={1}>
              <b>{getDisplayedCustomerAddress(customer)}</b>
            </Paragraph>
            <YesNoRadio />
            <FieldFeedback />
          </Field>
        </Section>
      )}
      {doesLiveAt === YesNoBoolean.Yes && (
        <Field name={`${pageKey}.whenDidStartLivingHere`}>
          <Label>{fields.whenDidStartLivingHere.label}</Label>
          <AccessibleDateInput format={MOVE_IN_DATE_FORMAT} />
          <FieldFeedback />
        </Field>
      )}
      {doesLiveAt === YesNoBoolean.No && (
        <>
          <Heading level={3}>{form.heading.currentAddress}</Heading>
          <AddressForm
            path={`${pageKey}.newCurrentAddress`}
            values={values[PageKey.ChildContactAndFinancialDetailsPage].newCurrentAddress}
          />
        </>
      )}
      {showAddAnother && (
        <AddAnother
          name={`${PageKey.ChildContactAndFinancialDetailsPage}.previousAddresses`}
          fullWidth={true}
          itemInitialValue={addressInitialValues}
          showAdd={!!addressHistoryIncomplete}
          addInfoSlot={renderAddInfo(addressHistoryIncomplete && submitCount > 0)}
          textMap={{
            addButton:
              childAddressSection.previousAddresses.addAnotherInfo +
              getPreviousAddressDateFormatted(
                values[PageKey.ChildContactAndFinancialDetailsPage].previousAddresses.length
              ),
          }}
          renderRemoveButtonText={({ index }) => (
            <>
              {childAddressSection.previousAddresses.removeAddressLabel.visible}
              <VisuallyHidden>
                {childAddressSection.previousAddresses.removeAddressLabel.hidden} {index + 1}
              </VisuallyHidden>
            </>
          )}
          duration={300}
        >
          {({ path, index }) => (
            <>
              <Heading level={3}>
                {childAddressSection.previousAddresses.addAnotherLabel}
                {getPreviousAddressDateFormatted(index)}?
              </Heading>
              <AddressForm
                path={path}
                values={
                  values[PageKey.ChildContactAndFinancialDetailsPage].previousAddresses[index]
                }
              />
            </>
          )}
        </AddAnother>
      )}
    </>
  )
}

export default ChildAddressSection
