import moment, { Moment } from 'moment'

import { LEGAL_ADULTHOOD_AGE } from 'constants/index'

export const getYearsSince = (date: string): number => moment().diff(date, 'years')

export const isAnAdult = (dateOfBirth: string) => getYearsSince(dateOfBirth) >= LEGAL_ADULTHOOD_AGE

export const waitAtLeast = (
  minTimeInMs: number,
  requestBeginTime: Moment = moment()
): (() => Promise<void>) => () =>
  new Promise(resolve => {
    const requestDurationInMs = moment().diff(requestBeginTime, 'milliseconds')

    if (requestDurationInMs >= minTimeInMs) {
      resolve()
    } else {
      const interval = setInterval(() => {
        if (moment().diff(requestBeginTime, 'milliseconds') >= minTimeInMs) {
          resolve()
          clearInterval(interval)
        }
      }, 100)
    }
  })
