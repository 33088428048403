import React, { FC } from 'react'
import { get } from 'lodash'
import { Expander, Heading, Paragraph, Section } from '@piggybank/core'
import {
  Fieldset,
  Form,
  FormFeedback,
  Legend,
  Navigation,
  ProgressIndicator,
  YesNoRadio,
  FieldFeedback,
} from '@piggybank/form'

import { InteractiveContent } from 'client/components'
import { getStore } from 'client/store'
import { PageKey } from 'enums/index'
import { T_PageProps } from 'types/props'

import ChildNamesSection from './ChildNamesSection'
import ChildPermanentResidenceSection from './ChildPermanentResidenceSection'
import ChildNationalitySection from './ChildNationalitySection'
import { ChildBirthDetailsSection } from './ChildBirthDetailsSection'
import getValidationSchema from './getValidationSchema'
import getInitialValues from './getInitialValues'
import dict, { detailsDisclaimerLabel } from './dictionary'

const { introductionSection, formSection } = dict
const pageKey = PageKey.ChildPersonalDetailsPage

const ChildPersonalDetailsPage: FC<T_PageProps> = ({ formInput }) => {
  const {
    environment: { HIDE_CDD_NEW_TEMPLATE_FIELDS },
    configuration: { ntbProfileId },
    remoteData: {
      initialResponse: { availableProducts },
    },
  } = getStore().getState()
  const dateOfBirth = !!formInput[PageKey.ProductEligibilityCheckPage]
    ? formInput[PageKey.ProductEligibilityCheckPage].dateOfBirth
    : ''
  return (
    <Form
      initialValues={getInitialValues(ntbProfileId, dateOfBirth)}
      validationSchema={getValidationSchema(HIDE_CDD_NEW_TEMPLATE_FIELDS)}
      autoComplete="off"
    >
      {({ values, errors }) => (
        <>
          <ProgressIndicator current={2} total={4} />
          <Section>
            <Heading level={1}>{introductionSection.heading}</Heading>
            <Paragraph>{introductionSection.paragraph}</Paragraph>
            <Expander title={detailsDisclaimerLabel} name={detailsDisclaimerLabel}>
              {introductionSection.detailsDisclaimer.content.map((p, i) => (
                <Paragraph key={i}>
                  <InteractiveContent chunks={p} />
                </Paragraph>
              ))}
            </Expander>
          </Section>
          <FormFeedback />
          <ChildNamesSection formValues={values} />
          <Fieldset name={`${pageKey}.gender`} marginBottom={4}>
            <Legend>{formSection.gender.label}</Legend>
            <YesNoRadio
              textMap={formSection.gender.options.textMap}
              values={formSection.gender.options.values}
            />
            <FieldFeedback />
          </Fieldset>
          <ChildBirthDetailsSection
            availableProducts={availableProducts}
            formInput={formInput}
            childDateOfBirthError={get(errors, [pageKey, 'dateOfBirth'], '')}
          />
          <ChildPermanentResidenceSection hideCddNewTemplateFields={HIDE_CDD_NEW_TEMPLATE_FIELDS} />
          <ChildNationalitySection
            nationality={values[pageKey].nationality}
            additionalNationalities={values[pageKey].additionalNationalities}
          />
          <Navigation marginBottom={7} />
        </>
      )}
    </Form>
  )
}

export default ChildPersonalDetailsPage
