import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { capitalize, map } from 'lodash'

import { unitedKingdom } from 'lookups/countries'
import { T_InitialResponse } from 'types/index'
import dict from './dictionary'

export const getLabelValueCollectionFromEnum = (en: any): { label: string; value: any }[] =>
  map(en, (value, key) => ({ label: key, value }))

export const getChildFirstName = (childFirstNames: string): string => {
  if (childFirstNames) {
    const childFirstName = childFirstNames.split(' ')[0]

    if (childFirstName.includes('-')) {
      return childFirstName
        .split('-')
        .map(name => capitalize(name))
        .join('-')
    }
    return capitalize(childFirstName)
  }
  return dict.defaultChildFirstName
}

export const getDisplayedCustomerName = (firstNames: string): string => {
  if (firstNames.includes('-')) {
    return firstNames
      .split('-')
      .map(s => s.substring(0, 1).toUpperCase() + s.substring(1, s.length))
      .join('-')
  } else {
    const firstName = firstNames.split(' ')[0]
    return firstName.substring(0, 1).toUpperCase() + firstName.substring(1, firstName.length)
  }
}

export const getDisplayedCustomerAddress = (customer: T_InitialResponse): string => {
  const { line1, line2, line3, line4, line5, line6, postCode } = customer.currentAddress
  const fields = [line1, line2, line3, line4, line5, line6, postCode]
  return fields.filter(f => f).join(', ')
}

export const getPhoneNumberObject = (
  // TODO: Not sure if we need to set this default value here, as it was filled in stubs already.
  countryCode: string = unitedKingdom.value,
  phoneCode: string = '',
  phoneNumber: string = ''
): {
  countryCode: string
  raw: string
  value: string
} => {
  if (phoneCode && phoneCode.startsWith('00')) phoneCode = '+' + phoneCode.slice(2)

  try {
    const { country } = parsePhoneNumberFromString(phoneCode + phoneNumber)
    if (country) countryCode = country
  } catch (ex) {
    return {
      countryCode: unitedKingdom.value,
      raw: '',
      value: '',
    }
  }

  return {
    countryCode,
    raw: phoneNumber,
    value: `${phoneCode}${phoneNumber}`,
  }
}

export const ariaTextBloat = value => {
  value = value !== undefined ? String(value) : ''
  return value.split('').join(' ') + ' '
}
