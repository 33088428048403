import React from 'react'
import {
  Section,
  IconStatusInformationOnlight,
  Heading,
  Paragraph,
  HorizontalRule,
} from '@piggybank/core'

import { ReturnToOnlineBanking } from 'client/components'
import dict from './dictionary'
import { InteractiveContent } from 'client/components'

const { mainHeading, whyCantIApplySection, whatCanIDoNowSection } = dict

type T_Props = {
  children: React.ReactNode
}

export const ApplicationDeclinedPage = ({ children }: T_Props) => (
  <Section marginBottom={7}>
    <Section>
      <div style={{ color: 'var(--sapphire)' }}>
        <IconStatusInformationOnlight width="2.5em" />
      </div>
      <Heading level={1} data-bdd={'main-heading'}>
        {mainHeading[0]}
      </Heading>
      <HorizontalRule />
    </Section>
    <Section>
      <Heading level={2} accentBar={true} data-bdd={'why-cant-i-apply-heading'}>
        {whyCantIApplySection.heading}
      </Heading>
      <Paragraph marginBottom={4} data-bdd={'why-cant-i-apply-paragraph'}>
        {children}
      </Paragraph>
    </Section>
    <Heading level={2} accentBar={true} data-bdd={'what-can-i-do-now-heading'}>
      {whatCanIDoNowSection.heading}
    </Heading>
    <Paragraph data-bdd={'what-can-i-do-now-paragraph'}>
      <InteractiveContent chunks={whatCanIDoNowSection.paragraphChunks} />
    </Paragraph>
    <ReturnToOnlineBanking />
  </Section>
)
