import { T_FormInput } from 'types/form'
import { PageKey } from 'enums/index'
import { T_AddressDetails } from 'types/data/local/submissionPayload'

export default (formInput: T_FormInput): T_AddressDetails[] => {
  const { previousAddresses } = formInput[PageKey.ChildContactAndFinancialDetailsPage]

  return previousAddresses.map(item => ({
    dateMovedIn: item.moveInDate + '-01',
    country: item.countryOrRegion,
    line1: item.building.trim(),
    line3: item.street.trim(),
    line5: item.townOrCity.trim(),
    postCode: item.postCode.trim(),
  }))
}
