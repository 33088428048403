import React, { FC } from 'react'
import { Heading, HorizontalRule, Paragraph, Section } from '@piggybank/core'
import {
  Checkbox,
  Field,
  FieldFeedback,
  Form,
  FormFeedback,
  Navigation,
  ProgressIndicator,
} from '@piggybank/form'

import { getStore } from 'client/store'
import { isFeatureEnabled } from 'client/utils/featureEnabled'
import { FeatureFlags, PageKey, Product } from 'enums/index'
import { T_FormInput } from 'types/form'
import { T_PageProps } from 'types/props'

import ImportantInformationSection from './ImportantInformationSection'
import MarketingPreferencesSection from './MarketingPreferencesSection'
import AccountUsageSection from './AccountUsageSection'
import getInitialValues from './getInitialValues'
import getValidationSchema from './getValidationSchema'
import dict from './dictionary'
import get from "lodash/get";

const { pageIntroductionSection, debitCardSection, accountOpeningConsent, navigation } = dict

const AccountInformationPage: FC<T_PageProps> = ({ formInput }: T_PageProps) => {
  const {
    configuration: { ntbProfileId },
    environment: { HIDE_CDD_NEW_TEMPLATE_FIELDS },
    features,
  } = getStore().getState()
  const primaryProduct = get(formInput, 'product-eligibility-check-page.primaryProduct')
  return (
    <Form
      initialValues={getInitialValues(ntbProfileId, features)}
      validationSchema={getValidationSchema(HIDE_CDD_NEW_TEMPLATE_FIELDS)}
    >
      {({ values }) => (
        <>
          <ProgressIndicator current={4} total={4} />
          <FormFeedback />
          <Section>
            <Heading level={1}>{pageIntroductionSection.mainHeading}</Heading>
            <Paragraph lead={true}>{pageIntroductionSection.mainParagraph}</Paragraph>
            <HorizontalRule />
          </Section>
          <AccountUsageSection
            primaryProduct={primaryProduct}
            formValues={values as T_FormInput}
            hideCddNewTemplateFields={HIDE_CDD_NEW_TEMPLATE_FIELDS}
          />
          <MarketingPreferencesSection />
          {primaryProduct &&
            (primaryProduct === Product.MY_MONEY ||
              primaryProduct === Product.PREMIER_MY_MONEY) && (
              <Section>
                <Heading level={2} accentBar={true}>
                  {debitCardSection.heading}
                </Heading>
                <Paragraph>{debitCardSection.paragraph1}</Paragraph>
                <Paragraph>{debitCardSection.paragraph2}</Paragraph>
              </Section>
            )}
          <ImportantInformationSection primaryProduct={primaryProduct} features={features} />
          <Field name={`${PageKey.AccountInformationPage}.accountOpeningConsent1`} marginBottom={5}>
            <Checkbox>{accountOpeningConsent.checkbox1}</Checkbox>
            <FieldFeedback />
          </Field>
          <Field name={`${PageKey.AccountInformationPage}.accountOpeningConsent2`} marginBottom={5}>
            <Checkbox>
              {isFeatureEnabled(features, FeatureFlags.NOV_ENABLED)
                ? accountOpeningConsent.checkbox2
                : accountOpeningConsent.checkbox3}
            </Checkbox>
            <FieldFeedback />
          </Field>
          <Field name={`${PageKey.AccountInformationPage}.accountOpeningConsent3`} marginBottom={5}>
            <Checkbox>{accountOpeningConsent.checkbox4}</Checkbox>
            <FieldFeedback />
          </Field>
          <Navigation marginBottom={7} textMap={navigation} />
        </>
      )}
    </Form>
  )
}

export default AccountInformationPage
