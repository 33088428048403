import React, { FC } from 'react';
import domtoimage from 'dom-to-image-more';
import { Button, IconCamera } from '@piggybank/core';

const screenshot = (filename = 'screenshot.svg', filter): void => {
  domtoimage.toSvg(document.body, { filter: filter }).then(function (dataUrl) {
    const link = document.createElement('a');
    link.download = filename;
    link.href = dataUrl;
    link.id = 'screenshot link';
    link.click();
    link.remove();
  });
};

const Screenshot: FC<{
  filename: string;
  filter: (node: any) => boolean;
}> = ({ filename, filter }) => {
  return (
    <Button
      secondary
      type="button"
      onClick={() => screenshot(filename, filter)}
      {...{ className: 'button' }}
    >
      <IconCamera inline />
    </Button>
  );
};

export default Screenshot;
