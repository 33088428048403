import { MissingTaxIdOrReferenceNumberReason } from 'enums/index'

export const whyDoWeNeedContactDetailsLabel =
  "Why do we need up to date contact details for the 'authorised adult'?"
export const whyDoWeNeedTaxResidencyDetailsLabel = 'Why do we need it'

const startLivingField = {
  label: 'When did your child start living here?',
  errMsg: "Please enter your child's move-in date",
  beforeWasBorn:
    "You've entered a move-in date that's before your child's birth date. Please check and re-enter.",
  // eslint-disable-next-line no-template-curly-in-string
  isAfterOneOfPreviousDates: 'Please only enter a move-in date before ${date}',
}

const reasonForMissingTaxIdOrRefNumberText =
  "Please tell us why you can't provide a Tax identification number or equivalent"

export default {
  heading: "Child's contact and financial details",
  paragraph: 'Let us know how we can get in touch.',
  childAddressSection: {
    heading: 'Child’s address',
    previousAddresses: {
      addAnotherInfo: "Add your child's address before ",
      addAnotherLabel: 'Where did your child live before ',
      removeAddressLabel: {
        visible: 'Remove',
        hidden: ' previous address ',
      },
    },
    form: {
      heading: {
        currentAddress: "Child's current address",
        previousAddress: 'Previous address',
      },
      fields: {
        doesLiveAt: {
          label: 'Does your child live at:',
          errMsg: 'Please choose one of the options',
        },
        whenDidStartLivingHere: startLivingField,
        building: {
          label: 'Building number or name',
          errMsg: 'Please enter the building number or name',
        },
        street: {
          label: 'Street',
          errMsg: 'Please enter the street',
        },
        townOrCity: {
          label: 'Town or city',
          errMsg: 'Please enter the town or city',
        },
        countryOrRegion: {
          label: 'Country or region',
          errMsg: 'Please enter the country or region',
        },
        postCode: {
          label: 'Postcode',
          errMsg: {
            required: 'Please enter the postcode',
            invalidUK: 'Please enter a valid postcode',
            invalidNonUK:
              'Please enter the postcode using only alphanumeric characters and basic punctuation',
          },
        },
        moveInDate: startLivingField,
        isAddressHistoryComplete: {
          errMsg: 'We need to understand where your child has lived for the past 3 years',
        },
      },
    },
  },
  parentContactDetailsSection: {
    heading: 'Contact details',
    expander: {
      title: whyDoWeNeedContactDetailsLabel,
      paragraph1:
        "We may need to contact you regarding this application, or once your child's account is open.",
      paragraph2:
        'If you need to change any contact details you can do so here. Any updates you make will be used for your child’s account only.',
      paragraph3: "You'll also need to update your details in online banking.",
    },
    form: {
      phoneNumber: {
        label: 'Mobile number',
        errMsg: 'Please enter your phone number',
      },
      email: {
        label: 'Email',
        errMsg: {
          required: 'Please enter your email address',
          invalid: 'Please enter a valid email address',
        },
      },
    },
  },
  taxResidencySection: {
    heading: 'Tax residency',
    expander: {
      title: whyDoWeNeedTaxResidencyDetailsLabel,
      paragraph1:
        'National laws based on the requirements of the OECD Common Reporting Standard (“CRS”) require Financial Institutions to collect information about an account holder’s tax residence. This may be reported to the national tax authority if the account holder is tax resident outside the jurisdiction where they hold their account.',
      paragraph2:
        'Under international agreements to exchange account information, the national tax authority may transfer this information to the tax authorities of other jurisdictions in which the account holder may be tax resident.',
      paragraph3:
        'Each jurisdiction has its own rules for defining tax residence, but in general, tax residence is the jurisdiction in which you live. Special circumstances (such as studying abroad, working overseas, or extended travel) may cause you to be resident elsewhere, or resident in more than one jurisdiction at the same time (dual residency).',
      paragraph4:
        'The jurisdiction/jurisdictions in which you pay income tax are therefore likely to be your jurisdiction/jurisdictions of tax residence. For more information about tax residency, please ask your tax adviser or visit the',
      link: {
        href: 'https://www.oecd.org/tax/automatic-exchange/',
        text: 'OECD Automatic Exchange portal',
      },
    },
    form: {
      countryOrJurisdiction: {
        country: {
          label: 'Country or jurisdiction where your child is resident for tax purposes',
          validation: {
            required:
              'Please select the country or jurisdiction where your child is resident for tax purposes',
          },
        },
        additionalCountry: {
          label: 'Please select any other country or jurisdiction where your child is tax resident',
          validation: {
            required:
              'Please select any other country or jurisdiction where your child is tax resident',
          },
        },
        addAnother: {
          label: 'Add another country or jurisdiction',
        },
        remove: {
          label: 'Remove',
        },
      },
      childHasTaxIdOrReferenceNumber: {
        label: 'Does your child have tax identification or reference number?',
        validation: {
          required: 'Please select if your child has a tax identification or reference number',
        },
      },
      reasonForMissingChildTaxIdOrReferenceNumber: {
        label: reasonForMissingTaxIdOrRefNumberText,
        options: [
          {
            label: 'This country / jurisdiction does not issue tax IDs for its residents',
            value: MissingTaxIdOrReferenceNumberReason.NoTaxIdForResidents,
          },
          {
            label: 'No tax identification number required in this country / jurisdiction',
            value: MissingTaxIdOrReferenceNumberReason.NoTaxIdForCountry,
          },
          {
            label: 'I can’t obtain a tax identification number or equivalent',
            value: MissingTaxIdOrReferenceNumberReason.UnableToObtain,
          },
        ],
        validation: {
          required: reasonForMissingTaxIdOrRefNumberText,
        },
      },
      taxIdentificationOrReferenceNumber: {
        label: "Please enter your child's tax identification or reference number",
        validation: {
          required: "Please enter your child's tax identification or reference number",
        },
      },
    },
  },
  childFinancialDetailsSection: {
    heading: "Child's financial details",
    form: {
      isStudent: {
        label: 'Is your child a student at school, college or university?',
        errMsg: 'Please select if your child is a student at school, college or university',
      },
      institutionName: {
        label: 'Name of educational institution',
        errMsg: 'Please enter the name of the educational institution',
      },
      employmentStatus: {
        label: 'Please tell us more about your child by selecting one of these options',
        errMsg: 'Please select an option',
      },
      businessRole: {
        label: 'Select an option that best describes your child',
        errMsg: 'Please select an option',
      },
      occupation: {
        label: "Please describe your child's occupation",
        errMsg: "Please describe your child's occupation",
      },
      employerName: {
        label: "What's your child's employer's name?",
        errMsg: "Please tell us your child's employer's name",
      },
      employerLocation: {
        label: "Where is your child's employer located?",
        errMsg: "Please tell us where your child's employer is located",
      },
      annualSalary: {
        label: "What's your child's annual salary before tax?",
        errMsg: "Please enter your child's annual salary before tax",
      },
      businessName: {
        label: 'What is the name of the business that your child owns?',
        errMsg: "Please tell us the name of your child's business",
      },
      businessType: {
        label: "What's the business type?",
        errMsg: "Please select your child's business type",
      },
      industry: {
        label: 'Industry',
        errMsg: "Please select your child's industry",
      },
      sector: {
        label: 'Sector',
        errMsg: "Please select your child's sector",
      },
      businessLocation: {
        label: "Where is your child's business located?",
        errMsg: "Please tell us where your child's business is located",
      },
      annualIncome: {
        label: "What's your child's annual earnings from business?",
        errMsg: "Please enter your child's annual earnings from business",
      },
      traderName: {
        label: "What name does your child's business trade under?",
        errMsg: "Please enter the name your child's business trades under",
      },
      workLocation: {
        label: 'Which country does your child work in?',
        errMsg: 'Please tell us the country your child works in',
      },
      controlledBusinessName: {
        label: 'What is the name of the business your child controls?',
        errMsg: 'Please tell us the name of the business your child controls',
      },
      controlledBusinessLocation: {
        label: 'Where is the business your child controls located?',
        errMsg: 'Please tell us where the business your child controls is located',
      },
    },
  },
  navigation: {
    previous: 'Back',
    next: 'Continue to last step',
  },
}
