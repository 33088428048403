import React from 'react'

import { Section } from '@piggybank/core'
import { Field, FieldFeedback, Label, TextInput, Select } from '@piggybank/form'

import { countries } from 'lookups/index'
import {
  BUILDING_MAX_LENGTH,
  MOVE_IN_DATE_FORMAT,
  NON_UK_POSTCODE_MAX_LENGTH,
  STREET_MAX_LENGTH,
  TOWN_MAX_LENGTH,
} from 'constants/index'
import dict from '../../dictionary'
import { AccessibleDateInput } from 'client/components'
import replaceApostrophes from 'client/utils/replaceApostrophes'

const onPostCodeChange = (setValue, bag) => {
  bag.value = replaceApostrophes(bag.value).toUpperCase()
  setValue(bag)
}

const AddressForm = ({ values, path }) => {
  const {
    building,
    street,
    townOrCity,
    countryOrRegion,
    postCode,
    moveInDate,
  } = dict.childAddressSection.form.fields

  return (
    <Section>
      <Field
        name={`${path}.building`}
        onChange={(next, rest) => {
          rest.value = replaceApostrophes(rest.value)
          next(rest)
        }}
        onBlur={(next, rest) => {
          values.building = values.building.trim()
          next(rest)
        }}
      >
        <Label>{building.label}</Label>
        <TextInput maxLength={BUILDING_MAX_LENGTH} />
        <FieldFeedback />
      </Field>
      <Field
        name={`${path}.street`}
        onChange={(next, rest) => {
          rest.value = replaceApostrophes(rest.value)
          next(rest)
        }}
        onBlur={(next, rest) => {
          values.street = values.street.trim()
          next(rest)
        }}
      >
        <Label>{street.label}</Label>
        <TextInput maxLength={STREET_MAX_LENGTH} />
        <FieldFeedback />
      </Field>
      <Field
        name={`${path}.townOrCity`}
        onChange={(next, rest) => {
          rest.value = replaceApostrophes(rest.value)
          next(rest)
        }}
        onBlur={(next, rest) => {
          values.townOrCity = values.townOrCity.trim()
          next(rest)
        }}
      >
        <Label>{townOrCity.label}</Label>
        <TextInput maxLength={TOWN_MAX_LENGTH} />
        <FieldFeedback />
      </Field>
      <Field name={`${path}.countryOrRegion`}>
        <Label>{countryOrRegion.label}</Label>
        <Select options={countries} />
        <FieldFeedback />
      </Field>
      <Field
        name={`${path}.postCode`}
        onChange={onPostCodeChange}
        onBlur={(next, rest) => {
          values.postCode = values.postCode.trim()
          next(rest)
        }}
      >
        <Label>{postCode.label}</Label>
        <TextInput maxLength={NON_UK_POSTCODE_MAX_LENGTH} />
        <FieldFeedback />
      </Field>
      <Field name={`${path}.moveInDate`}>
        <Label>{moveInDate.label}</Label>
        <AccessibleDateInput format={MOVE_IN_DATE_FORMAT} />
        <FieldFeedback />
      </Field>
    </Section>
  )
}

export default AddressForm
