export const bookAppointmentLinkLabel = 'book an appointment'

export default {
  contactOptions: [
    {
      heading: 'Personal Banking & Advance Customers',
      phoneNumber: '03457 404 404',
      availability: 'Lines are open 8:00-22:00 every day.',
    },
    {
      heading: 'Premier Banking & Jade Customers',
      phoneNumber: '03457 70 70 70',
      availability: 'Lines are open 24 hours a day.',
    },
  ],
  appointmentChunks: [
    { text: 'Alternatively, you can ' },
    {
      text: bookAppointmentLinkLabel,
      link:
        'https://www.appointmentbooking.hsbc.co.uk/group-appointmentbooking-ui-angular-web/#/cusaptboo?user=Prospect',
      external: true,
    },
  ],
}
