import { get } from 'lodash'

import {
  OfferType,
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumErrorType,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import {
  CHILD_ADDITIONAL_NATIONALITIES_FIELD_NAME_PATH,
  CHILD_BIRTH_PLACE_FIELD_NAME_PATH,
  CHILD_GENDER_FIELD_NAME_PATH,
  CHILD_HAS_PREVIOUS_NAMES_FIELD_NAME_PATH,
  CHILD_NATIONALITY_FIELD_NAME_PATH,
  CHILD_PERMANENT_RESIDENCE_FIELD_NAME_PATH,
} from 'constants/formFieldNamePaths'
import Age from 'enums/Age'
import { T_TaggingSpecs } from 'types/index'
import { returnToOnlineBankingLinkLabel } from 'client/components/common/ReturnToOnlineBanking'
import { getStore } from 'client/store'
import { detailsDisclaimerLabel } from 'client/pages/ChildPersonalDetailsPage'

const pageKey = PageKey.ChildPersonalDetailsPage

export const getIneligibilityModalEvent = value => ({
  trackingPageName: pageKey,
  component: TealiumComponent.ChildBirthDetailsSection,
  type: PiggyEvent.OnIneligibilityModalOpen,
  value,
})

export const getIneligibilityModalConfirmationEvent = value => ({
  trackingPageName: pageKey,
  component: TealiumComponent.ChildBirthDetailsSection,
  type: PiggyEvent.OnMyMoneyIneligibilityModalOpen,
  value,
})

export const getAgeIneligibilityEvent = value => ({
  trackingPageName: pageKey,
  component: TealiumComponent.ChildBirthDetailsSection,
  type: PiggyEvent.OnIneligibilityModalOpen,
  value,
})

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const childPersonalDetailsPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.WizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      funnel_step: '2',
      raw_datalayer: '3406v12',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer opens expander with explanation why we need the personal details.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Expander,
      type: PiggyEvent.OnOpen,
      name: detailsDisclaimerLabel,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Accordion,
      event_content: 'what will we do with these details',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v13',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer selects region of child's birth.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
      name: CHILD_BIRTH_PLACE_FIELD_NAME_PATH,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `region of birth: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v14',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer selects region of child's permanent residence.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
      name: CHILD_PERMANENT_RESIDENCE_FIELD_NAME_PATH,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `region of permanent residence: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v15',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer selects region of child's nationality.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
      name: CHILD_NATIONALITY_FIELD_NAME_PATH,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `nationality and citizenship: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v16',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates information on the child's previous names.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Radio,
      name: CHILD_HAS_PREVIOUS_NAMES_FIELD_NAME_PATH,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Radio,
      event_content: `child had any previous name: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v17',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the child's gender.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Radio,
      name: CHILD_GENDER_FIELD_NAME_PATH,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Radio,
      event_content: `gender: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v18',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer adds another nationality.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.AddAnother,
      type: PiggyEvent.OnAdd,
      name: CHILD_ADDITIONAL_NATIONALITIES_FIELD_NAME_PATH,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: `add another nationality`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v19',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer is presented a MyMoney ineligibility modal.`,
    eventIdentifier: getIneligibilityModalEvent(OfferType.MyMoneyAccount),
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Popup,
      event_content: `ineligible for mymoney`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v20',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer is presented a MySavings ineligibility modal.`,
    eventIdentifier: getIneligibilityModalEvent(OfferType.MySavingsAccount),
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Popup,
      event_content: `ineligible for mysaving`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v22',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer confirms to continue with MySavings.`,
    eventIdentifier: getIneligibilityModalConfirmationEvent(OfferType.MySavingsAccount),
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Popup,
      event_content: `continue with mysavings`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v21',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer confirms to continue with MyMoney.`,
    eventIdentifier: getIneligibilityModalConfirmationEvent(OfferType.MyMoneyAccount),
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Popup,
      event_content: `continue with mymoney`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v23',
    }),
  },

  {
    description: `Fired on ${pageKey} when customer insert's a date of birth of a child which is too old.`,
    eventIdentifier: getAgeIneligibilityEvent(Age.TooOld),
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Application,
      event_content: `child no longer eligible`,
      event_category: TealiumEventCategory.Error,
      raw_datalayer: '3406v24',
      error_type: TealiumErrorType.Application,
    }),
  },
  {
    description: `Fired on ${pageKey} when customer insert's a date of birth of a child which is too young.`,
    eventIdentifier: getAgeIneligibilityEvent(Age.TooYoung),
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Application,
      event_content: `child not yet seven`,
      event_category: TealiumEventCategory.Error,
      raw_datalayer: '3406v25',
      error_type: TealiumErrorType.Application,
    }),
  },
]

export const customChildPersonalDetailsPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired on ${pageKey} when customer clicks on "${returnToOnlineBankingLinkLabel}" link.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Button,
      type: PiggyEvent.OnClick,
      text: returnToOnlineBankingLinkLabel,
    },
    content: () => {
      const state = getStore().getState()
      const offerSpecificProps = {
        [OfferType.MyMoneyAccount]: {
          event_content: 'return to online banking from ineligible mymoney popup',
          raw_datalayer: '3406v72',
        },
        [OfferType.MySavingsAccount]: {
          event_content: 'return to online banking from ineligible mysaving popup',
          raw_datalayer: '3406v71',
        },
      }[get(state, 'localData.productBasket.offerType')]

      return {
        ...getAllCommonTags(),
        ...offerSpecificProps,
        event_action: TealiumEventAction.OnSite,
        event_category: TealiumEventCategory.Content,
      }
    },
  },
]
