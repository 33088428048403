import React from 'react'

import { Heading, Section, Link } from '@piggybank/core'
import { Hint } from '@piggybank/form'

import dict from './dictionary'
import { InteractiveContent } from 'client/components'

type T_Props = {
  children?: any
  withAppointment?: boolean
}

const ContactMethods = ({ children, withAppointment }: T_Props) => (
  <Section>
    {children}
    {dict.contactOptions.map(({ heading, phoneNumber, availability }, i) => (
      <dl key={i}>
        <dt>
          <Heading level={3} marginBottom={1}>
            {heading}
          </Heading>
        </dt>
        <dd style={{ marginBottom: 'var(--unit2)' }}>
          <Link elementType={'a'} href={`tel:${phoneNumber.split(' ').join('')}`}>
            {phoneNumber}
          </Link>
        </dd>
        <dd style={{ marginBottom: 'var(--unit4)' }}>
          <Hint marginBottom={4}>{availability}</Hint>
        </dd>
      </dl>
    ))}
    {withAppointment && <InteractiveContent chunks={dict.appointmentChunks} />}
  </Section>
)

export default ContactMethods
