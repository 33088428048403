import { PageKey, YesNoBoolean, MoneySource } from 'enums/index'
import { T_FormInput } from 'types/form'
import { getRandomLetters, getRandomEmail } from './../helpers'

export default {
  [PageKey.ProductEligibilityCheckPage]: {
    dateOfBirth: '2006-05-27',
  },
  [PageKey.ChildPersonalDetailsPage]: {
    title: 'MRS',
    firstNames: 'Diane',
    familyName: 'Adefola-' + getRandomLetters(),
    hasPreviousNames: 'no',
    gender: 'FEMALE',
    dateOfBirth: '2006-05-27',
    cityOfPermanentResidence: 'London',
  },
  [PageKey.ChildContactAndFinancialDetailsPage]: {
    doesLiveAt: YesNoBoolean.Yes,
    whenDidStartLivingHere: '2006-05',
    phoneNumber: {
      countryCode: 'GB',
      raw: '7845305853',
      value: `+447845305853`,
    },
    email: getRandomEmail(),
    isStudent: YesNoBoolean.Yes,
    institutionName: 'School Of Rock',
  },
  [PageKey.AccountInformationPage]: {
    regularPaymentSource: MoneySource.TransferFromFamily,
    firstDepositSource: MoneySource.TransferFromFamily,
    initialDeposit: '100',
    marketingPreferences: ['TELEPHONE', 'SMS', 'EMAIL', 'LETTER'],
    accountOpeningConsent1: true,
    accountOpeningConsent2: true,
    accountOpeningConsent3: true,
  },
} as T_FormInput
