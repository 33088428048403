import React, { FC } from 'react'
import { Section, IconStatusErrorOnlight, Heading, Paragraph, withTrackPage } from '@piggybank/core'

import { ReturnToOnlineBanking } from 'client/components'
import { PageKey, TealiumComponent } from 'enums/index'
import { T_PageProps } from 'types/props'

import dict from './dictionary'

export const TechnicalErrorPage: FC<T_PageProps> = () => (
  <Section marginBottom={7}>
    <Section>
      <div style={{ color: 'var(--scarlet)' }}>
        <IconStatusErrorOnlight width="2.5em" />
      </div>
      <Heading level={1} data-bdd={'main-heading'}>
        {dict.heading}
      </Heading>
      <Paragraph lead={true} data-bdd={'service-unavailable-paragraph'}>
        {dict.paragraph}
      </Paragraph>
    </Section>
    <ReturnToOnlineBanking />
  </Section>
)

export default withTrackPage(
  PageKey.TechnicalErrorPage,
  TealiumComponent.NonWizardPage
)(TechnicalErrorPage)
