import { LegalGender } from 'enums/index'

export const detailsDisclaimerLabel = 'What will we do with these details?'

export default {
  introductionSection: {
    heading: 'Child’s personal details',
    paragraph: 'We need to know a bit more about your child.',
    detailsDisclaimer: {
      label: detailsDisclaimerLabel,
      content: [
        [
          {
            text:
              "Once you've applied for this product, your and your child's personal information will be used in line with our ",
          },
          {
            text: 'Privacy Notice',
            link: 'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/privacy-notice2.pdf',
            external: true,
          },
        ],
        [
          {
            text:
              'We use automated systems to help us detect and prevent fraud and to meet our legal obligations. We will decline your application if fraud is detected.',
          },
        ],
        [
          {
            text:
              "We may ask your child to verify their ID digitally to help protect your child from fraud and financial crime. We do this by using automated systems to check that an image or video of your child's face matches their photo on their ID document using biometric analysis. Please make sure your child is aware of this.",
          },
        ],
      ],
    },
  },
  formSection: {
    gender: {
      label: 'Gender',
      options: {
        textMap: { yes: 'Male', no: 'Female' },
        values: { yes: LegalGender.Male, no: LegalGender.Female },
      },
      validation: {
        required: "Please select your child's gender",
      },
    },
    permanentResidence: {
      label: 'Country or region of permanent residence',
      validation: {
        required: "Please select your child's country or region of permanent residence",
      },
    },
    cityOfPermanentResidence: {
      label: 'City of permanent residence',
      validation: {
        required: "Please enter your child's city of permanent residence",
      },
    },
  },
}
