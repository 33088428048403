import { Product, TealiumProductId } from 'enums/index'

export const productConfigurations = [
  {
    primaryProduct: Product.MY_SAVINGS,
    expectedTagValue: TealiumProductId.MySavings,
  },
  {
    primaryProduct: Product.MY_MONEY,
    expectedTagValue: TealiumProductId.MyMoney,
  },
  {
    primaryProduct: Product.PREMIER_MY_SAVINGS,
    expectedTagValue: TealiumProductId.PremierMySavings,
  },
  {
    primaryProduct: Product.PREMIER_MY_MONEY,
    expectedTagValue: TealiumProductId.PremierMyMoney,
  },
]
