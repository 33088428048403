import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import reducer from 'client/store/reducers'
import { getInitialState } from 'client/helpers'
import { Env } from 'enums/index'
import { APP_NAME } from 'constants/index'
import { T_Store } from 'types/index'

let store

export default (state: T_Store) => {
  const initialState = getInitialState(state)
  const { NODE_ENV, REACT_APP_ENV } = initialState.environment

  if (NODE_ENV === Env.Test || REACT_APP_ENV === Env.RemoteProd) {
    store = createStore(reducer, initialState, applyMiddleware(thunk))
    return store
  } else {
    const composeEnhancers = composeWithDevTools({
      name: APP_NAME,
      trace: true,
    })
    store = createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(thunk, createLogger({ collapsed: true })))
    )
    return store
  }
}

export const getStore = () => store
