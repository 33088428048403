import {
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { T_TaggingSpecs } from 'types/taggingSpecs'

const pageKey = PageKey.ApplicationDeclinedInternalPolicyPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const applicationDeclinedInternalPolicyPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      application_event: 'declined',
      application_decision: 'internal policy',
      funnel_step: '9',
      raw_datalayer: '3406v59',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on return to online banking button.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Button,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_category: TealiumEventCategory.Content,
      event_content: 'return to online banking',
      raw_datalayer: '6067v4',
    }),
  },
]
