import includes from 'lodash/includes'

import { getChildAgeEligibilityStatus, getYearsSince } from 'client/helpers'
import { Age, OfferType, Product } from 'enums/index'
import { AGE_RANGE, PRODUCT_ELIGIBILITY_MATRIX, T_EligibilityDecision } from 'constants/index'

export default (
  availableProducts: Product[],
  childDateOfBirth: string
): {
  offerType: OfferType
  ageStatus: Age
  primaryProduct: Product
  additionalProduct: Product
} => {
  let offerType: OfferType
  let ageStatus: Age
  let primaryProduct: Product
  let additionalProduct: Product

  const childAge = getYearsSince(childDateOfBirth)
  ageStatus = getChildAgeEligibilityStatus(childAge)

  if (ageStatus === Age.ValidAge) {
    const ageRange = includes(AGE_RANGE[Age.SevenToTenRange], childAge)
      ? Age.SevenToTenRange
      : Age.ElevenToSeventeenRange
    const decision = PRODUCT_ELIGIBILITY_MATRIX[ageRange] as T_EligibilityDecision
    offerType = decision.eligibleAccount
    primaryProduct = availableProducts.filter(product =>
      decision.primaryProducts.includes(product)
    )[0]
    if (decision.additionalProducts) {
      additionalProduct = availableProducts.filter(product =>
        decision.additionalProducts.includes(product)
      )[0]
    }
  }

  return { offerType, ageStatus, primaryProduct, additionalProduct }
}
