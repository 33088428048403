import { Age, OfferType, Product } from 'enums/index'

export type T_EligibilityDecision = {
  eligibleAccount: OfferType
  primaryProducts: Product[]
  additionalProducts?: Product[]
}

export const PRODUCT_ELIGIBILITY_MATRIX = {
  [Age.SevenToTenRange]: {
    eligibleAccount: OfferType.MySavingsAccount,
    primaryProducts: [Product.PREMIER_MY_SAVINGS, Product.MY_SAVINGS],
  },
  [Age.ElevenToSeventeenRange]: {
    eligibleAccount: OfferType.MyMoneyAccount,
    primaryProducts: [Product.PREMIER_MY_MONEY, Product.MY_MONEY],
    additionalProducts: [Product.PREMIER_MY_SAVINGS, Product.MY_SAVINGS],
  },
}
