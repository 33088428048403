import {
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import dictionary from 'client/components/TimeoutWarning/dictionary'
import { T_TaggingSpecs } from 'types/index'

const getAllCommonTags = getCommonTags({
  page_type: TealiumPageType.Application,
})

export const timeoutModalOpenEvent = {
  type: PiggyEvent.OnTimeoutModalOpen,
  component: TealiumComponent.TimeoutWarningDialog,
}

export const timeoutModalTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer is presented with timeout modal.`,
    eventIdentifier: timeoutModalOpenEvent,
    content: ({ trackingPageName }) => ({
      ...getAllCommonTags(),
      ...getPageTags(trackingPageName),
      event_action: TealiumEventAction.Popup,
      event_category: TealiumEventCategory.Content,
      funnel_step_name: 'timeout popup',
      event_content: 'timeout',
      raw_datalayer: '3406v62',
    }),
  },
  {
    description: `Fired when customer clicks on "continue application" on timeout modal.`,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      component: TealiumComponent.Button,
      text: dictionary.buttonLabel,
    },
    content: ({ trackingPageName }) => ({
      ...getAllCommonTags(),
      ...getPageTags(trackingPageName),
      event_action: TealiumEventAction.Popup,
      event_category: TealiumEventCategory.Content,
      funnel_step_name: 'timeout popup',
      event_content: 'popup - continue application',
      raw_datalayer: '3406v63',
    }),
  },
]
