import {
  PageKey,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { doesYourChildLiveAtFieldName } from 'client/pages/ChildContactAndFinancialDetailsPage/ChildAddressSection'
import {
  businessRoleFieldName,
  childEmploymentStatusFieldName,
  isYourChildStudentFieldName,
} from 'client/pages/ChildContactAndFinancialDetailsPage/ChildFinancialDetails/ChildFinancialDetails'
import {
  addAnotherCountryOrJurisdictionButtonName,
  countryOrJurisdictionFieldName,
} from 'client/pages/ChildContactAndFinancialDetailsPage/TaxResidency/TaxResidency'
import PiggyEvent from 'enums/PiggyEvent'
import { businessTypeFieldName } from 'client/pages/ChildContactAndFinancialDetailsPage/ChildFinancialDetails/commonComponents'
import { businessLocationFieldName } from 'client/pages/ChildContactAndFinancialDetailsPage/ChildFinancialDetails/BusinessOwnerFinancialDetails'
import { T_TaggingSpecs } from 'types/index'
import {
  whyDoWeNeedContactDetailsLabel,
  whyDoWeNeedTaxResidencyDetailsLabel,
} from 'client/pages/ChildContactAndFinancialDetailsPage'

const pageKey = PageKey.ChildContactAndFinancialDetailsPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const childContactAndFinancialDetailsPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.WizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      funnel_step: '3',
      raw_datalayer: '3406v26',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the 'does live at field'.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Radio,
      name: doesYourChildLiveAtFieldName,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Radio,
      event_content: `does your child live at: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v27',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates child's student status.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Radio,
      name: isYourChildStudentFieldName,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Radio,
      event_content: `is your child a student: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v28',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer opens expander with explanation why we need the tax contact details.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Expander,
      type: PiggyEvent.OnOpen,
      name: whyDoWeNeedContactDetailsLabel,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Accordion,
      event_content: 'why do we need it - contact details',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v67',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer opens expander with explanation why we need the tax residency details.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Expander,
      type: PiggyEvent.OnOpen,
      name: whyDoWeNeedTaxResidencyDetailsLabel,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Accordion,
      event_content: 'why do we need it - tax residency',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v68',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the value of tax residency country or jurisdiction.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
      name: countryOrJurisdictionFieldName,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `country of jurisdiction: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v32',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the employment status of child.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
      name: childEmploymentStatusFieldName,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `employment status of child: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v33',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the business role/occupation of a child.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      name: businessRoleFieldName,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `occupation of child: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v34',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the child's business type.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      name: businessTypeFieldName,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `business type: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v35',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer updates the child's business location.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      name: businessLocationFieldName,
      component: TealiumComponent.Select,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `country of business: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v36',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer adds another country or jurisdiction.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.AddAnother,
      type: PiggyEvent.OnAdd,
      name: addAnotherCountryOrJurisdictionButtonName,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: `add another country of jurisdiction`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v37',
    }),
  },
]
