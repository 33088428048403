import React from 'react'

import { Section } from '@piggybank/core'
import { Field, Label, TextInput, FieldFeedback, Select } from '@piggybank/form'

import {
  AnnualIncome,
  AnnualSalary,
  BusinessType,
  Industry,
  Sector,
} from 'client/pages/ChildContactAndFinancialDetailsPage/ChildFinancialDetails/commonComponents'

import { countries } from 'lookups/index'
import replaceApostrophes from 'client/utils/replaceApostrophes'
import { MAX_BUSINESS_NAME_LENGTH } from 'constants/customer'
import dict from '../dictionary'

const { form } = dict.childFinancialDetailsSection

export const SoleTraderFinancialDetails = ({
  values,
  pageKey,
  businessType,
  industry,
  setFieldValue,
}) => (
  <Section>
    <Field
      name={`${pageKey}.traderName`}
      onChange={(next, rest) => {
        rest.value = replaceApostrophes(rest.value)
        next(rest)
      }}
      onBlur={(next, rest) => {
        values.traderName = values.traderName.trim()
        next(rest)
      }}
    >
      <Label>{form.traderName.label}</Label>
      <TextInput maxLength={MAX_BUSINESS_NAME_LENGTH} />
      <FieldFeedback />
    </Field>
    <Field name={`${pageKey}.workLocation`}>
      <Label>{form.workLocation.label}</Label>
      <Select options={countries} />
      <FieldFeedback />
    </Field>
    <BusinessType pageKey={pageKey} setFieldValue={setFieldValue} />
    <Industry pageKey={pageKey} businessType={businessType} setFieldValue={setFieldValue} />
    <Sector pageKey={pageKey} industry={industry} />
    <AnnualSalary pageKey={pageKey} />
    <AnnualIncome pageKey={pageKey} />
  </Section>
)
