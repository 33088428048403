import React from 'react'
import { Button } from '@piggybank/core'

import dict from './dictionary'

type T_Props = {
  isSecondary?: boolean
}

const ReturnToOnlineBanking = ({ isSecondary }: T_Props) => (
  <Button
    elementType="a"
    data-bdd={'return-to-online-banking-button'}
    href={dict.link}
    secondary={isSecondary || false}
  >
    {dict.text}
  </Button>
)

export default ReturnToOnlineBanking
