import { unitedKingdom } from 'lookups/countries'
import { ntbProfiles } from 'stubs/index'
import { PageKey } from 'enums/index'

const pageKey = PageKey.ChildPersonalDetailsPage

export default (ntbProfileId: string, dateOfBirth: string) => ({
  [pageKey]: {
    title: '',
    firstNames: '',
    familyName: '',
    hasPreviousNames: '',
    previousFirstNames: '',
    previousFamilyName: '',
    gender: '',
    birthPlace: unitedKingdom.value,
    permanentResidence: unitedKingdom.value,
    cityOfPermanentResidence: '',
    nationality: unitedKingdom.value,
    additionalNationalities: [],
    ...(ntbProfileId ? ntbProfiles[Number(ntbProfileId) - 1].data[pageKey] : {}),
    dateOfBirth,
  },
})
