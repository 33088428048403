import { BusinessRole } from 'enums/index'

const employee = {
  label: 'Employee',
  value: BusinessRole.Employee,
}

const businessOwner = {
  label: 'Business owner',
  value: BusinessRole.BusinessOwner,
}
const soleTrader = {
  label: 'Sole Trader',
  value: BusinessRole.SoleTrader,
}
const companyDirector = {
  label: 'Company Director',
  value: BusinessRole.CompanyDirector,
}

export const SELF_EMPLOYED_BUSINESS_ROLES = [soleTrader, businessOwner, companyDirector]
export const FULL_OR_PART_TIME_EMPLOYED_BUSINESS_ROLES = [employee, businessOwner, companyDirector]
