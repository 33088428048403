/* istanbul ignore file */

// TODO: this file needs serious split and refactor

import yup from 'client/validation'
import { PageKey, YesNoBoolean, EmploymentStatus, BusinessRole } from 'enums/index'

import {
  BUILDING_MAX_LENGTH,
  BUILDING_MIN_LENGTH,
  STREET_MAX_LENGTH,
  STREET_MIN_LENGTH,
  TOWN_MAX_LENGTH,
  TOWN_MIN_LENGTH,
} from 'constants/index'

import dict from './dictionary'
import { unitedKingdom } from 'lookups/countries'
import { getIsAddressHistoryComplete } from './_helpers'
import {
  REGEX_DEFAULT,
  REGEX_ANYTHING_BUT_WHITESPACE,
  REGEX_TAX_ID_OR_REF_NUMBER,
  REGEX_YES_NO,
} from 'src/regex'

const {
  doesLiveAt,
  whenDidStartLivingHere,
  building,
  street,
  townOrCity,
  countryOrRegion,
  postCode,
  moveInDate,
  isAddressHistoryComplete,
} = dict.childAddressSection.form.fields

const { taxResidencySection } = dict

const { phoneNumber, email } = dict.parentContactDetailsSection.form
const { form } = dict.childFinancialDetailsSection

const countryOrJurisdictionValidation = {
  countryOrJurisdictionValue: yup
    .string()
    .required(taxResidencySection.form.countryOrJurisdiction.additionalCountry.validation.required),
  childHasTaxIdOrReferenceNumber: yup.string().when(['countryOrJurisdictionValue'], {
    is: country => country && country !== unitedKingdom.value,
    then: yup
      .string()
      .required(taxResidencySection.form.childHasTaxIdOrReferenceNumber.validation.required),
  }),
  reasonForMissingChildTaxIdOrReferenceNumber: yup
    .string()
    .when(['childHasTaxIdOrReferenceNumber'], {
      is: YesNoBoolean.No,
      then: yup
        .string()
        .required(
          taxResidencySection.form.reasonForMissingChildTaxIdOrReferenceNumber.validation.required
        ),
    }),
  taxIdentificationOrReferenceNumber: yup.string().when(['childHasTaxIdOrReferenceNumber'], {
    is: YesNoBoolean.Yes,
    then: yup
      .string()
      .matches(
        REGEX_TAX_ID_OR_REF_NUMBER,
        taxResidencySection.form.taxIdentificationOrReferenceNumber.validation.required
      )
      .required(taxResidencySection.form.taxIdentificationOrReferenceNumber.validation.required),
  }),
}

const getBusinessRoleDependantFieldValidations = fields =>
  fields.reduce(
    (acc, { fieldName, applicableBusinessRoles, applicableEmploymentStatuses }) => ({
      ...acc,
      [fieldName]: yup.string().when(['isStudent', 'employmentStatus', 'businessRole'], {
        is: (
          _isStudent: YesNoBoolean,
          _employmentStatus: EmploymentStatus,
          businessRole: BusinessRole
        ) =>
          _isStudent === YesNoBoolean.No &&
          _employmentStatus !== EmploymentStatus.Unemployed &&
          (applicableEmploymentStatuses
            ? applicableEmploymentStatuses.includes(_employmentStatus)
            : true) &&
          applicableBusinessRoles.includes(businessRole),
        then: yup
          .string()
          .matches(REGEX_DEFAULT, form[fieldName].errMsg)
          .required(form[fieldName].errMsg),
      }),
    }),
    {}
  )

const getValidationSchema = childDateOfBirth => {
  const commonAddressValidation = {
    building: yup
      .string()
      .min(BUILDING_MIN_LENGTH)
      .max(BUILDING_MAX_LENGTH)
      .matches(REGEX_DEFAULT, building.errMsg)
      .required(building.errMsg),
    street: yup
      .string()
      .min(STREET_MIN_LENGTH)
      .max(STREET_MAX_LENGTH)
      .matches(REGEX_DEFAULT, street.errMsg)
      .required(street.errMsg),
    townOrCity: yup
      .string()
      .min(TOWN_MIN_LENGTH)
      .max(TOWN_MAX_LENGTH)
      .matches(REGEX_DEFAULT, townOrCity.errMsg)
      .required(townOrCity.errMsg),
    countryOrRegion: yup.string().required(countryOrRegion.errMsg),
    postCode: yup.string().when('countryOrRegion', {
      is: unitedKingdom.value,
      then: yup
        .string()
        .isValidUKPostCodeValidation(postCode.errMsg.invalidUK)
        .required(postCode.errMsg.required),
      otherwise: yup
        .string()
        .isValidNonUKPostCodeValidation(postCode.errMsg.invalidNonUK)
        .required(postCode.errMsg.required),
    }),
  }

  return yup.object().shape({
    [PageKey.ChildContactAndFinancialDetailsPage]: yup.object().shape({
      //
      doesLiveAt: yup
        .string()
        .matches(REGEX_YES_NO)
        .required(doesLiveAt.errMsg),
      //
      whenDidStartLivingHere: yup.string().when('doesLiveAt', {
        is: value => value === YesNoBoolean.Yes,
        then: yup
          .string()
          .isSameMonthOrAfterDateValidation(whenDidStartLivingHere.beforeWasBorn, childDateOfBirth)
          .noFutureDateValidation(whenDidStartLivingHere.errMsg),
      }),
      //
      newCurrentAddress: yup.object().when('doesLiveAt', {
        is: YesNoBoolean.No,
        then: yup.object().shape({
          ...commonAddressValidation,
          moveInDate: yup
            .string()
            .isSameMonthOrAfterDateValidation(
              whenDidStartLivingHere.beforeWasBorn,
              childDateOfBirth
            )
            .noFutureDateValidation(moveInDate.errMsg),
        }),
      }),
      //
      previousAddresses: yup.array().of(
        yup.object().shape({
          ...commonAddressValidation,
          moveInDate: yup
            .string()
            .isSameMonthOrAfterDateValidation(
              whenDidStartLivingHere.beforeWasBorn,
              childDateOfBirth
            )
            .isAddressMoveInDateInOrderValidation(moveInDate.isAfterOneOfPreviousDates)
            .noFutureDateValidation(moveInDate.errMsg),
        })
      ),
      isAddressHistoryComplete: yup
        .string()
        .when(
          [
            'doesLiveAt',
            'previousAddresses',
            'whenDidStartLivingHere',
            'newCurrentAddress.moveInDate',
          ],
          {
            is: (doesLiveAtVal, previousAddressesVal, whenDidStartLivingHereVal, moveInDateVal) => {
              const currentMoveInDate =
                doesLiveAtVal === YesNoBoolean.Yes ? whenDidStartLivingHereVal : moveInDateVal

              return getIsAddressHistoryComplete(currentMoveInDate, previousAddressesVal)
            },
            then: yup.string().required(isAddressHistoryComplete.errMsg),
          }
        ),
      //
      phoneNumber: yup.object().phoneNumberValidation(phoneNumber.errMsg),
      //
      email: yup.string().emailValidation(email.errMsg.required, email.errMsg.invalid),
      //
      ...countryOrJurisdictionValidation,
      countryOrJurisdictionAdditionalValues: yup
        .array()
        .of(yup.object().shape(countryOrJurisdictionValidation)),
      //
      isStudent: yup
        .string()
        .matches(REGEX_YES_NO)
        .required(form.isStudent.errMsg),
      //
      institutionName: yup.string().when('isStudent', {
        is: value => value === YesNoBoolean.Yes,
        then: yup
          .string()
          .matches(REGEX_DEFAULT, form.institutionName.errMsg)
          .required(form.institutionName.errMsg),
      }),
      //
      employmentStatus: yup.string().when('isStudent', {
        is: value => value === YesNoBoolean.No,
        then: yup.string().required(form.employmentStatus.errMsg),
      }),
      //
      businessRole: yup.string().when(['isStudent', 'employmentStatus'], {
        is: (isStudent, employmentStatus) =>
          isStudent === YesNoBoolean.No && employmentStatus !== EmploymentStatus.Unemployed,
        then: yup.string().required(form.businessRole.errMsg),
      }),
      ...getBusinessRoleDependantFieldValidations([
        { fieldName: 'occupation', applicableBusinessRoles: [BusinessRole.Employee] },
        { fieldName: 'employerName', applicableBusinessRoles: [BusinessRole.Employee] },
        { fieldName: 'employerLocation', applicableBusinessRoles: [BusinessRole.Employee] },
        { fieldName: 'businessName', applicableBusinessRoles: [BusinessRole.BusinessOwner] },
        { fieldName: 'businessLocation', applicableBusinessRoles: [BusinessRole.BusinessOwner] },
        { fieldName: 'traderName', applicableBusinessRoles: [BusinessRole.SoleTrader] },
        { fieldName: 'workLocation', applicableBusinessRoles: [BusinessRole.SoleTrader] },
        {
          fieldName: 'controlledBusinessName',
          applicableBusinessRoles: [BusinessRole.CompanyDirector],
        },
        {
          fieldName: 'controlledBusinessLocation',
          applicableBusinessRoles: [BusinessRole.CompanyDirector],
        },
        {
          fieldName: 'businessType',
          applicableBusinessRoles: [
            BusinessRole.BusinessOwner,
            BusinessRole.CompanyDirector,
            BusinessRole.SoleTrader,
          ],
        },
      ]),
      industry: yup
        .string()
        .when(['isStudent', 'employmentStatus', 'businessRole', 'businessType'], {
          is: (
            _isStudent: YesNoBoolean,
            _employmentStatus: EmploymentStatus,
            businessRole: BusinessRole,
            businessType: string
          ) =>
            _isStudent === YesNoBoolean.No &&
            [
              EmploymentStatus.FullTimeEmployed,
              EmploymentStatus.PartTimeEmployed,
              EmploymentStatus.SelfEmployed,
            ].includes(_employmentStatus) &&
            [
              BusinessRole.BusinessOwner,
              BusinessRole.CompanyDirector,
              BusinessRole.SoleTrader,
            ].includes(businessRole) &&
            businessType,
          then: yup
            .string()
            .matches(REGEX_ANYTHING_BUT_WHITESPACE, form.industry.errMsg)
            .required(form.industry.errMsg),
        }),
      sector: yup.string().when(['isStudent', 'employmentStatus', 'businessRole', 'industry'], {
        is: (
          _isStudent: YesNoBoolean,
          _employmentStatus: EmploymentStatus,
          businessRole: BusinessRole,
          industry: string
        ) =>
          _isStudent === YesNoBoolean.No &&
          [
            EmploymentStatus.FullTimeEmployed,
            EmploymentStatus.PartTimeEmployed,
            EmploymentStatus.SelfEmployed,
          ].includes(_employmentStatus) &&
          [
            BusinessRole.BusinessOwner,
            BusinessRole.CompanyDirector,
            BusinessRole.SoleTrader,
          ].includes(businessRole) &&
          industry,
        then: yup
          .string()
          .matches(REGEX_ANYTHING_BUT_WHITESPACE, form.sector.errMsg)
          .required(form.sector.errMsg),
      }),
      ...getBusinessRoleDependantFieldValidations([
        {
          fieldName: 'annualSalary',
          applicableBusinessRoles: [
            BusinessRole.Employee,
            BusinessRole.CompanyDirector,
            BusinessRole.SoleTrader,
          ],
          applicableEmploymentStatuses: [
            EmploymentStatus.FullTimeEmployed,
            EmploymentStatus.PartTimeEmployed,
            EmploymentStatus.SelfEmployed,
          ],
        },
        {
          fieldName: 'annualIncome',
          applicableBusinessRoles: [
            BusinessRole.BusinessOwner,
            BusinessRole.CompanyDirector,
            BusinessRole.SoleTrader,
          ],
        },
      ]),
    }),
  })
}

export default getValidationSchema
