import { flatten } from 'lodash'

import { customCustomerIneligibilityPageTaggingSpecs } from './customerIneligibilityPageTaggingSpecs'
import {
  customProductEligibilityCheckPageTaggingSpecs,
  productEligibilityCheckPageTaggingSpecs,
} from './productEligibilityCheckPageTaggingSpecs'
import {
  childPersonalDetailsPageTaggingSpecs,
  customChildPersonalDetailsPageTaggingSpecs,
} from './childPersonalDetailsPageTaggingSpecs'
import { childContactAndFinancialDetailsPageTaggingSpecs } from './childContactAndFinancialDetailsPageTaggingSpecs'
import {
  accountInformationPageTaggingSpecs,
  customAccountInformationPageTaggingSpecs,
} from './accountInformationPageTaggingSpecs'
import { processingPageTaggingSpecs } from './processingPageTaggingSpecs'
import { applicationReceivedPageTaggingSpecs } from './applicationReceivedPageTaggingSpecs'
import { customApplicationReferredPageTaggingSpecs } from './applicationReferredPageTaggingSpecs'
import { applicationDeclinedIneligibilityPageTaggingSpecs } from './applicationDeclinedIneligibilityPageTaggingSpecs'
import { timeoutPageTaggingSpecs } from './timeoutPageTaggingSpecs'
import { technicalErrorPageTaggingSpecs } from './technicalErrorPageTaggingSpecs'
import { timeoutModalTaggingSpecs } from 'client/components/singletons/Analytics/specs/timeoutModalTaggingSpecs'
import { customApplicationDeclinedPageCommonTaggingSpecs } from 'client/components/singletons/Analytics/specs/applicationDeclinedPageCommonTaggingSpecs'
import { applicationDeclinedInternalPolicyPageTaggingSpecs } from 'client/components/singletons/Analytics/specs/applicationDeclinedInternalPolicyPageTaggingSpecs'

// These are simple tagging specs, which use default piggy event and content function.
// Therefore are tested automatically in ./spec.ts
export const regularTaggingSpecs = flatten([
  productEligibilityCheckPageTaggingSpecs,
  childPersonalDetailsPageTaggingSpecs,
  childContactAndFinancialDetailsPageTaggingSpecs,
  accountInformationPageTaggingSpecs,
  processingPageTaggingSpecs,
  applicationReceivedPageTaggingSpecs,
  timeoutPageTaggingSpecs,
  technicalErrorPageTaggingSpecs,
  applicationDeclinedIneligibilityPageTaggingSpecs,
  applicationDeclinedInternalPolicyPageTaggingSpecs,
  timeoutModalTaggingSpecs,
])

// These are more complex tagging specs, which use custom event fields and/or custom store integration.
// Therefore need custom unit testing.
export const customTaggingSpecs = flatten([
  customProductEligibilityCheckPageTaggingSpecs,
  customApplicationReferredPageTaggingSpecs,
  customAccountInformationPageTaggingSpecs,
  customCustomerIneligibilityPageTaggingSpecs,
  customChildPersonalDetailsPageTaggingSpecs,
  customApplicationDeclinedPageCommonTaggingSpecs,
])

export const taggingSpecs = flatten([customTaggingSpecs, regularTaggingSpecs])
