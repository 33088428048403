import React from 'react'
import Cookies from 'js-cookie'
import { Button, detectBrowser, Heading } from '@piggybank/core'
import { Field, FieldFeedback, Fieldset, Form, Label, NumberInput, Select } from '@piggybank/form'

import { featureConfig, FEATURE_FLAGS_COOKIE_NAME, FeatureState } from 'constants/index'
import { formPages } from 'client/pages'
import { getStore } from 'client/store'
import { ActionName, FeatureFlags, PageKey } from 'enums/index'

import Feature from './Feature'
import validationSchema from './validation'
import profiles from 'stubs/customerProfiles'
import ntpProfiles from 'stubs/ntbProfiles/index'
import styles from './styles.module.css'

const FeatureFlipper = ({ onNavigate }) => {
  const { dispatch, getState } = getStore()
  const { features } = getState()
  const { ie11 } = detectBrowser
  const handleSubmit = (_, { values }) => {
    Cookies.set(FEATURE_FLAGS_COOKIE_NAME, JSON.stringify(values))
    dispatch({
      type: ActionName.UpdateFeatureFlags,
      payload: values,
    })
    if (values.FAKE_CIN.enabled || values.FAKE_NTB_PROFILE.enabled) {
      const payload = {}
      const queryParams = new URLSearchParams(window.location.search)
      if (values.FAKE_CIN.enabled) {
        payload['cin'] = values.FAKE_CIN.value
        queryParams.set('cin', values.FAKE_CIN.value)
      }
      if (values.FAKE_NTB_PROFILE.enabled) {
        payload['ntbProfileId'] = values.FAKE_NTB_PROFILE.value
        queryParams.set('ntbProfileId', values.FAKE_NTB_PROFILE.value)
      }
      dispatch({
        type: ActionName.UpdateFakeProfiles,
        payload,
      })
      window.history.pushState(null, null, '?' + queryParams.toString())
    }
    onNavigate(formPages[PageKey.RootPage].id)
    window.location.reload()
  }
  const cinOptions = profiles.map(data => {
    return {
      value: data.id,
      label: data.name,
    }
  })
  const ntbProfileOptions = ntpProfiles.map(data => {
    return {
      value: data.id,
      label: data.name,
    }
  })
  return (
    <Form
      initialValues={features}
      name={PageKey.FeatureFlags}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values }: FormCallbackProps) => (
        <>
          <>
            <Heading level={1}>Baguette Feature Lab</Heading>
            <Fieldset name="value">
              <Heading level={3}>Enabled app features:</Heading>
              <div className={ie11 ? styles['featureFlagsIE11'] : styles['featureFlags']}>
                {Object.keys(features)
                  .filter(key => key in featureConfig)
                  .sort()
                  .map((key: FeatureFlags) => (
                    <Feature
                      key={key}
                      name={key}
                      description={featureConfig[key].description}
                      sendToApi={featureConfig[key].sendToApi}
                    />
                  ))}
              </div>
              <Heading level={3}>Additional features:</Heading>
              <Field name={`${FeatureFlags.FAKE_CIN}.value`}>
                <Label marginBottom={1}>ETB Customer Profile</Label>
                <Select
                  disabled={!values[FeatureFlags.FAKE_CIN].enabled}
                  options={cinOptions}
                  placeholder={'Please select'}
                />
              </Field>
              <Field name={`${FeatureFlags.FAKE_NTB_PROFILE}.value`}>
                <Label marginBottom={1}>NTB Child Profile</Label>
                <Select
                  disabled={!values[FeatureFlags.FAKE_NTB_PROFILE].enabled}
                  options={ntbProfileOptions}
                  placeholder={'Please select'}
                />
              </Field>
              <Field name={`${FeatureFlags.CUSTOM_TIMEOUT}.value`}>
                <Label marginBottom={1}>Timeout in seconds</Label>
                <NumberInput disabled={!values[FeatureFlags.CUSTOM_TIMEOUT].enabled} />
                <FieldFeedback />
              </Field>
            </Fieldset>
            <Button type="submit" onClick={() => handleSubmit(null, { values })}>
              Apply
            </Button>
          </>
        </>
      )}
    </Form>
  )
}

interface FormCallbackProps {
  values: FeatureState
}

export default FeatureFlipper
