enum PiggyEvent {
  OnClick = 'onClick',
  OnOpen = 'onOpen',
  OnChange = 'onChange',
  Mounted = 'mounted',
  OnBlur = 'onBlur',
  OnAdd = 'onAdd',
  ProductUpdate = 'productUpdate',
  OnIneligibilityModalOpen = 'onIneligibilityModalOpen',
  OnMyMoneyIneligibilityModalOpen = 'onMyMoneyIneligibilityModalOpen',
  OnTimeoutModalOpen = 'onTimeoutModalOpen',
  IneligibleAge = 'ineligibleAge',
}

export default PiggyEvent
