import keys from 'lodash/keys'
import isArray from 'lodash/isArray'

import { T_FormInput } from 'types/index'
import { PageKey, YesNoBoolean, EmploymentStatus } from 'enums/index'

const shouldDelete = value => value === '' || (isArray(value) && value.length === 0)

export default (formInput: T_FormInput) => {
  if (!formInput) {
    return
  }
  if (
    !!formInput[PageKey.ChildContactAndFinancialDetailsPage] &&
    formInput[PageKey.ChildContactAndFinancialDetailsPage].isStudent === YesNoBoolean.Yes
  ) {
    formInput[PageKey.ChildContactAndFinancialDetailsPage].employmentStatus =
      EmploymentStatus.Student
  }

  // for each page
  keys(formInput).forEach((pageKey: PageKey) => {
    // for each field
    keys(formInput[pageKey]).forEach((fieldKey: string) => {
      // when field is object
      if (formInput[pageKey][fieldKey] === undefined) {
        delete formInput[pageKey][fieldKey]
      } else if (Object.getPrototypeOf(formInput[pageKey][fieldKey]) === Object.prototype) {
        // for each object's field
        keys(formInput[pageKey][fieldKey]).forEach((nestedFieldKey: string) => {
          const value = formInput[pageKey][fieldKey][nestedFieldKey]
          // removing if empty string or array
          if (shouldDelete(value)) delete formInput[pageKey][fieldKey][nestedFieldKey]
        })
      } else {
        // when field is not object
        const value = formInput[pageKey][fieldKey]
        // removing if empty string or array
        if (shouldDelete(value)) delete formInput[pageKey][fieldKey]
      }
    })
  })
}
