/* istanbul ignore file */

// IE9, IE10 and IE11 polyfills
import 'core-js/stable'

import render from 'client/render'

// styles
import '@piggybank/core/lib/styles.css'
import '@piggybank/form/lib/styles.css'
import './styles.module.css'

// setting visibility to visible only when content loaded
// to prevent flicker happening in local dev with ssr on
window.addEventListener('load', () => (document.getElementById('App').style.visibility = 'visible'))

// rendering App
render()
