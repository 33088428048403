import yup from 'client/validation'
import dict from './dictionary'
import { YesNoBoolean } from 'enums/index'
import { NAME_MIN_LENGTH } from 'constants/customer'
import { REGEX_NAME } from 'src/regex'

export const ChildNamesSectionValidationSchema = {
  title: yup.string().required(dict.title.validation.required),
  firstNames: yup
    .string()
    .matches(REGEX_NAME, dict.firstNames.validation.invalidCharacters)
    .min(NAME_MIN_LENGTH, dict.firstNames.validation.minLength)
    .required(dict.firstNames.validation.required),
  familyName: yup
    .string()
    .matches(REGEX_NAME, dict.familyName.validation.invalidCharacters)
    .min(NAME_MIN_LENGTH, dict.familyName.validation.minLength)
    .required(dict.familyName.validation.required),
  hasPreviousNames: yup.string().required(dict.hasPreviousNames.validation.required),
  previousFirstNames: yup.string().when('hasPreviousNames', {
    is: YesNoBoolean.Yes,
    then: yup
      .string()
      .matches(REGEX_NAME, dict.previousFirstNames.validation.invalidCharacters)
      .min(NAME_MIN_LENGTH, dict.previousFirstNames.validation.minLength)
      .required(dict.previousFirstNames.validation.required),
  }),
  previousFamilyName: yup.string().when('hasPreviousNames', {
    is: YesNoBoolean.Yes,
    then: yup
      .string()
      .matches(REGEX_NAME, dict.previousFamilyName.validation.invalidCharacters)
      .min(NAME_MIN_LENGTH, dict.previousFamilyName.validation.minLength)
      .required(dict.previousFamilyName.validation.required),
  }),
}
