const waitingForTealiumInterval = 3000;
const checkingInterval = 100;

export const waitForTealium = () =>
  new Promise<void>((resolve, reject) => {
    const tms = window.TMS;
    if (tms) {
      resolve();
    } else {
      let timePassed = 0;
      const interval = setInterval(() => {
        timePassed += checkingInterval;
        if (timePassed >= waitingForTealiumInterval) {
          clearInterval(interval);
          reject();
        }
        const tms = window.TMS;
        if (tms) {
          clearInterval(interval);
          resolve();
        }
      }, checkingInterval);
    }
  });

export const getUtagScript = ({
  noview = false,
  account,
  profile,
  environment,
}: {
  noview?: boolean;
  account: string;
  profile: string;
  environment: string;
}) => {
  if (!account || !profile || !environment) {
    return '';
  }

  return `<script src="https://tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.sync.js"></script>
  <script>
    ${noview ? 'window.utag_cfg_ovrd = { noview: true };' : ''}
    (function (a, b, c, d){
      a='//tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.js';
      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d, a);
    })();
  </script>`;
};

export const trackView = object => {
  return waitForTealium()
    .then(() => {
      const tms = window.TMS;
      return tms.trackView(object);
    })
    .catch(() => {
      console.warn('TMS is not available'); // eslint-disable-line no-console
    });
};

export const trackEvent = object => {
  return waitForTealium()
    .then(() => {
      const tms = window.TMS;
      return tms.trackEvent(object);
    })
    .catch(() => {
      console.warn('TMS is not available'); // eslint-disable-line no-console
    });
};
