import Product from 'enums/Product'

const countryOfInitialDepositRequiredValidation =
  'Please select the country or region the initial deposit will come from'

export default {
  pageIntroductionSection: {
    mainHeading: 'Account information',
    mainParagraph:
      'We need you to tell us how your child will use their account, and read some important documents.',
  },
  debitCardSection: {
    heading: 'Debit card',
    paragraph1: "We'll send a contactless Visa debit card for your child to use.",
    paragraph2: 'They can opt out in branch, or by calling our customer service centre.',
  },
  accountUsageSection: {
    mainHeading: 'Initial deposit',
    regularPaymentSource: {
      label: 'Where will regular payments into this account come from?',
      validation: {
        required: 'Please select where regular payments into this account will come from',
      },
    },
    regularPaymentCashSource: {
      label: 'Where will this cash come from?',
      validation: {
        required: 'Please tell us where this cash will come from',
      },
    },
    firstDepositSource: {
      label: 'Where will the first deposit come from?',
      validation: {
        required: 'Please select where the first deposit will come from',
      },
    },
    firstDepositCashSource: {
      label: 'Where will this cash come from?',
      validation: {
        required: 'Please tell us where this cash will come from',
      },
    },
    countriesOfInitialDeposit: {
      country: {
        label: 'Which country or region will the initial deposit come from?',
        validation: {
          required: countryOfInitialDepositRequiredValidation,
        },
      },
      additionalCountry: {
        label: 'Country or region',
        validation: {
          required: countryOfInitialDepositRequiredValidation,
        },
      },
      addAnother: {
        label: 'Add another country or region',
      },
      remove: {
        label: 'Remove',
      },
    },
    initialDepositImportantInformation: {
      [Product.MY_MONEY]:
        'A minimum of £1 must be deposited to open a MyAccount and £10 for MySavings account.\n' +
        'There is no maximum balance.',
      [Product.PREMIER_MY_MONEY]:
        'A minimum of £1 must be deposited to open a Premier MyAccount and £10 for a Premier MySavings account.\n' +
        'There is no maximum balance.',
      [Product.MY_SAVINGS]:
        'A minimum of £10 must be deposited to open a MySavings account.\n' +
        'There is no maximum balance.',
      [Product.PREMIER_MY_SAVINGS]:
        'A minimum of £10 must be deposited to open a Premier MySavings account.\n' +
        'There is no maximum balance.',
    },
    initialDeposit: {
      label: 'Approximately how much will the initial deposit be?',
      validation: {
        required: 'Please enter the approximate initial deposit amount',
        toSmall: 'Please enter the approximate initial deposit amount', // TODO: Update the validation message once confirmed
      },
    },
  },
  accountOpeningConsent: {
    checkbox1:
      "The information you've provided in this application is accurate to the best of your knowledge and belief.",
    checkbox2:
      "You have read and agree to the Terms and Conditions and Charges on your child's behalf and are aware of the Notice of Variation.",
    checkbox3: "You agree to the account terms and conditions on your child's behalf.",
    checkbox4:
      "You and your child agree to the Authorised Adult Mandate, and as 'Authorised Adult' you agree to operate the account in accordance with the account terms and conditions.",
    validation: {
      msg1:
        "Please select the checkbox the information you've provided in this application is accurate to the best of your knowledge and belief.",
      msg2:
        "Please select the checkbox You agree to the account terms and conditions on your child's behalf.",
      msg3:
        "Please select the checkbox you and your child agree to the Authorised Adult Mandate, and as 'Authorised Adult' you agree to operate the account in accordance with the account terms and conditions.",
    },
  },
  navigation: {
    previous: 'Back',
    next: 'Submit your application',
  },
}
