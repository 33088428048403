import yup from 'client/validation'
import { PageKey } from 'enums/index'

import dict from './dictionary'

export default yup.object().shape({
  [PageKey.ProductEligibilityCheckPage]: yup.object().shape({
    dateOfBirth: yup.string().noFutureDateValidation(dict.dobSection.errMsg),
  }),
})
