export const LEGAL_ADULTHOOD_AGE = 18
export const CHILD_ELIGIBLE_MIN_AGE = 7
export const CHILD_ELIGIBLE_MAX_AGE = LEGAL_ADULTHOOD_AGE - 1
export const REQUIRED_CHILD_ADDRESS_HISTORY_IN_YEARS = 3
export const EXTRA_COUNTRY_OR_JURISDICTION_MAX = 2

export const DATE_OF_BIRTH_FORMAT = 'YYYY-MM-DD'
export const MOVE_IN_DATE_FORMAT = 'YYYY-MM'
export const PREVIOUS_ADDRESS_FORMAT = 'MMMM YYYY'
export const MAX_NUMBER_OF_ADDITIONAL_NATIONALITIES = 2
export const NAME_MIN_LENGTH = 2
export const NAME_MAX_LENGTH = 30
export const FIRST_NAMES_MAX_LENGTH = 60
export const FAMILY_NAME_MAX_LENGTH = 40
export const CITY_MAX_LENGTH = 33

export const BUILDING_MIN_LENGTH = 1
export const BUILDING_MAX_LENGTH = 33
export const STREET_MIN_LENGTH = 1
export const STREET_MAX_LENGTH = 33
export const TOWN_MIN_LENGTH = 1
export const TOWN_MAX_LENGTH = 33
export const NON_UK_POSTCODE_MAX_LENGTH = 7

export const EMAIL_MAX_LENGTH = 100
export const MAX_NUMBER_OF_ADDITIONAL_INITIAL_DEPOSIT_COUNTRIES = 2
export const MIN_INITIAL_DEPOSIT = 10
export const MAX_OCCUPATION_LENGTH = 100
export const MAX_EARNINGS_LENGTH = 11
export const MAX_BUSINESS_NAME_LENGTH = 120
export const MAX_EDUCATIONAL_ESTABLISHMENT_LENGTH = 50

export const MAX_INITIAL_DEPOSIT_LENGTH = 11

export const MAX_TAX_ID_OR_REF_NUMBER_LENGTH = 30
