import {
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getApplicationId, getCommonTags, getPageTags } from 'client/helpers'
import { returnToOnlineBankingFullLinkLabel } from 'client/pages/ApplicationReceivedPage'
import { T_TaggingSpecs } from 'types/index'

const pageKey = PageKey.ApplicationReceivedPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const applicationReceivedPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      ...getApplicationId(),
      application_decision: 'submit-complete',
      application_event: 'completed',
      funnel_step: '6',
      raw_datalayer: '3406v52',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on return to online banking button.`,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Button,
      text: returnToOnlineBankingFullLinkLabel,
    },
    content: () => ({
      ...getAllCommonTags(),
      ...getApplicationId(),
      event_action: TealiumEventAction.Button,
      event_category: TealiumEventCategory.Content,
      event_content: 'return to online banking',
      raw_datalayer: '6067v1',
    }),
  },
]
