import { template } from 'lodash'

import { DateValidationMethod, Status, YesNoBoolean } from 'enums/index'
import {
  DATE_OF_BIRTH_FORMAT,
  MOVE_IN_DATE_FORMAT,
  PREVIOUS_ADDRESS_FORMAT,
} from 'constants/customer'

const moment = require('moment')

export const isValid = (
  validationType: DateValidationMethod,
  value: any,
  skipDaysCheck?: boolean
) => {
  const isEmpty = !value
  const isIncompleteOrInvalid = [Status.Incomplete, Status.Invalid].includes(value)
  return (
    !isEmpty &&
    !isIncompleteOrInvalid &&
    ((validationType === DateValidationMethod.NoFuture && !moment().isBefore(value)) ||
      (validationType === DateValidationMethod.isCurrentOrFuture &&
        !moment().isAfter(value, skipDaysCheck ? 'month' : '')))
  )
}

export function getIsValidMethod(
  validationType: DateValidationMethod,
  errMsg: string,
  skipDaysCheck?: boolean
) {
  return function() {
    const fieldName = this.path.split('.').reverse()[0]
    const value = this.parent[fieldName]
    return isValid(validationType, value, skipDaysCheck) || this.createError({ message: errMsg })
  }
}

export const noFutureDateValidation = function(errMsg: string) {
  return this.test({
    name: 'noFutureDateValidation',
    test: getIsValidMethod(DateValidationMethod.NoFuture, errMsg),
  })
}

export const isCurrentOrFutureDateValidation = function(errMsg: string) {
  return this.test({
    name: 'isCurrentOrFutureDateValidation',
    test: getIsValidMethod(DateValidationMethod.isCurrentOrFuture, errMsg, true),
  })
}

export const isSameMonthOrAfterDateValidation = function(message: string, date: string) {
  return this.test({
    name: 'isSameMonthOrAfterDateValidation',
    message,
    test: value => {
      const thisMoment = moment(value, MOVE_IN_DATE_FORMAT)
      const otherMoment = moment(date, DATE_OF_BIRTH_FORMAT)

      return !value || moment(thisMoment).isSameOrAfter(otherMoment, 'month')
    },
  })
}

export const isAddressMoveInDateInOrderValidation = function(messageTemplate: string) {
  return this.test({
    name: 'isAddressMoveInDateInOrderValidation',
    test(thisMoveInDate) {
      const thisMoveInDateAddressIndex = parseInt(this.path.split('[')[1].split(']')[0], 10)
      const formRoot = this.from[1].value

      let newestPossibleDate
      if (thisMoveInDateAddressIndex === 0) {
        if (this.from[1].value.doesLiveAt === YesNoBoolean.Yes) {
          newestPossibleDate = formRoot.whenDidStartLivingHere
        } else {
          newestPossibleDate = formRoot.newCurrentAddress.moveInDate
        }
      } else {
        newestPossibleDate = formRoot.previousAddresses[thisMoveInDateAddressIndex - 1].moveInDate
      }

      const newestPossibleDateMoment = moment(newestPossibleDate, MOVE_IN_DATE_FORMAT)

      return moment(thisMoveInDate, MOVE_IN_DATE_FORMAT).isSameOrBefore(
        newestPossibleDateMoment,
        'month'
      )
        ? true
        : this.createError({
            message: template(messageTemplate)({
              date: newestPossibleDateMoment.format(PREVIOUS_ADDRESS_FORMAT),
            }),
          })
    },
  })
}
