import React from 'react'
import { Provider } from 'react-redux'
import { Router, StaticRouter } from 'react-router-dom'

import App from 'client/components/singletons/App'

export default (history, store) => {
  if (history) {
    return (
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    )
  } else {
    return (
      <Provider store={store}>
        <StaticRouter>
          <App />
        </StaticRouter>
      </Provider>
    )
  }
}
