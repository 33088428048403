import { PageKey, YesNoBoolean } from 'enums/index'
import { T_RemoteData, T_FormInput } from 'types/index'
import { T_AddressDetails } from 'types/data/local/submissionPayload'

export default (remoteData: T_RemoteData, formInput: T_FormInput): T_AddressDetails => {
  const { doesLiveAt, whenDidStartLivingHere, newCurrentAddress } = formInput[
    PageKey.ChildContactAndFinancialDetailsPage
  ]

  if (doesLiveAt === YesNoBoolean.Yes) {
    const {
      postCode,
      line1,
      line2,
      line3,
      line4,
      line5,
      line6,
    } = remoteData.initialResponse.currentAddress

    return {
      dateMovedIn: whenDidStartLivingHere + '-01',
      country: 'GB',
      line1: line1.trim(),
      line2: line2 && line2.trim(),
      line3: line3 && line3.trim(),
      line4: line4 && line4.trim(),
      line5: line5 && line5.trim(),
      line6: line6 && line6.trim(),
      postCode: postCode && postCode.trim(),
    }
  } else {
    const {
      moveInDate,
      countryOrRegion,
      building,
      street,
      townOrCity,
      postCode,
    } = newCurrentAddress

    return {
      dateMovedIn: moveInDate + '-01',
      country: countryOrRegion,
      line1: building.trim(),
      line3: street.trim(),
      line5: townOrCity.trim(),
      postCode: postCode.trim(),
    }
  }
}
