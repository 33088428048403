import { PageKey, YesNoBoolean, MoneySource } from 'enums/index'
import { T_FormInput } from 'types/form'
import { getRandomLetters, getRandomEmail } from './../helpers'

export default {
  [PageKey.ProductEligibilityCheckPage]: {
    dateOfBirth: '2008-08-02',
  },
  [PageKey.ChildPersonalDetailsPage]: {
    title: 'MR',
    firstNames: 'Jeff',
    familyName: 'Jefferson-' + getRandomLetters(),
    hasPreviousNames: 'no',
    gender: 'MALE',
    dateOfBirth: '2008-08-02',
    cityOfPermanentResidence: 'London',
  },
  [PageKey.ChildContactAndFinancialDetailsPage]: {
    doesLiveAt: YesNoBoolean.Yes,
    whenDidStartLivingHere: '2008-08',
    phoneNumber: {
      countryCode: 'GB',
      raw: '7841234453',
      value: `+447841234453`,
    },
    email: getRandomEmail(),
    isStudent: YesNoBoolean.Yes,
    institutionName: 'Biker Grove',
  },
  [PageKey.AccountInformationPage]: {
    regularPaymentSource: MoneySource.TransferFromFamily,
    firstDepositSource: MoneySource.TransferFromFamily,
    initialDeposit: '100',
    marketingPreferences: ['TELEPHONE', 'SMS', 'EMAIL', 'LETTER'],
    accountOpeningConsent1: true,
    accountOpeningConsent2: true,
    accountOpeningConsent3: true,
  },
} as T_FormInput
