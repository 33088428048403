export default [
  {
    id: '1121841430',
    name: 'IB1121841430 MASS',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: true,
        availableProducts: ['MY_SAVINGS', 'MY_MONEY'],
        personalDetails: {
          firstNames: 'DON',
        },
        currentAddress: {
          line1: '6',
          line2: 'Hawthorn Avenue',
          line4: 'Sawston',
          line5: 'Cambridge',
          postCode: 'CB22 3TE',
          country: 'GB',
          addressType: 'UK',
        },
        contactDetails: {
          mobileTelephoneCode: '07550',
          mobileTelephoneNumber: '876943',
          emailAddress: 'environments.is@hsbc.com',
        },
      },
    },
  },
  {
    id: '1821852656',
    name: 'IB1821852656 ADVANCE',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: true,
        availableProducts: ['MY_SAVINGS', 'MY_MONEY'],
        personalDetails: {
          firstNames: 'DIRK',
        },
        currentAddress: {
          line1: '6',
          line2: 'Hawthorn Avenue',
          line4: 'Sawston',
          line5: 'Cambridge',
          postCode: 'CB22 3TE',
          country: 'GB',
          addressType: 'UK',
        },
        contactDetails: {
          mobileTelephoneCode: '07700',
          mobileTelephoneNumber: '900000',
        },
      },
    },
  },
  {
    id: '1121843751',
    name: 'IB1121843751 PREMIER',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: true,
        availableProducts: ['PREMIER_MY_SAVINGS', 'PREMIER_MY_MONEY'],
        personalDetails: {
          firstNames: 'DEREK ONE',
        },
        currentAddress: {
          line1: '6',
          line2: 'Hawthorn avenuE',
          line4: 'sawston',
          line5: 'CAMBRIDGE',
          postCode: 'ab22 3TE',
          country: 'GB',
          addressType: 'UK',
        },
        contactDetails: {
          mobileTelephoneCode: '07700',
          mobileTelephoneNumber: '900000',
          emailAddress: 'no-reply@hsbc.com',
        },
      },
    },
  },
  {
    id: '1221843885',
    name: '1221843885 MISSING_PHONE',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: true,
        availableProducts: ['MY_MONEY', 'MY_SAVINGS'],
        personalDetails: {
          firstNames: 'MUHAMMED',
        },
        currentAddress: {
          line1: '6',
          line2: 'Hawthorn Avenue',
          line4: 'Sawston',
          line5: 'Cambridge',
          postCode: 'CB22 3TE',
          country: 'GB',
          addressType: 'UK',
        },
      },
    },
  },
  {
    id: '1421846543',
    name: 'IB1421846543 UNDER_18',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: false,
        ineligibleReason: 'UNDER_18',
      },
    },
  },
  {
    id: '1421117614',
    name: 'IB1421117614 NON_UK_ADDRESS',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: false,
        ineligibleReason: 'NON_UK_ADDRESS',
      },
    },
  },
  {
    id: '1821847849',
    name: 'IB1821847849 DOES_NOT_HAVE_REQUIRED_ACCOUNT',
    eligibilityResponse: {
      status: 200,
      body: {
        eligible: false,
        ineligibleReason: 'DOES_NOT_HAVE_REQUIRED_ACCOUNT',
      },
    },
  },
  {
    id: '1921843926',
    name: 'IB1921843926 CD030_CALL_FAILED',
    eligibilityResponse: {
      status: 500,
      body: {
        errorInfo: [
          {
            code: '500',
            causes: ['Retrieve Customer Details call failed'],
            source: 'Youth API',
            errorStack: [
              {
                code: '500',
                causes: ['Retrieve Customer Details call failed'],
                source: 'Customer Details DAPI',
                errorStack: [
                  {
                    code: '403',
                    causes: ['Forbidden'],
                    source: 'CD030 SAPI',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    id: '1221067206',
    name: 'IB1221067206 HBPA_CALL_FAILED',
    eligibilityResponse: {
      status: 500,
      body: {
        errorInfo: [
          {
            code: '500',
            causes: ['Retrieve Customer Accounts call failed'],
            source: 'Youth API',
            errorStack: [
              {
                code: '500',
                causes: ['HBPA ZAPI call encountered a problem'],
                source: 'Accounts DAPI',
                errorStack: [
                  {
                    code: 'Z01',
                    source: 'RPS',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
]
