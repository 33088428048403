enum Env {
  Test = 'test',
  Prod = 'production',
  Dev = 'development',
  RemoteProd = 'remote-prod',
  RemoteCert = 'remote-cert',
  RemoteDev = 'remote-dev',
  Local = 'local',
}

export default Env
