import { PageKey, PiggyEvent, TealiumComponent, TealiumPageType } from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { T_TaggingSpecs } from 'types/index'

const pageKey = PageKey.ProcessingPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const processingPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      funnel_step: '5',
      raw_datalayer: '3406v51',
    }),
  },
]
