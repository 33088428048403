import { PageKey } from 'enums/index'

export const PAGE_TAGS = {
  [PageKey.CustomerIneligibilityPage]: {
    funnel_step_name: 'ineligible page',
    page_name: 'ib:my money:youth bank account:ineligible page',
    page_url: '/my-money/youth-bank-account/ineligible-page',
  },
  [PageKey.AccountInformationPage]: {
    funnel_step_name: 'account information',
    page_name: 'ib:my money:youth bank account:account information',
    page_url: '/my-money/youth-bank-account/account-information',
  },
  [PageKey.ApplicationReferredPage]: {
    funnel_step_name: 'application referred',
    page_name: 'ib:my money:youth bank account:application referred',
    page_url: '/my-money/youth-bank-account/application-referred',
  },
  [PageKey.ApplicationReceivedPage]: {
    funnel_step_name: 'application submitted',
    page_name: 'ib:my money:youth bank account:application submitted',
    page_url: '/my-money/youth-bank-account/application-submitted',
  },
  [PageKey.ChildContactAndFinancialDetailsPage]: {
    funnel_step_name: 'child contact and financial details',
    page_name: 'ib:my money:youth bank account:child contact and financial details',
    page_url: '/my-money/youth-bank-account/child-contact-and-financial-details',
  },
  [PageKey.ChildPersonalDetailsPage]: {
    funnel_step_name: 'child personal details',
    page_name: 'ib:my money:youth bank account:child personal details',
    page_url: '/my-money/youth-bank-account/child-personal-details',
  },
  [PageKey.ProcessingPage]: {
    funnel_step_name: 'processing page',
    page_name: 'ib:my money:youth bank account:processing page',
    page_url: '/my-money/youth-bank-account/processing-page',
  },
  [PageKey.ProductEligibilityCheckPage]: {
    funnel_step_name: 'landing page',
    page_name: 'ib:my money:youth bank account:landing page',
    page_url: '/my-money/youth-bank-account/landing-page',
  },
  [PageKey.TechnicalErrorPage]: {
    funnel_step_name: 'error page',
    page_name: 'ib:my money:youth bank account:error page',
    page_url: '/my-money/youth-bank-account/error-page',
  },
  [PageKey.TimeoutPage]: {
    funnel_step_name: 'application',
    page_name: 'ib:my money:youth bank account:timeout',
    page_url: '/my-money/youth-bank-account/timeout',
  },
  [PageKey.ApplicationDeclinedIneligibilityPage]: {
    funnel_step_name: 'decline - ineligible',
    page_name: 'ib:my money:youth bank account:decline:ineligibility',
    page_url: '/my-money/youth-bank-account/decline/ineligibility',
  },
  [PageKey.ApplicationDeclinedInternalPolicyPage]: {
    funnel_step_name: 'decline - internal policy',
    page_name: 'ib:my money:youth bank account:decline:internal policy',
    page_url: '/my-money/youth-bank-account/decline/internal-policy',
  },
}
