const firstNamesHint =
  "As they appear on your child's identity documents including any middle names."
const familyNameHint = "As it appears on your child's identity documents."

export default {
  title: {
    label: 'Title',
    validation: {
      required: "Please select your child's title",
    },
  },
  firstNames: {
    label: 'First names',
    hint: firstNamesHint,
    validation: {
      required: "Please enter your child's first names",
      minLength: "Your child's first names and middle names need at least 2 characters",
      invalidCharacters:
        "Please enter your child's first names and middle names using only the characters a-z, - and '",
    },
  },
  familyName: {
    label: 'Surname / family name',
    hint: familyNameHint,
    validation: {
      required: "Please enter your child's last name",
      minLength: "Your child's surname / family name needs at least 2 characters",
      invalidCharacters:
        "Please enter your child's surname / family name using only the characters a-z, - and '",
    },
  },
  hasPreviousNames: {
    label: 'Has your child had any previous names?',
    validation: {
      required: 'Please tell us if your child has ever had any previous names',
    },
  },
  previousFirstNames: {
    label: 'Previous first names',
    hint: firstNamesHint,
    validation: {
      required: "Please enter your child's previous first names",
      minLength: "Your child's previous first names and middle names need at least 2 characters",
      invalidCharacters:
        "Please enter your child's previous first names and previous middle names using only the characters a-z, - and '",
    },
  },
  previousFamilyName: {
    label: 'Previous surname / family name',
    hint: familyNameHint,
    validation: {
      required: "Please enter your child's previous surname / family name",
      minLength: "Your child's previous surname / family name needs at least 2 character",
      invalidCharacters:
        "Please enter your child's previous surname / family name using only the characters a-z, - and '",
    },
  },
}
