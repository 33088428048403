import React, { ReactElement } from 'react'
import { VisuallyHidden, Section } from '@piggybank/core'
import { AddAnother, Field, Label, Hint, Select, FieldFeedback } from '@piggybank/form'
import { isEmpty, some } from 'lodash'

import { countries } from 'src/lookups'

type T_Props = {
  dict: {
    country: {
      label: string
      hint?: string
    }
    additionalCountry: {
      label: string
    }
    addAnother: {
      label: string
    }
    remove: {
      label: string
    }
  }
  countryFieldName: string
  additionalCountriesFieldName: string
  country: string
  additionalCountries: any[]
  maxNumberOfAdditionalCountries: number
  mainCountrySlot?: null | ReactElement
  additionalCountriesSlot?: null | ((path: string, index: number) => ReactElement)
  additionalCountryFieldName?: string
  initialItemValue?: any
}

const CountrySelection = ({
  dict,
  countryFieldName,
  additionalCountriesFieldName,
  country,
  additionalCountries = [],
  maxNumberOfAdditionalCountries,
  mainCountrySlot = null,
  additionalCountriesSlot = null,
  additionalCountryFieldName,
  initialItemValue = '',
}: T_Props) => {
  const additionalCountriesValues = additionalCountriesSlot
    ? additionalCountries.map(c => c[additionalCountryFieldName])
    : additionalCountries

  const getSelectableAdditionalCountries = index =>
    countries.filter(({ value }) => {
      const isCurrentValue = additionalCountriesValues[index] === value
      const isSelectedElsewhere = country !== value && !additionalCountriesValues.includes(value)

      return isCurrentValue || isSelectedElsewhere
    })

  const selectableCountries = countries.filter(
    ({ value }) => !additionalCountriesValues.includes(value)
  )

  const selectableAdditionalCountries = additionalCountriesValues.map((_, index) =>
    getSelectableAdditionalCountries(index)
  )

  const addAnotherButtonVisible = !isEmpty(country) && !some(additionalCountriesValues, isEmpty)

  return (
    <Section marginBottom={5}>
      <>
        <Section marginBottom={0}>
          <Field marginBottom={0} name={countryFieldName}>
            <Label marginBottom={dict.country.hint ? 1 : 2}>{dict.country.label}</Label>
            {dict.country.hint && <Hint>{dict.country.hint}</Hint>}
            <Select options={selectableCountries} />
            <FieldFeedback />
          </Field>
        </Section>
        {mainCountrySlot}
      </>
      <AddAnother
        showAdd={addAnotherButtonVisible}
        name={additionalCountriesFieldName}
        itemInitialValue={initialItemValue}
        min={0}
        max={maxNumberOfAdditionalCountries}
        renderAddButtonText={({ length }) => (
          <>
            {dict.addAnother.label}
            <VisuallyHidden>{length + 1}</VisuallyHidden>
          </>
        )}
        renderRemoveButtonText={({ index }) => (
          <>
            {dict.remove.label}
            <VisuallyHidden>
              {dict.remove.label} {index + 1}
            </VisuallyHidden>
          </>
        )}
      >
        {({ path, index }) => (
          <>
            <Section marginBottom={0}>
              <Field
                marginBottom={0}
                name={additionalCountriesSlot ? `${path}.${additionalCountryFieldName}` : path}
              >
                <Label>{dict.additionalCountry.label}</Label>
                <Select options={selectableAdditionalCountries[index]} />
                <FieldFeedback />
              </Field>
            </Section>
            {additionalCountriesSlot && additionalCountriesSlot(path, index)}
          </>
        )}
      </AddAnother>
    </Section>
  )
}

export default CountrySelection
