import React, { Reducer, createContext, useReducer, Dispatch } from 'react'
import { identity } from 'lodash/fp'

import { getStore } from 'client/store'
import { fetchData, IFetchAttributes, Method } from 'client/utils/fetch'
import { defaultReducer } from 'client/utils/defaultReducer'
import { AUTH_TOKEN_HEADER } from 'constants/app'
import { getFeaturesHeaders } from 'constants/features'
import {
  GET_CUSTOMER_NODE_PATH,
  GET_EXTENDED_AUTH_TOKEN_NODE_PATH,
  POST_APPLICATION_NODE_PATH,
} from 'constants/urlPaths'

type Endpoint = (...args: any[]) => Promise<any>

export interface RequestStatus {
  uid: string
  maxAttempts: number
  attempt: number
  limitExceeded?: boolean
}

export type Endpoints = Record<string, Endpoint>
export type Requests = Record<string, RequestStatus>

export interface InitialState {
  service: Endpoints
  requests: Requests
}

export type ServiceStore = [InitialState, Dispatch<Partial<InitialState>>]

let commonHeaders

const getCommonHeaders = () => {
  let headers = {}
  const {
    authorization: { token },
    environment: { FEATURES_OVERRIDES },
    features,
  } = getStore().getState()
  if (token) headers[AUTH_TOKEN_HEADER] = token
  if (FEATURES_OVERRIDES === 'YES') {
    headers = {
      ...headers,
      ...getFeaturesHeaders(features, true),
    }
  }
  return headers
}

const makeCall = (url: string, options: IFetchAttributes) =>
  fetchData(url, options)
    .then((res: Response) => res.text())
    .then(text => (text.length ? JSON.parse(text) : {}))

const endpoints = {
  extendSession: () =>
    makeCall(GET_EXTENDED_AUTH_TOKEN_NODE_PATH, {
      method: Method.GET,
      headers: commonHeaders,
    }),
  checkEligibility: () =>
    makeCall(GET_CUSTOMER_NODE_PATH, { method: Method.GET, headers: commonHeaders }),
  postApplication: payload =>
    makeCall(POST_APPLICATION_NODE_PATH, { method: Method.POST, headers: commonHeaders, payload }),
}

const initialState: InitialState = {
  service: endpoints,
  requests: {} as Requests,
}

const ServiceContext = createContext<ServiceStore>([initialState, identity])

export const useService = (): ServiceStore =>
  useReducer<Reducer<InitialState, Partial<InitialState>>>(defaultReducer, initialState)

export const ServiceProvider: React.FC = ({ children }) => {
  commonHeaders = getCommonHeaders()

  return (
    <ServiceContext.Provider value={useService()}>
      {React.Children.only(children)}
    </ServiceContext.Provider>
  )
}
