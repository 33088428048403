export default {
  title: 'Need more time?',
  content: [
    'For your security, your application will shortly timeout due to inactivity.',
    "If it times out, any personal details you've entered will be removed.",
  ],
  timeRemaining: ['Time remaining:', 'seconds'],
  buttonLabel: 'Continue application',
  buttonLabelRetrying: 'Please wait...',
}
