import {
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumErrorType,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { T_TaggingSpecs } from 'types/index'

const pageKey = PageKey.TechnicalErrorPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Error,
})

export const technicalErrorPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      error_type: TealiumErrorType.System,
      event_action: TealiumEventAction.System,
      event_category: TealiumEventCategory.Error,
      event_content: 'something went wrong',
      raw_datalayer: '3406v65',
    }),
  },
]
