import React, { useCallback, useEffect, useState } from 'react'
import { Heading, Section } from '@piggybank/core'
import { some } from 'lodash'

import { Field, FieldFeedback, YesNoRadio, Label, Legend, TextInput, Select } from '@piggybank/form'

import replaceApostrophes from 'client/utils/replaceApostrophes'
import {
  EMPLOYMENT_STATUSES,
  SELF_EMPLOYED_BUSINESS_ROLES,
  FULL_OR_PART_TIME_EMPLOYED_BUSINESS_ROLES,
  MAX_EDUCATIONAL_ESTABLISHMENT_LENGTH,
} from 'constants/index'
import { YesNoBoolean, EmploymentStatus, BusinessRole, PageKey } from 'enums/index'

import { EmployeeFinancialDetails } from './EmployeeFinancialDetails'
import { BusinessOwnerFinancialDetails } from './BusinessOwnerFinancialDetails'
import { SoleTraderFinancialDetails } from './SoleTraderFinancialDetails'
import { CompanyDirectorFinancialDetails } from './CompanyDirectorFinancialDetails'

import dict from '../dictionary'
import { staticChildContactAndFinancialDetailsInitialValues } from '../getInitialValues'

const { heading, form } = dict.childFinancialDetailsSection

export const isYourChildStudentFieldName = `${PageKey.ChildContactAndFinancialDetailsPage}.isStudent`
export const childEmploymentStatusFieldName = `${PageKey.ChildContactAndFinancialDetailsPage}.employmentStatus`
export const businessRoleFieldName = `${PageKey.ChildContactAndFinancialDetailsPage}.businessRole`

const ChildFinancialDetails = ({
  values,
  pageKey,
  isStudent,
  employmentStatus,
  businessRole,
  businessType,
  industry,
  setFieldValue,
}) => {
  const getBusinessRoles = useCallback(() => {
    if (employmentStatus === EmploymentStatus.SelfEmployed) {
      return SELF_EMPLOYED_BUSINESS_ROLES
    } else if (
      [EmploymentStatus.FullTimeEmployed, EmploymentStatus.PartTimeEmployed].includes(
        employmentStatus
      )
    ) {
      return FULL_OR_PART_TIME_EMPLOYED_BUSINESS_ROLES
    } else {
      return []
    }
  }, [employmentStatus])

  const [currentBusinessRoles, setCurrentBusinessRoles] = useState(getBusinessRoles())

  const resetBusinessRole = useCallback(() => {
    const newBusinessRoles = getBusinessRoles()
    if (!some(newBusinessRoles, ['value', businessRole])) {
      setFieldValue(
        businessRoleFieldName,
        staticChildContactAndFinancialDetailsInitialValues.businessRole
      )
    }

    setCurrentBusinessRoles(newBusinessRoles)
  }, [businessRole, getBusinessRoles, setFieldValue])
  useEffect(resetBusinessRole, [employmentStatus])

  return (
    <>
      <Heading level={2} accentBar={true}>
        {heading}
      </Heading>
      <Field name={isYourChildStudentFieldName} marginBottom={4}>
        <Legend marginBottom={1}>{form.isStudent.label}</Legend>
        <YesNoRadio />
        <FieldFeedback />
      </Field>
      {isStudent === YesNoBoolean.Yes && (
        <Section>
          <Field
            name={`${pageKey}.institutionName`}
            onChange={(next, rest) => {
              rest.value = replaceApostrophes(rest.value)
              next(rest)
            }}
            onBlur={(next, rest) => {
              values.institutionName = values.institutionName.trim()
              next(rest)
            }}
          >
            <Label>{form.institutionName.label}</Label>
            <TextInput maxLength={MAX_EDUCATIONAL_ESTABLISHMENT_LENGTH} />
            <FieldFeedback />
          </Field>
        </Section>
      )}
      {isStudent === YesNoBoolean.No && (
        <Section>
          <Field name={childEmploymentStatusFieldName}>
            <Label>{form.employmentStatus.label}</Label>
            <Select options={EMPLOYMENT_STATUSES} />
            <FieldFeedback />
          </Field>
          {!!employmentStatus && employmentStatus !== EmploymentStatus.Unemployed && (
            <Section>
              <Field name={businessRoleFieldName}>
                <Label>{form.businessRole.label}</Label>
                <Select options={currentBusinessRoles} />
                <FieldFeedback />
              </Field>
              {businessRole === BusinessRole.Employee && (
                <EmployeeFinancialDetails
                  values={values}
                  pageKey={pageKey}
                  employmentStatus={employmentStatus}
                />
              )}
              {businessRole === BusinessRole.BusinessOwner && (
                <BusinessOwnerFinancialDetails
                  values={values}
                  pageKey={pageKey}
                  businessType={businessType}
                  industry={industry}
                  setFieldValue={setFieldValue}
                />
              )}
              {businessRole === BusinessRole.SoleTrader && (
                <SoleTraderFinancialDetails
                  values={values}
                  pageKey={pageKey}
                  businessType={businessType}
                  industry={industry}
                  setFieldValue={setFieldValue}
                />
              )}
              {businessRole === BusinessRole.CompanyDirector && (
                <CompanyDirectorFinancialDetails
                  values={values}
                  pageKey={pageKey}
                  businessType={businessType}
                  industry={industry}
                  setFieldValue={setFieldValue}
                />
              )}
            </Section>
          )}
        </Section>
      )}
    </>
  )
}

export default ChildFinancialDetails
