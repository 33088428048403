export const APP_NAME = 'omauk-youth-etb-app--gb-hrfb'
export const APP_START_UP_TIME = 'APP_START_UP_TIME'

export const DEFAULT_MIN_REQ_TIME_IN_MS = 200
export const SUBMIT_MIN_REQ_TIME_IN_MS = 2000

export const AUTH_TOKEN_HEADER = 'x-authtoken'
export const AUTHORIZATION_HEADER = 'Authorization'
export const REQUEST_CORRELATION_ID_HEADER = 'x-hsbc-request-correlation-id'
export const STATE_HEADER = 'x-hsbc-state'

export const NODE_TO_API = 'NODE_TO_API'
