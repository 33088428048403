import React from 'react'
import { Field, Select, Label, TextInput, FieldFeedback } from '@piggybank/form'

import { CITY_MAX_LENGTH } from 'constants/index'
import { countries } from 'lookups/index'
import { PageKey, YesNoBoolean } from 'enums/index'

import dict from '../dictionary'

const ChildPermanentResidenceSection = ({ hideCddNewTemplateFields }) => (
  <>
    <Field name={`${PageKey.ChildPersonalDetailsPage}.permanentResidence`}>
      <Label>{dict.formSection.permanentResidence.label}</Label>
      <Select options={countries} />
      <FieldFeedback />
    </Field>
    {hideCddNewTemplateFields !== YesNoBoolean.Yes && (
      <Field name={`${PageKey.ChildPersonalDetailsPage}.cityOfPermanentResidence`}>
        <Label>{dict.formSection.cityOfPermanentResidence.label}</Label>
        <TextInput maxLength={CITY_MAX_LENGTH} />
        <FieldFeedback />
      </Field>
    )}
  </>
)

export default ChildPermanentResidenceSection
