enum ActionName {
  HttpRequest = 'HTTP_REQUEST',
  HttpRequestError = 'HTTP_REQUEST_ERROR',
  HttpResponse = 'HTTP_RESPONSE',
  HttpResponseError = 'HTTP_RESPONSE_ERROR',
  StoreFormInput = 'STORE_FORM_INPUT',
  StoreInitialResponse = 'STORE_INITIAL_RESPONSE',
  StoreNewAuthToken = 'STORE_NEW_AUTH_TOKEN',
  StoreSubmissionResponse = 'STORE_SUBMISSION_RESPONSE',
  UpdatePrimaryProduct = 'UPDATE_PRIMARY_PRODUCT',
  UpdateFeatureFlags = 'UPDATE_FEATURE_FLAGS',
  UpdateFakeProfiles = 'UPDATE_FAKE_PROFILES',
}

export default ActionName
