import { getPhoneNumberObject } from 'client/helpers'
import { MissingTaxIdOrReferenceNumberReason, PageKey, YesNoBoolean } from 'enums/index'
import { T_FormInput, T_RemoteData } from 'types/index'
import { unitedKingdom } from 'lookups/countries'
import { ntbProfiles } from 'stubs/index'

const pageKey = PageKey.ChildContactAndFinancialDetailsPage

export const addressInitialValues = {
  building: '',
  street: '',
  townOrCity: '',
  countryOrRegion: unitedKingdom.value,
  postCode: '',
  moveInDate: '',
}

export const additionalCountriesOrJurisdictionsInitialValues = {
  countryOrJurisdictionValue: '',
}

export const staticChildContactAndFinancialDetailsInitialValues = {
  whenDidStartLivingHere: '',
  newCurrentAddress: addressInitialValues,
  countryOrJurisdictionValue: unitedKingdom.value,
  countryOrJurisdictionAdditionalValues: [],
  isStudent: '',
  institutionName: '',
  employmentStatus: '',
  occupation: '',
  employerName: '',
  employerLocation: unitedKingdom.value,
  businessName: '',
  businessLocation: '',
  traderName: '',
  workLocation: unitedKingdom.value,
  controlledBusinessName: '',
  controlledBusinessLocation: unitedKingdom.value,
  businessRole: '',
  businessType: '',
  industry: '',
  sector: '',
  annualSalary: '',
  annualIncome: '',
  previousAddresses: [],
  isAddressHistoryComplete: '',
  childHasTaxIdOrReferenceNumber: '',
  // Reason is UnableToObtain, because the default is UK
  reasonForMissingChildTaxIdOrReferenceNumber: MissingTaxIdOrReferenceNumberReason.UnableToObtain,
  taxIdentificationOrReferenceNumber: '',
}

export default (
  ntbProfileId: string,
  remoteData: T_RemoteData,
  hideDoesLiveAtQuestion: boolean
) => {
  const { mobileTelephoneCountryCode, mobileTelephoneCode, mobileTelephoneNumber, emailAddress } =
    remoteData.initialResponse.contactDetails || {}

  const phoneNumber = getPhoneNumberObject(
    mobileTelephoneCountryCode,
    mobileTelephoneCode,
    mobileTelephoneNumber
  )

  return {
    [pageKey]: {
      ...staticChildContactAndFinancialDetailsInitialValues,
      doesLiveAt: hideDoesLiveAtQuestion ? YesNoBoolean.No : YesNoBoolean.Yes,
      phoneNumber,
      email: emailAddress,
      ...(ntbProfileId ? ntbProfiles[Number(ntbProfileId) - 1].data[pageKey] : {}),
    },
  } as T_FormInput
}
