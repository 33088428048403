import * as React from 'react'
import get from 'lodash/get'
import {
  Heading,
  Image,
  Masthead,
  Paragraph,
  ProductBasket,
  Section,
  LogoHsbcUk,
} from '@piggybank/core'

import hsbcMyMoneyDebitCard from 'src/assets/hsbcMyMoneyDebitCard.png'
import { CurrentOfferName } from 'client/components'
import { Product } from 'enums/index'
import { T_FormInput } from 'types/form'
import dict from './dictionary'

type T_Props = {
  formInput: T_FormInput
  basketVisible: boolean
}

export const MastHeadWithProductBasket = ({ formInput, basketVisible }: T_Props) => {
  const primaryProduct = get(formInput, 'product-eligibility-check-page.primaryProduct', '')
  const withCard =
    primaryProduct && [Product.MY_MONEY, Product.PREMIER_MY_MONEY].includes(primaryProduct)
  return (
    <Section marginBottom={0}>
      <Masthead logoSlot={<LogoHsbcUk />} marginBottom={basketVisible ? 0 : 5} />
      {basketVisible ? (
        <ProductBasket
          marginBottom={0}
          imageSlot={
            withCard ? (
              <Image
                src={hsbcMyMoneyDebitCard}
                intrinsicSize="200x126"
                alt={dict.cardImageDescription}
                marginBottom={0}
              />
            ) : null
          }
        >
          <Paragraph marginBottom={0}>{dict.title}</Paragraph>
          <Heading level={3} marginBottom={0}>
            <CurrentOfferName primaryProduct={primaryProduct} />
          </Heading>
        </ProductBasket>
      ) : null}
    </Section>
  )
}

export default MastHeadWithProductBasket
