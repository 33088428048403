import React, { FC } from 'react'
import get from 'lodash/get'
import {
  Section,
  Heading,
  Paragraph,
  HorizontalRule,
  UnorderedList,
  ListItem,
} from '@piggybank/core'
import {
  Form,
  Field,
  FieldFeedback,
  FormFeedback,
  Legend,
  ProgressIndicator,
  Navigation,
} from '@piggybank/form'

import { getDisplayedCustomerName } from 'client/helpers'
import { PageKey, Status } from 'enums/index'
import { AccessibleDateInput } from 'client/components'
import { getStore } from 'client/store'
import { T_FormInput } from 'types/form'
import { T_PageProps } from 'types/props'

import ProductSection from './ProductSection'
import getInitialValues from './getInitialValues'
import validationSchema from './validationSchema'
import dict from './dictionary'

const { welcomeSection, applicationSection, dobSection } = dict

const ProductEligibilityCheckPage: FC<T_PageProps> = () => {
  const {
    remoteData,
    configuration: { ntbProfileId },
  } = getStore().getState()
  if (!remoteData.initialResponse) return null

  const firstNames = get(remoteData.initialResponse, 'personalDetails.firstNames', '')

  return (
    <Form
      initialValues={getInitialValues(ntbProfileId)}
      validationSchema={validationSchema}
      autoComplete="off"
    >
      {({ values }) => {
        const { dateOfBirth } = values[PageKey.ProductEligibilityCheckPage]

        const showProductSection =
          dateOfBirth && ![Status.Incomplete, Status.Invalid].includes(dateOfBirth as Status)

        const handleDateOfBirthChange = (continueFieldValueUpdate, field) => {
          if (values[PageKey.ChildPersonalDetailsPage]) {
            values[PageKey.ChildPersonalDetailsPage].dateOfBirth = field.value
          }
          continueFieldValueUpdate(field)
        }

        return (
          <>
            <ProgressIndicator current={1} total={4} />
            <FormFeedback />
            <Section>
              <Heading level={1}>
                {welcomeSection.getHeading(getDisplayedCustomerName(firstNames))}
              </Heading>
              <Paragraph lead={true}>{welcomeSection.description}</Paragraph>
              <HorizontalRule />
            </Section>
            <Section>
              <Heading accentBar={true} level={2}>
                {applicationSection.heading}
              </Heading>
              <UnorderedList>
                {applicationSection.list.map((item, i) => (
                  <ListItem key={i}>{item}</ListItem>
                ))}
              </UnorderedList>
            </Section>
            <Section marginBottom={5}>
              <Heading accentBar={true} level={2}>
                {dobSection.heading}
              </Heading>
              <Paragraph>{dobSection.description}</Paragraph>
              <Field
                name={`${PageKey.ProductEligibilityCheckPage}.dateOfBirth`}
                onChange={handleDateOfBirthChange}
              >
                <Legend>{dobSection.legend}</Legend>
                <AccessibleDateInput />
                <FieldFeedback />
              </Field>
            </Section>
            {showProductSection ? (
              <ProductSection
                customer={remoteData.initialResponse}
                formInput={values as T_FormInput}
              />
            ) : (
              <Navigation disablePrevious={true} marginBottom={7} />
            )}
          </>
        )
      }}
    </Form>
  )
}

export default ProductEligibilityCheckPage
