import { PageKey } from 'enums/index'
import { ntbProfiles } from 'stubs/index'

const pageKey = PageKey.ProductEligibilityCheckPage

export default (ntbProfileId: string) => ({
  [pageKey]: {
    dateOfBirth: '',
    ...(ntbProfileId ? ntbProfiles[Number(ntbProfileId) - 1].data[pageKey] : {}),
  },
})
