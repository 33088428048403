import React from 'react'
import { connect } from 'react-redux'
import { RefreshStrategy, SessionProvider } from '@atlas/session'

import { Analytics, MainErrorBoundary, WizardForm } from 'client/components'
import { ServiceProvider, useService } from 'client/providers/Service'
import { isFeatureEnabled } from 'client/utils/featureEnabled'
import { Env, FeatureFlags } from 'enums/index'
import { T_AppProps, T_Store, T_ConnectedComponentProps } from 'types/index'

const App = (props: T_AppProps & T_ConnectedComponentProps) => {
  const {
    environment: { REACT_APP_ENV, TEALIUM_ENV, TIMEOUT_CLIENT_SESSION, TIMEOUT_WARNING_COUNTER },
    features,
  } = props
  const [{ service }] = useService()

  const customTimeout =
    isFeatureEnabled(features, FeatureFlags.CUSTOM_TIMEOUT) &&
    1000 * parseInt(features[FeatureFlags.CUSTOM_TIMEOUT].value, 10)
  const IDLE_TIMEOUT: number = customTimeout || parseInt(TIMEOUT_CLIENT_SESSION, 10)
  const IDLE_WARNING: number = IDLE_TIMEOUT - parseInt(TIMEOUT_WARNING_COUNTER, 10)

  return (
    <div
      id="App"
      // setting visibility to hidden to prevent flicker happening in local dev with ssr on
      style={REACT_APP_ENV === Env.Local ? { visibility: 'hidden' } : {}}
    >
      <ServiceProvider>
        <SessionProvider
          idleWarning={IDLE_WARNING || 230000}
          idleTimeout={IDLE_TIMEOUT || 290000}
          handleRefresh={() => service.extendSession()}
          startCondition={() => false}
          refreshStrategy={RefreshStrategy.OPTIMISTIC}
        >
          <Analytics enabled={Boolean(TEALIUM_ENV)}>
            <MainErrorBoundary>
              <WizardForm />
            </MainErrorBoundary>
          </Analytics>
        </SessionProvider>
      </ServiceProvider>
    </div>
  )
}

export default connect((store: T_Store) => ({
  environment: store.environment,
  features: store.features,
  configuration: store.configuration,
  remoteData: store.remoteData,
  formInput: store.formInput,
}))(App)
