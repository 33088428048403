import { unitedKingdom } from 'lookups/countries'
import { isFeatureEnabled } from 'client/utils/featureEnabled'
import { FeatureFlags, MoneySource, PageKey } from 'enums/index'
import { ntbProfiles } from 'stubs/index'
import { T_FormInput } from 'types/form'
import { T_Features } from 'types/store'

const pageKey = PageKey.AccountInformationPage

export default (ntbProfileId: string, features: T_Features) =>
  ({
    [pageKey]: {
      regularPaymentSource: '',
      regularPaymentCashSource: '',
      firstDepositSource: '' as MoneySource,
      firstDepositCashSource: '',
      initialDeposit: '',
      countryOfInitialDeposit: unitedKingdom.value,
      additionalCountriesOfInitialDeposit: [],
      marketingPreferences: [],
      accountOpeningConsent1: false,
      accountOpeningConsent2: false,
      accountOpeningConsent3: false,
      novAccepted: isFeatureEnabled(features, FeatureFlags.NOV_ENABLED),
      ...(ntbProfileId ? ntbProfiles[Number(ntbProfileId) - 1].data[pageKey] : {}),
    },
  } as T_FormInput)
