/* istanbul ignore file */
import get from 'lodash/get'
import attempt from 'lodash/fp/attempt'
import isError from 'lodash/fp/isError'
import Cookies from 'js-cookie'

import { extractEnvVariables } from 'client/helpers/index'
import {
  FEATURE_FLAGS_COOKIE_NAME,
  featureConfig,
  FeatureSet,
  SingleFeatureOptions,
} from 'constants/index'
import { FeatureFlags, PageKey } from 'enums/index'
import { T_Features, T_Store } from 'types/index'

export default (state: T_Store) => {
  let environment

  // SSR on
  if (get(state, 'configuration.serverSideRendered', false)) {
    environment = state.environment
  } else {
    // SSR off
    environment = extractEnvVariables(process.env)
    environment.EXPERIENCE_API_URL = '/api'
  }
  const featureSet: FeatureSet = environment.FEATURE_SET || 'production'
  const configEntries = Object.entries(featureConfig) as [
    keyof typeof features,
    SingleFeatureOptions
  ][]
  const defaultFeatureState = configEntries.reduce(
    (acc, [label, feature]) => ({
      ...acc,
      [label]: {
        enabled: feature.enabled[featureSet],
        value: feature.defaultValue,
      },
    }),
    {}
  )
  const featureStateFromCookie = attempt(() =>
    process.env.FEATURES_OVERRIDES === 'YES'
      ? JSON.parse(Cookies.get(FEATURE_FLAGS_COOKIE_NAME))
      : {}
  )

  const features = {
    ...defaultFeatureState,
    ...(!isError(featureStateFromCookie) ? featureStateFromCookie : {}),
  } as T_Features

  if (process.env.DSP_ENABLED !== 'true' && typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search)
    if (!!queryParams.get('cin')) {
      features[FeatureFlags.FAKE_CIN].value = queryParams.get('cin')
    }
    if (!!queryParams.get('ntbProfileId')) {
      features[FeatureFlags.FAKE_NTB_PROFILE].value = queryParams.get('ntbProfileId')
    }
  }

  return {
    environment,
    features,
    authorization: {
      ...state.authorization,
    },
    configuration: {
      ...state.configuration,
    },
    remoteData: {
      initialResponse: null,
      submissionResponse: null,
    },
    localData: {
      productBasket: {},
    },
    formInput: {
      [PageKey.ProductEligibilityCheckPage]: {},
      [PageKey.ChildPersonalDetailsPage]: {},
      [PageKey.ChildContactAndFinancialDetailsPage]: {},
      [PageKey.AccountInformationPage]: {},
    },
  }
}
