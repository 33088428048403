export const MARKETING_PREFS = [
  {
    value: 'TELEPHONE',
    label: 'Phone',
  },
  {
    value: 'SMS',
    label: 'Text',
  },
  {
    value: 'EMAIL',
    label: 'Email',
  },
  {
    value: 'LETTER',
    label: 'Post',
  },
]
