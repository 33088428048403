import {
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { T_TaggingSpecs } from 'types/taggingSpecs'
import { eligibilityRequirementsLinkLabel } from 'client/pages/ApplicationDeclinedPage'

const pageKey = PageKey.ApplicationDeclinedIneligibilityPage

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

export const applicationDeclinedIneligibilityPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired on ${pageKey} when customer clicks on eligibility requirements link.`,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Link,
      text: eligibilityRequirementsLinkLabel,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.OnSite,
      event_category: TealiumEventCategory.Content,
      event_content: 'eligibility requirements',
      raw_datalayer: '3406v57',
    }),
  },
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.NonWizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      application_event: 'declined',
      application_decision: 'ineligibility',
      funnel_step: '8',
      raw_datalayer: '3406v56',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer clicks on return to online banking button.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.OnClick,
      trackingPageName: pageKey,
      component: TealiumComponent.Button,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_category: TealiumEventCategory.Content,
      event_content: 'return to online banking',
      raw_datalayer: '6067v3',
    }),
  },
]
