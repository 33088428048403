import React from 'react'
import { withTrackPage } from '@piggybank/core'

import { PageKey, TealiumComponent } from 'enums/index'
import { ApplicationDeclinedPage } from './ApplicationDeclinedPage'
import dict from './dictionary'

export const ApplicationDeclinedIneligibilityPage = () => (
  <ApplicationDeclinedPage>
    {dict.whyCantIApplySection.ineligibilityParagraph}
  </ApplicationDeclinedPage>
)

export default withTrackPage(
  PageKey.ApplicationDeclinedIneligibilityPage,
  TealiumComponent.NonWizardPage
)(ApplicationDeclinedIneligibilityPage)
