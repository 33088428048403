import { omit, isEqual } from 'lodash'
import {
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import { bookAppointmentLinkLabel } from 'client/components/common/ContactMethods'
import { T_TaggingSpecs } from 'types/taggingSpecs'

const getAllCommonTags = getCommonTags({
  page_type: TealiumPageType.Application,
})

export const customApplicationDeclinedPageCommonTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired on ${PageKey.ApplicationDeclinedIneligibilityPage} or ${PageKey.ApplicationDeclinedInternalPolicyPage} when customer clicks on book an appointment link.`,
    eventIdentifier: event =>
      [
        PageKey.ApplicationDeclinedIneligibilityPage,
        PageKey.ApplicationDeclinedInternalPolicyPage,
      ].includes(event.trackingPageName) &&
      isEqual(omit(event, ['trackingPageName', 'name']), {
        type: PiggyEvent.OnClick,
        component: TealiumComponent.Link,
        text: bookAppointmentLinkLabel,
      }),
    content: ({ trackingPageName }) => ({
      ...getAllCommonTags(),
      ...getPageTags(trackingPageName),
      event_action: TealiumEventAction.OnSite,
      event_category: TealiumEventCategory.Content,
      event_content: 'book an appointment',
      raw_datalayer: '3406v58',
    }),
  },
]
