import { useEffect, useState } from 'react';

const useNow = () => {
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    let t1;
    const cb = () => {
      setNow(Date.now());
      t1 = requestAnimationFrame(cb);
    };
    t1 = requestAnimationFrame(cb);
    return () => {
      cancelAnimationFrame(t1);
    };
  }, []);
  return now;
};

export default useNow;
