import React, { FC, useEffect, useState } from 'react'
import { useSessionRefresh } from '@atlas/session'
import { Heading, Loading, Paragraph, useTrackingContext, withTrackPage } from '@piggybank/core'

import { validFormSubmissionEvent } from 'client/components/singletons/Analytics'
import { getPostCustomerPayload, prepareFormInputForSend, waitAtLeast } from 'client/helpers'
import FeatureFlagButtons from 'client/components/FeatureFlagButtons'
import { formPages } from 'client/pages'
import { useService } from 'client/providers/Service'
import { getStore } from 'client/store'
import { storeSubmissionResponse } from 'client/store/actions'
import { SUBMIT_MIN_REQ_TIME_IN_MS } from 'constants/index'
import {
  ChecksStatus,
  FinalDecision,
  MatchType,
  PageKey,
  RequestStatus,
  TealiumComponent,
} from 'enums/index'
import { T_SubmissionResponse } from 'types/data'
import { T_PageProps } from 'types/props'

import dict from './dictionary'

const { heading, processingDuration, pageReloadNotice } = dict

export const getPage = (data: T_SubmissionResponse) => {
  const customerMatchType = data.customerMatchType
  const finalDecision = data.decision

  if (customerMatchType === MatchType.PARTIAL_MATCH) {
    return formPages[PageKey.ApplicationReferredPage].id
  } else if (customerMatchType === MatchType.DEFINITE_MATCH) {
    if (data.divaCallSuccessful) {
      return formPages[PageKey.ApplicationReceivedPage].id
    } else {
      return formPages[PageKey.ApplicationReferredPage].id
    }
  } else if ([ChecksStatus.REFER, ChecksStatus.ERROR].includes(data.globalChecksStatus)) {
    return formPages[PageKey.ApplicationReferredPage].id
  } else if (finalDecision === FinalDecision.DECLINE_ELIGIBILITY) {
    return formPages[PageKey.ApplicationDeclinedIneligibilityPage].id
  } else if (finalDecision === FinalDecision.DECLINE_POLICY) {
    return formPages[PageKey.ApplicationDeclinedInternalPolicyPage].id
  } else if (finalDecision === FinalDecision.REFER_ERROR) {
    return formPages[PageKey.ApplicationReferredPage].id
  } else if ([FinalDecision.REFER, FinalDecision.ACCEPT].includes(finalDecision)) {
    if (data.divaCallSuccessful) {
      return formPages[PageKey.ApplicationReceivedPage].id
    } else {
      return formPages[PageKey.ApplicationReferredPage].id
    }
  }
  return formPages[PageKey.TechnicalErrorPage].id
}

export const ProcessingPage: FC<T_PageProps> = ({ onNavigate, formInput }) => {
  const refreshSession = useSessionRefresh()
  const { track } = useTrackingContext()
  const { getState, dispatch } = getStore()
  const [status, setStatus] = useState<RequestStatus | null>(null)
  const [{ service }] = useService()
  useEffect(() => {
    const { remoteData } = getState()
    const ensureMinAnimationLength = waitAtLeast(SUBMIT_MIN_REQ_TIME_IN_MS)
    const onSuccess = async (data: T_SubmissionResponse) => {
      setStatus(RequestStatus.Success)
      await refreshSession()
      dispatch(storeSubmissionResponse(data))
      ensureMinAnimationLength().then(() => onNavigate(getPage(data)))
    }
    const onError = () => {
      setStatus(RequestStatus.Failure)
      refreshSession()
      ensureMinAnimationLength().then(() => onNavigate(formPages[PageKey.TechnicalErrorPage].id))
    }
    if (!status) {
      setStatus(RequestStatus.Pending)
      prepareFormInputForSend(formInput)
      track({ ...validFormSubmissionEvent, value: formInput })
      const body = getPostCustomerPayload({ remoteData, formInput })
      try {
        service
          .postApplication({ body })
          .then(onSuccess)
          .catch(onError)
      } catch (e) {
        onError()
      }
    }
  }, [status, setStatus, service, dispatch, formInput, getState, onNavigate, refreshSession, track])
  return (
    <Loading show={true} getApplicationNode={() => document.getElementById('root')}>
      <Heading level={3}>{heading}</Heading>
      <Paragraph>{processingDuration}</Paragraph>
      <Paragraph>{pageReloadNotice}</Paragraph>
      <FeatureFlagButtons onNavigate={onNavigate} />
    </Loading>
  )
}

export default withTrackPage(PageKey.ProcessingPage, TealiumComponent.NonWizardPage)(ProcessingPage)
