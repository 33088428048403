import React from 'react'
import { Link } from '@piggybank/core'

import { Age, Product } from 'enums/index'
import validationDict from 'client/validation/dictionary'

export const seeOurCurrentAccountsButtonText = 'See our current accounts'
export const seeOurSavingsAccountsButtonText = 'See our savings accounts'
export const authorisedAdultLinkText = 'Authorised Adult'

export default {
  welcomeSection: {
    getHeading: firstName => `Hi ${firstName},`,
    description: "It's easy for parents or guardians to apply on their child's behalf.",
  },
  applicationSection: {
    heading: 'Simple online application',
    list: [
      "apply for an account in your child's name online in around 15 minutes",
      "proof of your child's identity is required",
      'your child can manage their account in branch or by phone. If your child has a MyAccount, they can also use the HSBC Mobile Banking app',
      [
        "you'll be the account's ",
        <Link
          key="authorisedAdultMandate"
          elementType={'a'}
          external={true}
          href="https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/authorised-adult-mandate.pdf"
        >
          {authorisedAdultLinkText}
        </Link>,
        ". This means you can manage certain things on your child's behalf. However, this account won't show up in your online banking",
      ],
      "it's important to discuss this application with your child so they understand how the account works",
    ],
  },
  dobSection: {
    heading: 'Open the right account',
    description: "To set up the account that's right for your child, we need to know their age.",
    legend: 'Date of birth',
    errMsg: validationDict.wrongDate,
  },
  productSection: {
    offers: {
      [Product.MY_MONEY]: {
        heading: 'Your child can open a MyMoney account',
        paragraph:
          'MyMoney offers a MyAccount for everyday banking and a MySavings account for their own personal savings.',
        list: [
          'They can manage their account online or on their mobile',
          "It's simple to transfer money between MySavings and MyAccount",
          'A contactless Visa debit card lets them shop online or in store',
        ],
      },
      [Product.PREMIER_MY_MONEY]: {
        heading: 'Your child can open a Premier MyMoney account',
        paragraph:
          'Premier MyMoney offers a Premier MyAccount for their everyday banking and a Premier MySavings for their own personal savings.',
        list: [
          'They can manage their account online or on their mobile',
          "It's simple to transfer money between their Premier MySavings and Premier MyAccount",
          'A contactless Visa debit card lets them shop online or in store',
        ],
      },
      [Product.MY_SAVINGS]: {
        heading: 'Your child can open a MySavings account',
        paragraph: 'Help them start saving.',
        list: ['Open with just £10', "On their 11th birthday, we'll open a MyAccount for them"],
      },
      [Product.PREMIER_MY_SAVINGS]: {
        heading: 'Your child can open a Premier MySavings account',
        paragraph: 'Help them start saving.',
        list: [
          'Open with just £10',
          "On their 11th birthday, we'll open a Premier MyAccount for them",
        ],
      },
    },
    noOfferInfo: {
      [Age.TooYoung]: {
        callout: 'Your child must be at least 7 years old to open a MyMoney account.',
        paragraph: "You can still put money aside for when your child's older.",
        button: {
          text: seeOurSavingsAccountsButtonText,
          link: 'https://www.hsbc.co.uk/savings/products/',
        },
      },
      [Age.TooOld]: {
        callout: 'Your child is no longer eligible for a MyMoney account.',
        paragraph: 'They can now choose from a wide range of HSBC savings and current accounts.',
        button: {
          text: seeOurCurrentAccountsButtonText,
          link: 'https://www.hsbc.co.uk/current-accounts/products/',
        },
        button2: {
          text: seeOurSavingsAccountsButtonText,
          link: 'https://www.hsbc.co.uk/savings/products/',
        },
      },
    },
  },
}
