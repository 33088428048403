import yup from 'client/validation'
import { MIN_INITIAL_DEPOSIT } from 'constants/customer'
import { MoneySource, PageKey, YesNoBoolean } from 'enums/index'

import dict from './dictionary'

export default (hideCddNewTemplateFields: YesNoBoolean) =>
  yup.object().shape({
    [PageKey.AccountInformationPage]: yup.object().shape({
      regularPaymentSource:
        hideCddNewTemplateFields !== YesNoBoolean.Yes
          ? yup.string().required(dict.accountUsageSection.regularPaymentSource.validation.required)
          : undefined,
      regularPaymentCashSource:
        hideCddNewTemplateFields !== YesNoBoolean.Yes
          ? yup.string().when('regularPaymentSource', {
              is: regularPaymentSource =>
                regularPaymentSource === MoneySource.CashFromAnotherSource,
              then: yup
                .string()
                .required(dict.accountUsageSection.regularPaymentCashSource.validation.required),
            } as any)
          : undefined,
      firstDepositSource: yup
        .string()
        .required(dict.accountUsageSection.firstDepositSource.validation.required),
      firstDepositCashSource:
        hideCddNewTemplateFields !== YesNoBoolean.Yes
          ? yup.string().when('firstDepositSource', {
              is: firstDepositSource => firstDepositSource === MoneySource.CashFromAnotherSource,
              then: yup
                .string()
                .required(dict.accountUsageSection.firstDepositCashSource.validation.required),
            } as any)
          : undefined,
      initialDeposit: yup
        .string()
        .test(
          'isGreaterThan10',
          dict.accountUsageSection.initialDeposit.validation.toSmall,
          value => Number(value) >= MIN_INITIAL_DEPOSIT
        )
        .required(dict.accountUsageSection.initialDeposit.validation.required),
      countryOfInitialDeposit: yup
        .string()
        .required(dict.accountUsageSection.countriesOfInitialDeposit.country.validation.required),
      additionalCountriesOfInitialDeposit: yup
        .array()
        .of(
          yup
            .string()
            .test(
              'isNotEmpty',
              dict.accountUsageSection.countriesOfInitialDeposit.additionalCountry.validation
                .required,
              value => value !== ''
            )
        ),
      accountOpeningConsent1: (yup.boolean() as any).test(
        'verifyAccountOpeningConsent',
        dict.accountOpeningConsent.validation.msg1,
        value => value === true
      ),
      accountOpeningConsent2: (yup.boolean() as any).test(
        'verifyAccountOpeningConsent',
        dict.accountOpeningConsent.validation.msg2,
        value => value === true
      ),
      accountOpeningConsent3: (yup.boolean() as any).test(
        'verifyAccountOpeningConsent',
        dict.accountOpeningConsent.validation.msg3,
        value => value === true
      ),
    }),
  })
