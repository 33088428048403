import React, { useCallback, useEffect } from 'react'
import get from 'lodash/get'

import {
  Button,
  ButtonRow,
  Callout,
  Heading,
  ListItem,
  Paragraph,
  Section,
  UnorderedList,
  useTrackingContext,
} from '@piggybank/core'
import { Navigation } from '@piggybank/form'

import { getChildEligibilityInfo } from 'client/helpers'
import { Age, PageKey, PiggyEvent, TealiumComponent } from 'enums/index'
import { T_FormInput, T_InitialResponse } from 'types/index'

import dict from '../dictionary'

type T_Props = {
  customer: T_InitialResponse
  formInput: T_FormInput
}

const getProductSectionContent = ({ customer, formInput }: T_Props) => {
  let offer
  let noOfferInfo
  const dateOfBirth =
    get(formInput, `${PageKey.ChildPersonalDetailsPage}.dateOfBirth`, '') ||
    get(formInput, `${PageKey.ProductEligibilityCheckPage}.dateOfBirth`, '')

  const childEligibilityInfo = getChildEligibilityInfo(customer.availableProducts, dateOfBirth)

  if (childEligibilityInfo.offerType && childEligibilityInfo.ageStatus === Age.ValidAge) {
    formInput[PageKey.ProductEligibilityCheckPage].primaryProduct =
      childEligibilityInfo.primaryProduct
    formInput[PageKey.ProductEligibilityCheckPage].additionalProduct =
      childEligibilityInfo.additionalProduct
    offer = {
      ...dict.productSection.offers[childEligibilityInfo.primaryProduct],
    }
  } else {
    noOfferInfo = dict.productSection.noOfferInfo[childEligibilityInfo.ageStatus]
  }

  return { offer, noOfferInfo, childEligibilityInfo }
}

export const onButtonClick = href => e => {
  e.preventDefault()
  window.location.assign(href)
}

export const ProductSection = (props: T_Props) => {
  const { track } = useTrackingContext()
  const { offer, noOfferInfo, childEligibilityInfo } = getProductSectionContent(props)

  const trackIneligibleAge = useCallback(
    ageStatus => {
      if ([Age.TooOld, Age.TooYoung].includes(ageStatus)) {
        track({
          component: TealiumComponent.ProductSection,
          type: PiggyEvent.IneligibleAge,
          ageStatus,
        })
      }
    },
    [track]
  )

  useEffect(() => {
    trackIneligibleAge(childEligibilityInfo.ageStatus)
  }, [childEligibilityInfo.ageStatus, trackIneligibleAge])

  const trackOfferUpdate = useCallback(
    primaryProduct => {
      if (primaryProduct) {
        track({
          childYearOfBirth: props.formInput[PageKey.ProductEligibilityCheckPage].dateOfBirth.split(
            '-'
          )[0],
          component: TealiumComponent.ProductSection,
          primaryProduct,
          type: PiggyEvent.ProductUpdate,
          trackingPageName: PageKey.ProductEligibilityCheckPage,
        })
      }
    },
    [props.formInput, track]
  )
  useEffect(() => {
    trackOfferUpdate(childEligibilityInfo.primaryProduct)
  }, [childEligibilityInfo.primaryProduct, trackOfferUpdate])

  return (
    <Section marginBottom={7}>
      {offer && (
        <>
          <Heading level={3}>{offer.heading}</Heading>
          <Paragraph>{offer.paragraph}</Paragraph>
          <UnorderedList marginBottom={6} type="tick">
            {offer.list.map((item, i) => (
              <ListItem key={i} marginBottom={i === offer.list.length - 1 ? 0 : 4}>
                {item}
              </ListItem>
            ))}
          </UnorderedList>
          <Navigation disablePrevious={true} />
        </>
      )}
      {noOfferInfo && (
        <>
          <Callout type="information">
            <Paragraph marginBottom={0}>{noOfferInfo.callout}</Paragraph>
          </Callout>
          <Paragraph>{noOfferInfo.paragraph}</Paragraph>
          <ButtonRow>
            <Button onClick={onButtonClick(noOfferInfo.button.link)}>
              {noOfferInfo.button.text}
            </Button>
            {noOfferInfo.button2 && (
              <Button onClick={onButtonClick(noOfferInfo.button2.link)} secondary={true}>
                {noOfferInfo.button2.text}
              </Button>
            )}
          </ButtonRow>
        </>
      )}
    </Section>
  )
}

export default ProductSection
