import React from 'react'
import { Heading, Paragraph, Expander } from '@piggybank/core'
import { Field, FieldFeedback, Legend, TelephoneInput, TextInput } from '@piggybank/form'

import { EMAIL_MAX_LENGTH } from 'constants/index'
import dict, { whyDoWeNeedContactDetailsLabel } from '../dictionary'
import replaceApostrophes from "client/utils/replaceApostrophes";

const { parentContactDetailsSection } = dict

const ParentContactDetails = ({ values, pageKey }) => (
  <>
    <Heading level={2} accentBar={true}>
      {parentContactDetailsSection.heading}
    </Heading>
    <Expander
      title={whyDoWeNeedContactDetailsLabel}
      name={whyDoWeNeedContactDetailsLabel}
      marginBottom={4}
    >
      <Paragraph>{parentContactDetailsSection.expander.paragraph1}</Paragraph>
      <Paragraph>{parentContactDetailsSection.expander.paragraph2}</Paragraph>
      <Paragraph>{parentContactDetailsSection.expander.paragraph3}</Paragraph>
    </Expander>
    <Field
      name={`${pageKey}.phoneNumber`}
      onBlur={(next, rest) => {
        values.phoneNumber.raw = values.phoneNumber.raw.trim()
        next(rest)
      }}
    >
      <Legend>{parentContactDetailsSection.form.phoneNumber.label}</Legend>
      <TelephoneInput />
      <FieldFeedback />
    </Field>
    <Field
      name={`${pageKey}.email`}
      onChange={(next, rest) => {
        rest.value = replaceApostrophes(rest.value)
        next(rest)
      }}
      onBlur={(next, rest) => {
        values.email = values.email.trim()
        next(rest)
      }}
      marginBottom={6}
    >
      <Legend>{parentContactDetailsSection.form.email.label}</Legend>
      <TextInput maxLength={EMAIL_MAX_LENGTH} />
      <FieldFeedback />
    </Field>
  </>
)

export default ParentContactDetails
