import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { BusinessRole, EmploymentStatus, PageKey, YesNoBoolean } from 'enums/index'
import { T_Store, T_SubmissionPayload } from 'types/index'

import getCurrentAddress from './getCurrentAddress'
import getPreviousAddresses from './getPreviousAddresses'
import getTaxResidencies from './getTaxResidencies'

export default ({ remoteData, formInput }: T_Store): T_SubmissionPayload => {
  const { dateOfBirth, primaryProduct, additionalProduct } = formInput[
    PageKey.ProductEligibilityCheckPage
  ]

  const {
    title,
    firstNames,
    familyName,
    hasPreviousNames,
    previousFirstNames,
    previousFamilyName,
    gender,
    birthPlace,
    permanentResidence,
    nationality,
    additionalNationalities,
  } = formInput[PageKey.ChildPersonalDetailsPage]

  const {
    email,
    employmentStatus,
    businessRole,
    occupation,
    businessName,
    sector,
    businessLocation,
    phoneNumber,
    doesLiveAt,
    newCurrentAddress,
    previousAddresses,
    countryOrJurisdictionValue,
    annualSalary,
    annualIncome,
    employerName,
    traderName,
    controlledBusinessName,
    employerLocation,
    workLocation,
    controlledBusinessLocation,
    institutionName,
    isStudent,
  } = formInput[PageKey.ChildContactAndFinancialDetailsPage]
  const { countryCallingCode, nationalNumber } = parsePhoneNumberFromString(phoneNumber.value)

  const {
    firstDepositSource,
    initialDeposit,
    countryOfInitialDeposit,
    additionalCountriesOfInitialDeposit,
    marketingPreferences,
    novAccepted,
  } = formInput[PageKey.AccountInformationPage]

  // MAIN OBJECT
  const payload = {
    primaryProduct,
    additionalProduct,
    novAccepted,
    personalDetails: {
      title,
      firstNames: firstNames.trim(),
      lastName: familyName.trim(),
      dateOfBirth,
      countryOfBirth: birthPlace,
      gender: gender.toUpperCase(),
      residenceCountryCode: permanentResidence,
    },
    contactDetails: {
      emailAddress: email.trim(),
      mobileTelephoneCountryCode: countryCallingCode as string,
      mobileTelephoneNumber: nationalNumber as string,
    },
    firstDepositDetails: {},
    marketingPreferences: {
      postOffers: !!marketingPreferences && marketingPreferences.includes('LETTER'),
      telephoneOffers: !!marketingPreferences && marketingPreferences.includes('TELEPHONE'),
      mobileOffers: !!marketingPreferences && marketingPreferences.includes('SMS'),
      emailOffers: !!marketingPreferences && marketingPreferences.includes('EMAIL'),
    },
  } as T_SubmissionPayload

  // PERSONAL DETAILS

  if (hasPreviousNames && hasPreviousNames === YesNoBoolean.Yes) {
    payload.previousNames = [
      {
        firstNames: previousFirstNames.trim(),
        lastName: previousFamilyName.trim(),
      },
    ]
  }

  if (nationality) {
    payload.nationalityDetails = { nationality }
    if (additionalNationalities) {
      payload.nationalityDetails.additionalNationality1 = additionalNationalities[0]
      if (additionalNationalities.length > 1) {
        payload.nationalityDetails.additionalNationality2 = additionalNationalities[1]
      }
    }
  }

  // CONTACT DETAILS

  if (doesLiveAt === YesNoBoolean.Yes || newCurrentAddress) {
    payload.currentAddress = getCurrentAddress(remoteData, formInput)
  }

  if (previousAddresses) {
    payload.previousAddresses = getPreviousAddresses(formInput)
  }

  if (countryOrJurisdictionValue) {
    payload.taxDetails = getTaxResidencies(formInput)
  }

  // FINANCIAL DETAILS
  if (isStudent === YesNoBoolean.Yes) {
    payload.financialDetails = {
      employmentStatus,
      educationalEstablishment: institutionName.trim(),
    }
  } else {
    if (employmentStatus === EmploymentStatus.Unemployed) {
      payload.financialDetails = { employmentStatus }
    } else {
      switch (businessRole) {
        case BusinessRole.Employee: {
          payload.financialDetails = {
            employmentStatus,
            employmentRole: businessRole,
            jobTitle: occupation.trim(),
            employersName: employerName.trim(),
            grossIncomeDetails: { amount: annualSalary, currency: 'GBP' },
          }
          payload.workAddress = { country: employerLocation }
          break
        }
        case BusinessRole.BusinessOwner: {
          payload.financialDetails = {
            employmentStatus,
            employmentRole: businessRole,
            employersName: businessName.trim(),
            standardIndustryCode: sector,
            annualTurnover: { amount: annualIncome, currency: 'GBP' },
          }
          payload.workAddress = { country: businessLocation }
          break
        }
        case BusinessRole.CompanyDirector: {
          payload.financialDetails = {
            employmentStatus,
            employmentRole: businessRole,
            employersName: controlledBusinessName.trim(),
            standardIndustryCode: sector,
            grossIncomeDetails: { amount: annualSalary, currency: 'GBP' },
            annualTurnover: { amount: annualIncome, currency: 'GBP' },
          }
          payload.workAddress = { country: controlledBusinessLocation }
          break
        }
        case BusinessRole.SoleTrader: {
          payload.financialDetails = {
            employmentStatus,
            employmentRole: businessRole,
            employersName: traderName.trim(),
            standardIndustryCode: sector,
            grossIncomeDetails: { amount: annualSalary, currency: 'GBP' },
            annualTurnover: { amount: annualIncome, currency: 'GBP' },
          }
          payload.workAddress = { country: workLocation }
          break
        }
      }
    }
  }

  // FIRST DEPOSIT DETAILS

  if (firstDepositSource) payload.firstDepositDetails.firstDepositSource = firstDepositSource
  if (initialDeposit) {
    payload.firstDepositDetails.firstDeposit = {
      amount: initialDeposit,
      currency: 'GBP',
    }
  }
  payload.firstDepositDetails.firstDepositCountries = additionalCountriesOfInitialDeposit
    ? [countryOfInitialDeposit, ...additionalCountriesOfInitialDeposit]
    : [countryOfInitialDeposit]

  return payload
}
