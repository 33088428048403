import { FormFieldName, PageKey } from 'enums/index'

// Child Personal Details
export const CHILD_BIRTH_PLACE_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.birthPlace`
export const CHILD_PERMANENT_RESIDENCE_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.permanentResidence`
export const CHILD_CITY_OF_PERMANENT_RESIDENCE_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.cityOfPermanentResidence`
export const CHILD_NATIONALITY_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.nationality`
export const CHILD_ADDITIONAL_NATIONALITIES_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.additionalNationalities`
export const CHILD_HAS_PREVIOUS_NAMES_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.hasPreviousNames`
export const CHILD_GENDER_FIELD_NAME_PATH = `${PageKey.ChildPersonalDetailsPage}.gender`

// Account Information
export const REGULAR_PAYMENT_SOURCE_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.RegularPaymentSource}`
export const REGULAR_PAYMENT_CASH_SOURCE_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.RegularPaymentCashSource}`
export const FIRST_DEPOSIT_SOURCE_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.FirstDepositSource}`
export const FIRST_DEPOSIT_CASH_SOURCE_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.FirstDepositCashSource}`
export const INITIAL_DEPOSIT_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.InitialDeposit}`
export const COUNTRY_OF_INITIAL_DEPOSIT_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.CountryOfInitialDeposit}`
export const ADDITIONAL_COUNTRIES_OF_INITIAL_DEPOSIT_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.AdditionalCountriesOfInitialDeposit}`
export const MARKETING_PREFERENCES_FIELD_NAME_PATH = `${PageKey.AccountInformationPage}.${FormFieldName.MarketingPreferences}`
