import React from 'react'
import { Link } from '@piggybank/core'

import {
  IconContactPhone,
  IconDeviceMobile,
  IconDeviceDesktop,
  IconContactMessage,
} from '@piggybank/core'

import { MARKETING_PREFS } from 'constants/index'

export default {
  heading: 'How can we get in touch?',
  paragraph: [
    "Select how would you like to be contacted about your child's account or any other services that may be relevant to them. You can opt out or change your preferences any time by calling our contact centre on ",
    <Link key="phoneNumberLink" elementType={'a'} href="tel:0345 740 4404">
      0345 740 4404
    </Link>,
    ' or by visiting a branch.',
  ],
  contactOptions: [
    { label: MARKETING_PREFS[0].label, value: MARKETING_PREFS[0].value, icon: IconContactPhone },
    { label: MARKETING_PREFS[1].label, value: MARKETING_PREFS[1].value, icon: IconDeviceMobile },
    { label: MARKETING_PREFS[2].label, value: MARKETING_PREFS[2].value, icon: IconDeviceDesktop },
    { label: MARKETING_PREFS[3].label, value: MARKETING_PREFS[3].value, icon: IconContactMessage },
  ],
}
