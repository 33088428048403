export const isValid = value => {
  return (
    /^[a-z0-9&'/?_-]+([.][a-z0-9&'/?_-]+)*([+][a-z0-9&.'+/?_-]*)?@[a-z0-9]+(-+[a-z0-9]+)*([.][a-z0-9]+(-+[a-z0-9]+)*)+$/i.test(
      value
    ) && !/[@.][0-9]+$/.test(value)
  )
}

export function getIsValidMethod(requiredErrMsg: string, invalidErrMsg: string) {
  return function() {
    const fieldName = this.path.split('.').reverse()[0]
    const value = this.parent[fieldName]
    if (!value) return this.createError({ message: requiredErrMsg })
    if (!isValid(value)) return this.createError({ message: invalidErrMsg })
    return true
  }
}

export default function(requiredErrMsg: string, invalidErrMsg: string) {
  return this.test({
    name: 'emailValidation',
    test: getIsValidMethod(requiredErrMsg, invalidErrMsg),
  })
}
