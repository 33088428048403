import * as React from 'react'

import { Link } from '@piggybank/core'

type T_Props = {
  chunks: {
    text: string
    link?: string
    phoneNumber?: string
    external?: boolean
    dataBdd?: string
  }[]
}

const InteractiveContent = ({ chunks }: T_Props) => (
  <>
    {chunks.map((chunk, i) => {
      if (chunk.link) {
        return (
          <Link
            key={i}
            data-bdd={chunk.dataBdd}
            elementType={'a'}
            href={chunk.link}
            external={chunk.external}
          >
            {chunk.text}
          </Link>
        )
      }

      if (chunk.phoneNumber) {
        return (
          <Link key={i} elementType={'a'} href={'tel:' + chunk.phoneNumber}>
            {chunk.text ? chunk.text : chunk.phoneNumber}
          </Link>
        )
      }

      return chunk.text
    })}
  </>
)

export default InteractiveContent
